import { Pagination } from 'react-bootstrap';

type Props = {
  handleNextPage: () => void;
  handlePrevPage: () => void;
  onPageClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, shift?: number) => void;
  page: number;
  nextDisabled: boolean;
  loading?: boolean;
};

export const PaginationPages: React.FC<Props> = ({ handleNextPage, handlePrevPage, page, onPageClick, nextDisabled, loading }) => (
  <Pagination style={{ justifyContent: 'center' }}>
    <Pagination.Prev onClick={handlePrevPage} disabled={page === 1 || loading} />
    {page > 1 && (
      <>
        <Pagination.Item key={`page-${page}`} onClick={onPageClick}>
          {1}
        </Pagination.Item>
        {page > 4 && <Pagination.Ellipsis />}
      </>
    )}

    {page > 3 && <Pagination.Item onClick={onPageClick}>{page - 2}</Pagination.Item>}
    {page > 2 && <Pagination.Item onClick={onPageClick}>{page - 1}</Pagination.Item>}
    <Pagination.Item key={`page-${page}`} active activeLabel="">
      {page}
    </Pagination.Item>
    <Pagination.Next onClick={handleNextPage} disabled={nextDisabled} />
  </Pagination>
);
