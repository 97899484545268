import OwnFranchiseHeader from 'components/OwnFranchise/OwnFranchiseHeader';
import { Container } from 'react-bootstrap';
import Card from 'antd/lib/card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { MarketplaceStore } from './MarketplaceStore/MarketplaceStore';

export const MarketplaceMain: React.FC = () => (
  <Container>
    <OwnFranchiseHeader />
    <Card>
      <CardBody>
        <MarketplaceStore />
      </CardBody>
    </Card>
  </Container>
);
