import history from 'routes/history';

export const handleGameJoinResponse = (res: any) => {
  console.log('res', res);
  if (res && res.success && res?.game) {
    if (res?.game?.isFilled) {
      if (res?.game?.type === 'H') {
        history?.push('/leagueheadtohead', res?.game?.gid);
      } else if (res?.game?.type === 'E') {
        history?.push(`/leagueelimination?gid=${res?.game?.gid}`);
      } else if (res?.game?.type === 'S') {
        history?.push(`/season/${res?.game?.gid}`);
      }
    } else {
      history?.push(`/EnteredLeague?gid=${res?.game?.gid}&type=${res?.game?.type}`);
    }
  }
};
