import { notification } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPlayerStats } from 'redux/actions/GameEngineAction';
import { post } from 'redux/services/Api';

export const useTradeBlockAvailability = (pid: number) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleTradeBlockAvailability = async (isOnTrade: boolean) => {
    try {
      setLoading(true);
      await post(`/engine/players/${pid}/toggleTradeBlockAvailability`, { isOnTrade });
      dispatch(getPlayerStats(pid));
      notification.success({ message: `Player is now ${isOnTrade ? '' : 'not '}available for trade` });
    } catch (e) {
      notification.error({ message: 'Failed to toggle trade block availability' });
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleTradeBlockAvailability };
};
