import { Modal } from 'antd';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { TradeProposalModalContent } from 'components/TradesAndFreeAgents/CreateTradeProposal/modals/TradeProposalModalContent';
import { FullTrade } from 'types/trades';
import { useTradeActions } from '../hooks/useTradeActions';
import history from 'routes/history';

type Props = {
  open: boolean;
  onClose: () => void;
  sender: boolean;
  trade: FullTrade;
};

export const ManageTradeProposalModal: React.FC<Props> = ({ open, onClose, sender, trade }) => {
  const { loading, request } = useTradeActions(trade._id, trade.trade[Number(sender)].utid);

  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      width={600}
      footer={
        <div className="modal-footer-buttons">
          {sender ? (
            <BlackButton text="CANCEL TRADE" onClick={() => request('cancel')} disabled={loading} />
          ) : (
            <>
              <PinkButton text="ACCEPT" onClick={() => request('accept')} disabled={loading} />
              <BlackButton text="COUNTER" onClick={() => history.push(`/counter-trade/${trade._id}`)} disabled={loading} />
              <BlackButton text="REJECT" onClick={() => request('reject')} disabled={loading} />
            </>
          )}
        </div>
      }
    >
      <h6 className="trade-proposal-modal-title">{sender && 'PENDING '}TRADE PROPOSAL</h6>
      <TradeProposalModalContent playersToReceive={trade.trade[Number(!sender)].players} playersToSend={trade.trade[Number(sender)].players} />
    </Modal>
  );
};
