import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Title from 'components/Common/Title/Title';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useEffect, useState } from 'react';
import { useTeamPlayersWithSomeStats } from 'components/TradesAndFreeAgents/hooks/useTeamPlayersWithSomeStats';
import { getTradeAwayPlayers } from 'components/TradesAndFreeAgents/constants';
import { PlayerSelector } from 'components/TradesAndFreeAgents/PlayerSelector';
import { TradeProposalModal } from 'components/TradesAndFreeAgents/CreateTradeProposal/modals/TradeProposalModal';
import { ShortTrade } from 'types/trades';
import { addLeagueEnterFormData } from 'redux/actions/TeamAction';
import { Table } from 'antd';

type Props = {
  trade?: ShortTrade | null;
};

export const CounterTrade: React.FC<Props> = ({ trade }) => {
  const dispatch = useDispatch();
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const utid: number | undefined = useSelector((state: RootState) => state.team.leagueEnterFormData?.franchise?.engId);
  const otherUtid = trade?.trade?.some((tr) => tr.utid === utid) ? trade?.trade?.find((tr) => tr.utid !== utid)?.utid : undefined;

  const otherPlayers = useTeamPlayersWithSomeStats(otherUtid);
  const myPlayers = useTeamPlayersWithSomeStats(utid);

  const [otherTeamSelectedPlayers, setOtherTeamSelectedPlayers] = useState<number[]>([]);
  const [mySelectedPlayers, setMySelectedPlayers] = useState<number[]>([]);
  const columnsMy = getTradeAwayPlayers(mySelectedPlayers, setMySelectedPlayers);
  const columnsOther = getTradeAwayPlayers(otherTeamSelectedPlayers, setOtherTeamSelectedPlayers);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!trade || !userTeams?.length) return;
    const team = userTeams.find((t) => trade.trade.some((tr) => tr.utid === t.engId));
    if (team) {
      dispatch(
        addLeagueEnterFormData({
          franchise: team,
        }),
      );

      const myPlayersIndex = trade?.trade?.findIndex((tr) => tr.utid === team.engId);
      const myInitialPlayers = trade?.trade?.[myPlayersIndex]?.pids;
      if (myInitialPlayers?.length) {
        setMySelectedPlayers(myInitialPlayers);
      }
      const otherInitialPlayers = trade?.trade?.[Math.abs(myPlayersIndex - 1)]?.pids;
      if (otherInitialPlayers?.length) {
        setOtherTeamSelectedPlayers(otherInitialPlayers);
      }
    }
  }, [trade, userTeams]);

  if (!trade) {
    return null;
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className="trade-proposal">
            <h6 className="mb-4">COUNTER TRADE PROPOSAL</h6>
            <Title text="TRADE FOR" />
            <div className="players-to-trade-container standingmain">
              <Table size="small" columns={columnsOther} dataSource={otherPlayers} pagination={false} rowKey={(i: any) => i.pid} />
            </div>

            <Title text="TRADE AWAY" />
            <div className="players-to-trade-container">
              <PlayerSelector pagination={false} players={myPlayers} columns={columnsMy} />
            </div>
            <div className="propose-button-container">
              <BlueButton text="PROPOSE TRADE" onClick={() => setIsSubmitting(true)} disabled={!mySelectedPlayers.length} />
            </div>
          </div>
        </CardBody>
      </Card>

      <TradeProposalModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
        playersToReceive={otherPlayers.filter((p) => otherTeamSelectedPlayers.includes(p.pid))}
        playersToSend={mySelectedPlayers.map((pid) => myPlayers.find((p) => p.pid === pid)!)}
        tradeId={trade._id}
      />
    </>
  );
};
