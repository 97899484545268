import { API_URL } from 'config';
import Stickers from '../assets/images/franchiseOverview/teamLeaders.png';
import { DepthChartRatings, EliminationTreeValue, HelmetData, PlayerLeader } from 'types';
import { get, post } from 'redux/services/Api';
import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import { notification } from 'antd';
import moment from 'moment';
import { BlobServiceClient } from '@azure/storage-blob';
import { FoundUser } from 'types/groups';
import { LeaderProps } from 'components/Common/LeagueOverviewTab/LeagueOverviewLeader';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import history from 'routes/history';
import { SeasonScores } from 'screens/Season/Tabs/Scores/types';
import { SeasonScoresWithHelmets } from 'screens/Season/Tabs/Scores/hooks/useScoresForWeek';

export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const validName = new RegExp(/^[A-Za-z0-9\s]*$/);

export const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[d!@#$%^&*()-_=+{};:,<.>])(?=.*[a-zA-Zd!@#$%^&*()-_=+{};:,<.>]).{8,}$');

export const bufferToBase64 = (data: any) => {
  if (!data) return '';
  return 'data:image/png;base64,' + Buffer.from(data).toString('base64');
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error: any) => reject(error);
  });
};

export const fileToBuffer = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: any) => reject(error);
  });
};

export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const filterValue = (obj: any, key: any, value: any) => obj?.find((v: any) => v[key] === value);

export const metaMaskWalletId = (data: any) => data?.substring(0, 6) + '...' + data?.substring(data?.length - 4);

export function getFormattedDate(date: any) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  var formattedDate = month + '-' + day + '-' + year;

  return formattedDate;
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
//moved team creation to backend, so probably we don't need this method:
export const generateAbbreviation = (str: any) => {
  if (str.includes(' ')) {
    return str
      .match(/\b([A-Za-z0-9])/g)
      .join('')
      .toUpperCase();
  } else {
    return str
      .match(/\b([A-Za-z0-9]{3})/g)
      .join('')
      .toUpperCase();
  }
};

export const deleteCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + `=;expires=${new Date()}`;
  }
};

export const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const months = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

export const saveDecreaseCount = (count: number) => {
  if (count > 0) {
    return count - 1;
  }
  return 0;
};

const categoriesToName = {
  pssYds: 'PASSING',
  rusYds: 'RUSHING',
  recYds: 'RECEIVING',
  defTckSolo: 'TACKLES',
  defSk: 'SACKS',
  defInt: 'INTERCEPTIONS',
};

export const getTeamLeadersForFranchiseOverview = (teamLeaders: Record<string, PlayerLeader>, franchiseId?: number | null, allTime = false) => {
  if (!teamLeaders) return [];

  const leaders = Object.values(teamLeaders);

  const preparedLeaders = Object.entries(categoriesToName).map(([trait, leaderCategory]) => {
    const leaderData = leaders.find((l) => trait in l);

    if (!leaderData) return null;

    const leaguedigitname = trait === 'defTckSolo' ? 'TPG' : trait === 'defSk' ? 'SACKS' : trait === 'defInt' ? 'INT' : 'YPG';

    const leaguedigit = allTime ? leaderData[trait as keyof PlayerLeader] : leaderData?.avgValue;

    return {
      franchiseId,
      leagueperson: `${leaderData.firstName} ${leaderData.lastName}`,
      leagueimage: leaderData.position ? require(`../assets/images/player/${leaderData.position}.png`) : Stickers,
      leaguename: leaderCategory,
      leaguedigit,
      leaguedigitname,
      id: leaderData.pid,
    };
  });

  return preparedLeaders.filter(Boolean).filter((leader) => Object.keys(leader as any).length) as LeaderProps[];
};

export const getFee = (price: number, feePercent: number, feeValue: number) => {
  return Number((price * (feePercent / 100) + feeValue).toFixed(2));
};

export const getPriceWithFee = (price: number, feePercent: number, feeValue: number) => {
  return price + getFee(price, feePercent, feeValue);
};

export const getPrizePool = (entryFee: number, payoutPercent = 100, teamLength = 2) => {
  if (teamLength === 2) {
    return Math.round((entryFee + (entryFee * (teamLength - 1) * payoutPercent) / 100) * 100) / 100;
  }

  return Math.round(((entryFee * teamLength * payoutPercent) / 100) * 100) / 100;
};

export const getStringDate = (date: Date): string => {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const getTeamsInGameString = (numberOfTeams = 2, teamsLength: number | undefined, withPercent = false) => {
  if (teamsLength === undefined) {
    return 'N/A';
  }
  let resultSting = `${teamsLength} of ${numberOfTeams}`;

  if (withPercent) {
    resultSting += ` (${Math.round((teamsLength / numberOfTeams) * 100)} %)`;
  }

  return resultSting;
};

const countRows = (initialPairsNumber: number) => {
  let num = parseInt(initialPairsNumber.toString());
  let count = 0;
  while (num > 1) {
    num /= 2;
    count++;
  }

  return count + 1;
};

export const getFilledTree = (tree: EliminationTreeValue[][], schedule?: Date[]) => {
  const treeCopy: EliminationTreeValue[][] = JSON.parse(JSON.stringify(tree));
  const initialPairsNumber = tree[0].length;
  const targetTreeLength = countRows(initialPairsNumber);
  const lastActualColumnIndex = tree.length - 1;

  for (let i = lastActualColumnIndex + 1; i < targetTreeLength; i++) {
    const preparedColumnLength = treeCopy[i - 1].length / 2;
    const preparedColumn = [];

    if (i === lastActualColumnIndex + 1) {
      const lastRound = tree[lastActualColumnIndex];

      for (let j = 0; j < lastRound.length; j += 2) {
        const teams: EliminationTreeValue['teams'] = [' ', ' '];
        const pair1winner = lastRound[j]?.winner;
        const pair2winner = lastRound[j + 1]?.winner;
        if (pair1winner) {
          teams[0] = pair1winner;
        }
        if (pair2winner) {
          teams[1] = pair2winner;
        }

        preparedColumn.push({
          gid: '',
          teams,
        });
      }
    } else {
      for (let j = 0; j < preparedColumnLength; j++) {
        const teams: [' ', ' '] = [' ', ' '];
        preparedColumn.push({
          gid: '',
          teams,
        });
      }
    }

    treeCopy.push(preparedColumn);
  }

  if (schedule) {
    let scheduleCopy = JSON.parse(JSON.stringify(schedule));
    if (scheduleCopy.length === 6) {
      scheduleCopy = [scheduleCopy[0], scheduleCopy[1], scheduleCopy[2], null, scheduleCopy[3], scheduleCopy[4], scheduleCopy[5]];
    } else if (scheduleCopy.length === 5) {
      scheduleCopy = [null, scheduleCopy[0], scheduleCopy[1], null, scheduleCopy[2], scheduleCopy[3], scheduleCopy[4]];
    }
    treeCopy.forEach((column, columnIndex, arr) => {
      column.forEach((element, elementIndex) => {
        const allPrevColsLength = arr.slice(0, columnIndex).reduce((acc, col) => acc + col.length, 0);
        const scheduleIndex = allPrevColsLength + elementIndex;
        const date = scheduleCopy[scheduleIndex];
        if (date) {
          element.startAt = date;
        }
      });
    });
  }

  return treeCopy;
};

export const roundToTwoDigits = (number: number) => {
  return Math.round(number * 100) / 100;
};

export const getRoundName = (roundsNumber: number, currentRound: number) => {
  if (roundsNumber === 0) {
    return currentRound;
  }
  if (currentRound === roundsNumber) {
    return 'FINAL';
  }
  if (currentRound === roundsNumber - 1) {
    return 'SEMI-FINAL';
  }
  if (currentRound === roundsNumber - 2) {
    return 'QUARTER-FINAL';
  }
  if (currentRound === roundsNumber - 3) {
    return 'SWEET 16';
  }
  if (currentRound === roundsNumber - 4) {
    return 'ROUND OF 32';
  }
  if (currentRound === 1) {
    return 'FIRST';
  }
  if (currentRound === 2) {
    return 'SECOND';
  }
  if (currentRound === 3) {
    return 'THIRD';
  }

  return currentRound;
};

export const calculateRounds = (initialPairs?: number) => {
  if (!initialPairs) {
    return null;
  }

  return Math.ceil(Math.log2(initialPairs)) + 1;
};

export const getFullGameType = (shortType: League['type']) => {
  const types = {
    H: 'HEAD-TO-HEAD',
    E: 'ELIMINATION',
    S: 'SEASON',
  };

  return types[shortType];
};

export const getEliminationWinnings = (payouts: [number, number, number], winnerIndex: 0 | 1 | 2 | null, prizePool: number) => {
  if (winnerIndex === null) {
    return 0;
  }

  return Math.round((payouts[winnerIndex] / 100) * prizePool * 100) / 100;
};

export const roundRatingsToTwoDigitsAfterDot = (ratings?: DepthChartRatings) => {
  if (!ratings) {
    return;
  }
  const ratingsCopy = JSON.parse(JSON.stringify(ratings));
  Object.keys(ratingsCopy).forEach((key) => {
    ratingsCopy[key] = typeof ratingsCopy[key] === 'number' ? roundToTwoDigitsAfterDot(ratingsCopy[key]) : ratingsCopy[key];
  });

  return ratingsCopy;
};

export const roundToTwoDigitsAfterDot = (number: number) => number.toFixed(2);

export const isGameStartingInLessThan30Minutes = (startAt?: string | Date) => {
  if (!startAt) {
    return true; //if no startAt, then game is starting in unknown time, maybe even now
  }
  const gameStartTime = moment(new Date(startAt));
  const currentTimePlus30Minutes = moment().add(30, 'minutes');

  return currentTimePlus30Minutes.isAfter(gameStartTime);
};

export const copyLinkToClipboard = async (link = window.location.href) => {
  await navigator.clipboard.writeText(link);
  notification.success({
    message: 'Link copied to clipboard',
    placement: 'bottomRight',
  });
};

const badWords = [
  'Bastard',
  'Bellend',
  'Bint',
  'Bitch',
  'Bloodclaat',
  'Bloody',
  'Bugger',
  'Bumberclat',
  'Chav',
  'Cow',
  'Crap',
  'Damn',
  'Dickhead',
  'Douchebag',
  'Effing',
  'Feck',
  'Fuck',
  'Ginger',
  'Git',
  'Minger',
  'Motherfucker',
  'Pissed',
  'Pissed off',
  'Shit',
  'Shite',
  'Sod Off',
  'Son of a Bitch',
  'Twat',
  'Arse',
  'Arsehole',
  'Balls',
  'Bawbag',
  'Beaver',
  'Bollocks',
  'Choad',
  'Clunge',
  'Cock',
  'Cunt',
  'Dick',
  'Fanny',
  'Gash',
  'Japs eye',
  'Knob',
  'Minge',
  'Prick',
  'Punani',
  'Pussy',
  'Pussy hole',
  'Snatch',
  'Tits',
  'Bang',
  'Bonk',
  'Cocksucker',
  'Cum',
  'Frigging',
  'Jizz',
  'MILF',
  'Nonce',
  'Prickteaser',
  'Raped',
  'Shag',
  'Skank',
  'Slag',
  'Slapper',
  'Slut',
  'Spunk',
  'Tart',
  'Tosser',
  'Wanker',
  'Whore',
  'Karen',
  'Boomer',
  'Femi-nazi',
  'Gammon',
  'Libtard',
  'Nat',
  'Remoaner',
  'Snowflake',
  'TERF',
  'Yoon',
  'Bud Bud',
  'Chinaman',
  'Ching Chong',
  'Chinky',
  'Coconut',
  'Coloured',
  'Coon',
  'Cracker',
  'Curry Muncher',
  'Darky',
  'Freshy',
  'Gippo',
  'Golliwog',
  'Golly',
  'Half-caste',
  'Honky',
  'Jap',
  'Jew',
  'Jock',
  'Jungle Bunny',
  'Kike',
  'Kraut',
  'Nazi',
  'Negro',
  'Nigger',
  'nigga',
  'Nig nog',
  'Nignog',
  'Oriental',
  'Paddy',
  'Paki',
  'Pikey',
  'Raghead',
  'Sambo',
  'Sheep Shagger',
  'Slope',
  'Spade',
  'Spic',
  'Taff',
  'Tinker',
  'Uncle Tom',
  'Uppity',
  'Wog',
  'Yid',
  'Batty Boy',
  'Bender',
  'Bent',
  'Bummer',
  'Butt Bandit',
  'Chick with a Dick',
  'Dyke',
  'Fag',
  'Faggot',
  'Fairy',
  'Fudge Packer',
  'Gender Bender',
  'He-She',
  'Homo',
  'Lezza',
  'Mincing',
  'Muff Diver',
  'Nancy',
  'Pansy',
  'Ponce',
  'Poof',
  'Queen',
  'Queer',
  'Rugmuncher',
  'Shemale',
  'Shirt Lifter',
  "That's Gay",
  'Tranny',
  'Transsexual',
  'Bible Basher',
  'Fenian',
  'God',
  'Goddamn',
  'Hun',
  'Jesus Christ',
  'Jew',
  'Kike',
  'Muzzie',
  'Papist',
  'Prod',
  'Taig',
  'Tarrier',
  'Yid',
  'Cretin',
  'Cripple',
  'Deaf and dumb',
  'Dwarf',
  'Flid',
  'Handicapped',
  'Invalid',
  'Looney',
  'Mental',
  'Mentally Challenged',
  'Midget',
  'Mong',
  'Moron',
  'Nutter',
  'Psycho',
  'Retard',
  'Schizo',
  'Spastic',
  'Special',
  'Tone deaf',
  'Wheelchair bound',
  'Window Licker',
  'Behnchod',
  'Chooray',
  'Chamaar',
  'Fitnah',
  'Habshi',
  'habshan',
  'Kaafir',
  'Kaala',
  'kaali',
  'Kutta',
  'Machod',
  'Murtad',
  'Uloo ka patha',
];

const replaceCharacters = {
  '0': 'o',
  '1': 'i',
  '3': 'e',
  '4': 'a',
  '5': 's',
  '7': 't',
  '8': 'b',
  '9': 'g',
};

export const isTextContainsBadWord = (text: string | null) => {
  if (!text) {
    return false;
  }
  const lowerCaseText = text.toLowerCase();
  const lowerCaseBadWords = badWords.map((w) => w.toLowerCase());
  const textWithNoNumbers = lowerCaseText
    .split('')
    .map((char) => {
      if (char in replaceCharacters) {
        //@ts-ignore
        return replaceCharacters[char];
      } else {
        return char;
      }
    })
    .join('');

  return lowerCaseBadWords.some((badWord) => textWithNoNumbers.includes(badWord));
};

export const removeMultipleWhitespacesBetweenWords = (text: string) => {
  return text
    .split(' ')
    .map((w) => w.trim())
    .filter((w) => w.length)
    .join(' ');
};

export const roundNumberToGivenDigitsAfterDot = (number: number | string, digits: number, noDecimal = false): string => {
  const multiplier = 10 ** digits;
  if (noDecimal) return Math.floor(+number).toString();
  return (Math.round(+number * multiplier) / multiplier).toFixed(digits);
};

export const sumObjectsByKeys = (objs: Record<string, number>[]) => {
  return objs.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      if (key in acc) {
        acc[key] += obj[key];
      } else {
        acc[key] = obj[key];
      }
    });
    return acc;
  }, {});
};

export const getPositionFullName = (key?: string) => {
  const positions = {
    QB: 'Quarterback',
    RB: 'Running Back',
    WR: 'Wide Receiver',
    TE: 'Tight End',
    OL: 'Offensive Line',
    DL: 'Defensive Line',
    LB: 'Linebacker',
    CB: 'Cornerback',
    S: 'Safety',
    K: 'Kicker',
    P: 'Punter',
    KR: 'Kick Returner',
    GM: 'General Manager',
    HC: 'Head Coach',
    AC: 'Assistant Coach',
  };
  if (!key || !(key in positions)) {
    return 'UNKNOWN';
  }
  return positions[key as keyof typeof positions];
};

export const transparentBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ep9ZAAAAABJRU5ErkJggg==';

export const findUserByNameSubstring = async (substring: string) => {
  try {
    const res: { data: FoundUser[] } = await get(`/user/get-users-by-substring?substr=${substring}`);
    return res.data;
  } catch (e) {
    console.log('ERROR DURING FIND USER', e);
    return [];
  }
};

export const findUserName = async (substring: string) => {
  try {
    const res: { data: string[] } = await get(`/user/get-username-by-substring?substr=${substring}`);
    return res.data;
  } catch (e) {
    console.log('ERROR DURING FIND USERNAME', e);
    return [];
  }
};
export function getAzureStorageFileUrl(fileUrl: string) {
  if (!process.env.REACT_APP_AZURE_BLOB_SERVICE_SAS_URL) return null;

  const blobServiceClient = new BlobServiceClient(process.env.REACT_APP_AZURE_BLOB_SERVICE_SAS_URL);

  const containerName = fileUrl.split('/')[1];
  const fileName = fileUrl.split('/')[2];

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(fileName);
  const url = blobClient.url;
  const randomNumber = Math.floor(Math.random() * 1000000) / 100;
  //for disabling chrome cache img
  const urlWithRandomNumber = `${url}&version=${randomNumber}`;
  return urlWithRandomNumber;
}

export function getMediaExtensionFromFileName(filename: string) {
  return filename.split('.').at(-1) as string;
}

export function bytesToMb(bytes: number) {
  return bytes / (1024 * 1024); // Convert bytes to MB
}
export function mbToBytes(mb: number) {
  const bytes = mb * 1024 * 1024; // Convert MB to bytes
  return bytes;
}

export const getTeamOwner = async (teamId: number) => {
  try {
    const owner: { userName: string; userId: number } = await get(`${API_URL}/user/get-team-owner/${teamId}`);

    return owner;
  } catch (e) {
    console.log('Error getting team owner', e);
    return {};
  }
};

export const notify = (type: 'error' | 'success', message: string) => {
  notification[type]({
    message,
    placement: 'bottomRight',
  });
};

export const isNum = (value: any) => typeof value === 'number';

export const getTableValue = (value: any) => {
  if (isNum(value)) {
    return value.toFixed(2);
  }

  return '-';
};

export const shortenString = (str: string, length: number) => {
  if (str.length > length) {
    const firstPart = str.slice(0, (length - 3) / 2);
    const secondPart = str.slice(-((length - 3) / 2));
    return `${firstPart}...${secondPart}`;
  }

  return str;
};

export const mapSeasonScores = (scores: SeasonScoresWithHelmets[]) => {
  return scores.map((data) => {
    const info: any = {
      id: data.gid,
      leaguename: data.name?.[0] ?? '---',
      tid1: data.tid?.[0] ?? '---',
      tid2: data.tid?.[1] ?? '---',
      recordscore1:
        data?.wonRecord?.[0] !== undefined && data?.lostRecord?.[0] !== undefined && data?.tiedRecord?.[0] !== undefined
          ? `RECORD ${data?.wonRecord?.[0]}-${data?.lostRecord?.[0]}-${data?.tiedRecord?.[0]}`
          : '---',
      recordscore2: data?.ovrEnd?.[0] !== undefined ? `RATING : ${data?.ovrEnd?.[0]}` : '---',
      score: data.ptsQtrs,
      leaguename2: data?.name?.[1] ? data?.name?.[1] : '---',
      recordscore12:
        data?.wonRecord?.[1] !== undefined && data?.lostRecord?.[1] !== undefined && data?.tiedRecord?.[1] !== undefined
          ? `RECORD ${data?.wonRecord?.[1]}-${data?.lostRecord?.[1]}-${data?.tiedRecord?.[1]}`
          : '---',
      recordscore22: data?.ovrEnd?.[1] !== undefined ? `RATING : ${data?.ovrEnd?.[1]}` : '---',
      passtitle: 'PASS',
      passsubtitle: data?.leaderInfo?.pass?.name || '---',
      passscoredigit: data?.leaderInfo?.pass
        ? `${data?.leaderInfo?.pass?.pssCmp}-${data?.leaderInfo?.pass?.pss}, ${data?.leaderInfo?.pass?.pssYds} YDS, ${data?.leaderInfo?.pass?.pssTD} TD`
        : '---',
      rushtitle: 'RUSH :',
      rushsubtitle: data?.leaderInfo?.rush?.name || '---',
      rushscoredigit: data?.leaderInfo?.rush ? `${data?.leaderInfo?.rush?.rus} CAR, ${data?.leaderInfo?.rush?.rusYds} YDS, ${data?.leaderInfo?.rush?.rusTD} TD` : '---',
      rectitle: 'REC :',
      recsubtitle: data?.leaderInfo?.rec?.name || '---',
      recscoredigit: data?.leaderInfo?.rec ? `${data?.leaderInfo?.rec?.rec} REC, ${data?.leaderInfo?.rec?.recYds} YDS, ${data?.leaderInfo?.rec?.recTD} TD` : '---',
      leagueid: data?.gid ? `#GID${data?.gid}` : '---',
      button1: BlueButton({ text: 'BOX SCORE', onClick: () => history.push(`/game-results/${data.gid}?tab=SCORE`) }),
      button2: BlueButton({ text: 'DRIVES', onClick: () => history.push(`/game-results/${data.gid}?tab=DRIVE-CHART`) }),
      button3: BlueButton({ text: 'TEAM STATS', onClick: () => history.push(`/game-results/${data.gid}?tab=TEAM-STATS`) }),
      button4: BlueButton({ text: 'REPLAY', onClick: () => history.push(`/replay/${data.gid}`) }),
      helmets: data.helmets,
    };

    return info;
  });
};

export const getFullHelmets = (engIds: number[]) => get<HelmetData[]>(`/user/get-full-helmet/${engIds.join(',')}`);

export const addHelmetsForScores = async (scores: SeasonScores[]): Promise<SeasonScoresWithHelmets[]> => {
  const engIds = Array.from(new Set(scores.map((score) => score.tid).flat()));
  if (!engIds.length) {
    return [];
  }
  const response = await getFullHelmets(engIds);

  const res = scores.map((score) => {
    const copy = {
      ...score,
      helmets: score.tid.map((tid) => response.find((h) => h.engId === tid)),
    } as unknown as SeasonScoresWithHelmets;
    return copy;
  });

  return res;
};

export const convertCamelCaseToUpperCase = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').toUpperCase();
};

export interface CreatePlayerImageRequest {
  playerId?: number;
  img1?: string; // Base64-encoded string
  img2?: string; // Base64-encoded string
}

export const postPlayerImages = async (body: CreatePlayerImageRequest) => {
  return await post(`/players/image`, body);
};

export interface CreateJsonFileRequest {
  playerId?: number;
  teamId?: number;
  description?: string;
  ownerId?: number;
}

export const postPlayerNftJson = async (body: CreateJsonFileRequest) => {
  return await post(`/players/json`, body);
};

export interface CreatePlayerToMintRequest {
  teamId?: number;
  playerId?: number;
  ownerId?: number;
  creatorId?: number;
  nftTokenId?: string;
  jsonFile?: string;
}

export const postPlayerToMint = async (body: CreatePlayerToMintRequest) => {
  return await post(`/players`, body);
};

export const getUserNamesByIds = (ids: number[]) => {
  return get<Record<string, string>>(`/user/get-usernames-by-ids/${ids.join(',')}`);
}
