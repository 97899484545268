import { Row, Col } from 'react-bootstrap';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { API_URL } from './../../../config/index';
import { useEffect, useState } from 'react';
import { getTeamsInGameString, roundToTwoDigits } from 'helper/helper';
import { MVPPrice } from 'components/Common/MVPPrice/MVPPrice';

export interface Props {
  leaguedata?: any;
}

const LeaguePaymentContent = ({ leaguedata }: Props) => {
  const league = leaguedata?.[0];
  const [joinTitle, setJoinTitle] = useState<string>('JOIN A LEAGUE');
  const [nameTitle, setNameTitle] = useState<string>('LEAGUE NAME :');

  useEffect(() => {
    switch (league?.type) {
      case 'H':
        setJoinTitle('JOIN A HEAD-TO-HEAD GAME');
        setNameTitle('GAME NAME :');
        break;
      case 'S':
        setJoinTitle('JOIN A LEAGUE');
        setNameTitle('LEAGUE NAME :');
        break;
      case 'E':
        setJoinTitle('JOIN AN ELIMINATION');
        setNameTitle('ELIMINATION NAME :');
        break;
      default:
        setJoinTitle('JOIN A LEAGUE');
        setNameTitle('LEAGUE NAME :');
        break;
    }
  }, [league]);

  const LEAGUE_NAME = league?.name;
  const LEAGUE_TEAMS = getTeamsInGameString(league?.numberOfTeams || league?.totalTeams, league?.teams?.length, true);
  const ENTRY_FEE = league?.isCrowns ? <MVPPrice amt={league?.fee} /> : `$ ${league?.fee}`;
  const TEAM_RATING = `${league?.ratingRangeMin} TO ${league?.ratingRangeMax}`;
  const IMG = league && league.franchise && league.franchise.img;
  const LEAGUE_IMAGE = IMG;

  const data1 = [
    {
      id: 1,
      detail: nameTitle,
      detailcontent: LEAGUE_NAME,
    },
    {
      id: 2,
      detail: 'TEAMS :',
      detailcontent: LEAGUE_TEAMS,
    },
    {
      id: 3,
      detail: 'ENTRY FEE :',
      detailcontent: ENTRY_FEE,
    },
    {
      id: 4,
      detail: 'TEAM RATING :',
      detailcontent: TEAM_RATING,
    },
  ];

  if (league && league?.startAt) {
    data1.push({
      id: 5,
      detail: 'STARTS :',
      detailcontent: new Date(league.startAt).toLocaleString(),
    });
  }

  if (league && league.type && league.type === 'E') {
    data1.push({
      id: 6,
      detail: 'GAME MODE :',
      detailcontent: league.gameMode,
    });
  }
  // TABLE
  const columns = [
    {
      title: 'PERFORMANCE PAY-OUT',
      dataIndex: 'americawest',
    },
    {
      title: '',
      dataIndex: 'w',
    },
  ];

  const dataLeague =
    league && league.payouts && league.payouts.length
      ? [
          {
            key: '1',
            americawest: '1ST PLACE',
            w: league?.isCrowns ? <MVPPrice amt={(league.payouts[0] / 100) * league.prizePool} /> : `$ ${roundToTwoDigits((league.payouts[0] / 100) * league.prizePool)}`,
          },
          {
            key: '2',
            americawest: '2ND PLACE',
            w: league?.isCrowns ? <MVPPrice amt={(league.payouts[1] / 100) * league.prizePool} /> : `$ ${roundToTwoDigits((league.payouts[1] / 100) * league.prizePool)}`,
          },
          {
            key: '3',
            americawest: '3RD PLACE',
            w: league?.isCrowns ? <MVPPrice amt={(league.payouts[2] / 100) * league.prizePool} /> : `$ ${roundToTwoDigits((league.payouts[2] / 100) * league.prizePool)}`,
          },
        ]
      : [];

  const dataH2H = [
    {
      key: '1',
      americawest: 'WINNER',
      w: league?.isCrowns ? <MVPPrice amt={league.prizePool} /> : league.prizePool,
    },
    {
      key: '2',
      americawest: 'LOSER',
      w: 0,
    },
  ];
  return (
    <Row>
      <Col xl={3}>
        <div className="leagueimage">
          <img src={`${API_URL}/images/franchise/userFranchise/${LEAGUE_IMAGE}`} alt="cardimage" />
        </div>
      </Col>
      <Col xl={9}>
        <div className="leaguecontent">
          <h5>{joinTitle}</h5>
          <div className="leaguedetail">
            <table>
              {data1.map((d: any, i: number) => (
                <tr key={i}>
                  <td>{d.detail}</td>
                  <td>{d.detailcontent}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="rankStandTable performancepay">
            <Table columns={columns} pagination={false} dataSource={!league?.type || league?.type === 'H' ? dataH2H : dataLeague} size="small" />
          </div>
          <div className="performancepaycontent">
            <h5>PERFORMANCE PAY-OUT</h5>
            <p>
              Upon entry your team will be randomly assigned a division and conference. All teams will play sixteen regular season games. The top 16 team will advance to
              the playoffs with the top 2 in each conference receiving a 1st week bye. <Link to="">FULL RULES</Link>
            </p>
          </div>
          <div className="performancepaycontent termsservice">
            <h5>TERMS OF SERVICE</h5>
            <p>By clicking to enter this league you are agreeing to terms of service and hereby certify that </p>
            <ul>
              <li>I am complying with all local and national laws.</li>
              <li>I am at least of 18 years of age.</li>
              <li>I understand, that fees and bonuses will not be refunded if I leave game</li>
              <li>I agree to all league rules.</li>
            </ul>
            <Link to="/privacy">FULL TERMS</Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LeaguePaymentContent;
