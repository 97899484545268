import { Col } from 'react-bootstrap';
import { FreeAgents } from 'components/TradesAndFreeAgents/types';
import { getPositionFullName } from 'helper/helper';
import history from 'routes/history';

type Props = {
  player: Pick<FreeAgents['freeAgents'][number], 'bornLoc' | 'firstName' | 'lastName' | 'pos' | 'pid' | 'ovr'>;
};

export const Player: React.FC<Props> = ({ player }) => (
  <Col className="trade-proposal-player mt-2" xl={6}>
    <img src={require(`../../../../assets/images/player/${player.pos}.png`)} alt="profile" width="119px" height="150px"/>

    <div>
      <span style={{ cursor: 'pointer'}} onClick={() => history.push(`/playerdetail/${player.pid}`)}>
        {player.firstName} {player.lastName}
      </span>
      <span>{player.bornLoc}</span>
      <span>{getPositionFullName(player.pos)}</span>
      <span>RATING: {player.ovr}</span>
    </div>
  </Col>
);
