import CardBody from 'components/Common/Card/CardBody';
import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import LoadingScreen from 'components/Common/LoadingScreen/LoadingScreen';
import { useLoading } from 'components/FranchiseOverview/hooks/use-loading';
import OwnFranchiseHeader from 'components/OwnFranchise/OwnFranchiseHeader';
import { Card, Container } from 'react-bootstrap';
import { MarketplaceTeams } from 'screens/MarketplaceTeamsScreen/MarketplaceTeams/MarketplaceTeams';

export const MarketplaceTeamsScreen: React.FC = () => {
  const loader = useLoading();

  return (
    <>
      {!!loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <Container>
          <OwnFranchiseHeader />
          <Card>
            <CardBody>
              <MarketplaceTeams />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};