import CircleIcon from './CircleIcon';
import './CircleChevronIconStyle.scss';
import CircleChevronSvg from '../../../../assets/images/common/chevron-double-down.svg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
  upsideDown?: boolean;
}

const CircleChevronIcon: React.FC<Props> = ({ upsideDown, ...props }) => {
  const transformStyle = upsideDown ? 'rotate(180deg)' : undefined;
  return <CircleIcon {...{ ...props, style: { ...props.style, transform: transformStyle } }} icon={CircleChevronSvg} className="circleChevronIcon" />;
};

export default CircleChevronIcon;
