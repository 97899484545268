import { useEffect, useState } from "react";
import { PropertySelector } from "../PropertySelector/PropertySelector";
import {
  PATH_TO_TROPHY_FOLDER,
  TrophyPreview,
} from "../TrophyPreview/TrophyPreview";
import { PrestigePointsSelectorModal } from "../../modals/PrestigePointsSelector/PrestigePointsSelector";
import { useTrophyAssets } from "../../hooks/useTrophyAssets";
import { Col, Row } from "react-bootstrap";
import { TrophyAssetsObject } from "redux/actions/TrophyAssets/types";
import { SelectorPopover } from "../SelectorPopover/SelectorPopover";
import {
  getAssetLevel,
  getColorFromBgName,
  getSelectorTitle,
  getTrophyNameAvailability,
} from "components/PurchaseTrophy/helper";
import { UnselectedPopover } from "components/PurchaseFranchise/Common/UnselectedPopover";
import {
  isTextContainsBadWord,
  removeMultipleWhitespacesBetweenWords,
  validName,
} from "helper/helper";
import { Input, notification } from "antd";
import Dropdown from "components/Common/Form/Dropdown/Dropdown";
import "./TrophyImageBuilder.scss";
import { TrophyBeforePurchase } from "components/PurchaseTrophy/types";
import BlackButton from "components/Common/Button/BlackButton/BlackButton";
import { PrestigeLevelAlertModal } from "../PrestigeLevelAlertModal/PrestigeLevelAlertModal";
import { useAdminGroups } from "helper/useAdminGroups";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

type Props = {
  trophy: Partial<TrophyBeforePurchase> | null;
  setTrophy: React.Dispatch<
    React.SetStateAction<Partial<TrophyBeforePurchase> | null>
  >;
};

export const TrophyImageBuilder: React.FC<Props> = ({
  trophy: fullTrophyData,
  setTrophy: setFullTrophyData,
}) => {
  const userId: number | undefined = useSelector((state: RootState) => state.app.resData?.id);
  const trophyAssets = useTrophyAssets();
  const [prestigePoints, setPrestigePoints] = useState(0);
  const [trophy, setTrophy] = useState<TrophyAssetsObject["cup"][0] | null>(
    null
  );
  const [background, setBackground] = useState<
    TrophyAssetsObject["bg"][0] | null
  >(null);
  const [corner, setCorner] = useState<TrophyAssetsObject["corner"][0] | null>(
    null
  );
  const [texture, setTexture] = useState<
    TrophyAssetsObject["texture"][0] | null
  >(null);
  const [trophyName, setTrophyName] = useState("");
  const [isTrophyNameOffensive, setIsTrophyNameOffensive] = useState(false);
  const [trophyNameError, setTrophyNameError] = useState("");

  const [isPrestigePointsModalVisible, setIsPrestigePointsModalVisible] =
    useState(false);

  const [isUnselectedError, setIsUnselectedError] = useState(false);
  const [mintToBlockchain, setMintToBlockchain] = useState(false);

  const [isPrestigeLevelAlert, setIsPrestigeLevelAlert] = useState(false);

  const [isNameUsed, setIsNameUsed] = useState(false);

  const userGroups = [
    { key: 0, option: "INDIVIDUAL (NO GROUP)", value: 0 },
    ...useAdminGroups(userId).map((group) => ({
      key: group.id,
      option: group.name,
      value: group.id,
    })),
  ];
  const [selectedGroup, setSelectedGroup] = useState(userGroups[0].value);

  const hideUnselectedError = () => {
    setIsUnselectedError(false);
  };

  useEffect(() => {
    if (trophyName) {
      if (isTextContainsBadWord(trophyName)) {
        setIsTrophyNameOffensive(true);
      } else {
        setIsTrophyNameOffensive(false);
      }
    }
  }, [trophyName]);

  const isNextDisabled = !(
    trophyName &&
    prestigePoints &&
    trophy &&
    background &&
    corner &&
    texture
  );

  useEffect(() => {
    setFullTrophyData(() => null);
    setIsNameUsed(false);
  }, [
    trophyName,
    prestigePoints,
    trophy,
    background,
    corner,
    texture,
    selectedGroup,
    mintToBlockchain,
    setFullTrophyData,
  ]);

  function handleTrophyName(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;
    if (!validName.test(name)) {
      setTrophyNameError("Only letters and numbers are permitted");
      return;
    }
    if (name?.length > 25) {
      setTrophyNameError(
        "You have reached maximum limit of characters allowed"
      );
    } else {
      setTrophyName(name?.toUpperCase());
      setTrophyNameError("");
    }
  }

  const handleNext = async () => {
    const trophyLevel = getAssetLevel(prestigePoints) as number;
    const assetsLevels = [
      getAssetLevel(trophy?.max_prestige_level) || 0,
      getAssetLevel(corner?.max_prestige_level) || 0,
      getAssetLevel(texture?.max_prestige_level) || 0,
    ];

    if (trophyLevel < Math.max(...assetsLevels)) {
      setIsPrestigeLevelAlert(true);
      return;
    }

    const isAvailable = await getTrophyNameAvailability(trophyName, selectedGroup || undefined);

    if (!isAvailable) {
      notification.error({
        message: "Trophy Name is already used",
        description: "Please, choose another name",
        placement: "bottomRight",
      });
      setIsNameUsed(true);
      return;
    }

    if (!isNextDisabled) {
      setFullTrophyData({
        trophyName,
        prestigePoints,
        bgId: background.id,
        cupId: trophy.id,
        cornerId: corner.id,
        textureId: texture.id,
        groupId: selectedGroup,
        mint: mintToBlockchain,
      });
    }
  };

  if (!trophyAssets) {
    return null;
  }

  return (
    <div className="image-builder-container">
      <Row>
        <Col md={6} xs={12}>
          <TrophyPreview
            trophyName={trophyName || "TROPHY NAME"}
            groupName={selectedGroup ? (userGroups.find((g) => g.key === selectedGroup)?.option || "") : ""}
            prestigePoints={prestigePoints || "00"}
            cupPath={trophy?.filename}
            bgPath={background?.filename}
            cornerPath={corner?.filename}
            texturePath={texture?.filename}
          />
        </Col>
        <Col md={6} xs={12}>
          <PropertySelector
            selectorName="1. Select Prestige Points"
            unselectedText="Please, Select Prestige Points"
            currentSelectedId={prestigePoints}
            currentSelectedValue={prestigePoints && `${prestigePoints} POINTS - LEVEL ${getAssetLevel(prestigePoints)}`}
            currentSelectedImgUrl={undefined}
            toggleSelector={() => setIsPrestigePointsModalVisible(true)}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: number | string) =>
              setPrestigePoints(+value)
            }
            showValue
          />
          <PropertySelector
            selectorName="2. Select Trophy"
            unselectedText="Please, Select Trophy"
            currentSelectedId={trophy?.id || 0}
            currentSelectedValue={getSelectorTitle(trophy, "Cup")}
            currentSelectedImgUrl={
              trophy?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${trophy?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: any) => setTrophy(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Trophy"
                onSelect={setTrophy}
                imageArray={trophyAssets.cup}
                mode="image"
              />
            }
          />
          <PropertySelector
            selectorName="3. Select Background"
            unselectedText="Please, Select Background"
            currentSelectedId={background?.id || 0}
            currentSelectedValue={background?.name}
            currentSelectedImgUrl={
              background?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${background?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: any) => setBackground(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Background"
                onSelect={setBackground}
                imageArray={trophyAssets.bg}
                mode="bg"
              />
            }
            isColor
          />
          <PropertySelector
            selectorName="4. Select Corner Design"
            unselectedText="Please, Select Corner Design"
            currentSelectedId={corner?.id || 0}
            currentSelectedValue={getSelectorTitle(corner, "Corner")}
            currentSelectedImgUrl={
              corner?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${corner?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: any) => setCorner(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Corner Design"
                onSelect={setCorner}
                imageArray={trophyAssets.corner}
                mode="corner"
              />
            }
          />
          <PropertySelector
            selectorName="5. Select Texture"
            unselectedText="Please, Select Texture"
            currentSelectedId={texture?.id || 0}
            currentSelectedValue={getSelectorTitle(texture, "Texture")}
            currentSelectedImgUrl={
              texture?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${texture?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: any) => setTexture(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Trophy"
                onSelect={setTexture}
                imageArray={trophyAssets.texture}
                mode="image"
                imageBG={
                  background?.filename
                    ? `#${getColorFromBgName(background.filename)}`
                    : "#384c6c"
                }
              />
            }
            imageBG={
              background?.filename
                ? `#${getColorFromBgName(background.filename)}`
                : "#384c6c"
            }
          />
          <div className="trophy-name">
            <strong>6. Select Trophy Name</strong>
            <div className="createFranchise-form-label">
              <UnselectedPopover
                content="Please, enter a team name"
                open={isUnselectedError && !trophyName}
                handleClose={() => setIsUnselectedError(false)}
                isFullWidth
              >
                <Input
                  placeholder="ENTER NAME"
                  value={trophyName}
                  onChange={handleTrophyName}
                  onBlur={() =>
                    setTrophyName((prevName) =>
                      removeMultipleWhitespacesBetweenWords(prevName)
                    )
                  }
                />
              </UnselectedPopover>
              {isTrophyNameOffensive && (
                <p className="error">
                  WARNING: TROPHY NAME IS POSSIBLY OFFENSIVE
                </p>
              )}
              {trophyNameError && <p className="error">{trophyNameError}</p>}
            </div>
          </div>
          <div className="group-association mb-4">
            <strong>7. Select Group Association</strong>
            <Dropdown
              value={selectedGroup}
              onChange={(value) => value.target.value && setSelectedGroup(+value.target.value)}
              dropDownOptions={userGroups}
            />
          </div>
          <strong>8. Mint to Blockchain</strong>
          <div className="mint-to-blockchain">
            <input
              type="radio"
              id="yes"
              name="mintToBlockchain"
              value="yes"
              checked={mintToBlockchain}
              onChange={() => setMintToBlockchain(true)}
            />
            <label htmlFor="yes">Yes</label>
            <input
              type="radio"
              id="no"
              name="mintToBlockchain"
              value="no"
              checked={!mintToBlockchain}
              onChange={() => setMintToBlockchain(false)}
            />
            <label htmlFor="no">No</label>
          </div>
          <div className="next-button-container">
            <BlackButton
              text="NEXT"
              disabled={isNextDisabled || !!fullTrophyData || isNameUsed}
              onClick={handleNext}
            />
          </div>
        </Col>
      </Row>
      <PrestigePointsSelectorModal
        open={isPrestigePointsModalVisible}
        onCancel={() => setIsPrestigePointsModalVisible(false)}
        setValue={setPrestigePoints}
      />
      <PrestigeLevelAlertModal
        open={isPrestigeLevelAlert}
        onClose={() => setIsPrestigeLevelAlert(false)}
        onChangePresigeLevel={() => {
          setIsPrestigeLevelAlert(false);
          setPrestigePoints(0);
          setIsPrestigePointsModalVisible(true);
        }}
      />
    </div>
  );
};
