import { Modal } from 'antd';
import { FreeAgents } from '../types';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { Row, Col } from 'react-bootstrap';
import { getPositionFullName } from 'helper/helper';

type Props = {
  open: boolean;
  onClose: () => void;
  agent: FreeAgents['freeAgents'][number];
  acquireFreeAgent: () => void;
};

export const AcquireFreeAgentModal: React.FC<Props> = ({ open, onClose, agent, acquireFreeAgent }) => (
  <Modal width="600px" centered open={open} onCancel={onClose} footer={<PinkButton text={'ADD PLAYER'} onClick={acquireFreeAgent} />}>
    <Row className="mb-4">
      <Col xs={6}>
        <img src={require(`../../../assets/images/player/${agent.pos || 'QB'}.png`)} alt="profile" width="100%" />
      </Col>
      <Col xs={6}>
        <div className="d-flex flex-column">
          <span style={{ fontSize: 24 }}>
            <b>
              {agent.firstName} {agent.lastName}
            </b>
          </span>
          <span>{agent.bornLoc}</span>
          <span>{getPositionFullName(agent.pos).toUpperCase()}</span>
        </div>
      </Col>
    </Row>
  </Modal>
);
