import CircleIcon from './CircleIcon';
import './CircleBlack.scss';
import CircleSwapSvg from '../../../../assets/images/common/swap.svg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
  selected?: boolean;
}

const CircleSwapIcon: React.FC<Props> = (props) => (
  <CircleIcon {...props} icon={CircleSwapSvg} className="circleBlack" style={{ ...(props?.style || {}), ...(props?.selected ? { backgroundColor: '#c26628' } : {}) }} />
);

export default CircleSwapIcon;
