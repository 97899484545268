import Title from 'components/Common/Title/Title';
import { AcquireCrowns } from './AcquireCrowns/AcquireCrowns';
import { AcquireGameAssets } from './AcquireGameAssets';

export const MarketplaceStore: React.FC = () => (
  <>
    <Title text="ACQUIRE MVP CROWNS" />
    <AcquireCrowns />
    <Title text="ACQUIRE GAME ASSETS" />
    <AcquireGameAssets />
  </>
);
