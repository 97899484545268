import { notification } from 'antd';
import { FreeAgents } from 'components/TradesAndFreeAgents/types';
import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';

export const usePlayerTradeInfo = () => {
  const [pid, setPid] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState<FreeAgents['freeAgents'][number] | null>(null);

  useEffect(() => {
    if (!pid) {
      setPlayerInfo(null);
      return;
    }

    const fetchPlayerInfo = async () => {
      try {
        setPlayerInfo(null);
        setLoading(true);
        const data = await get<FreeAgents['freeAgents'][number]>(`/engine/players/${pid}/tradeInfo`);
        setPlayerInfo(data);
      } catch (e) {
        notification.error({ message: 'Failed to fetch player info' });
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerInfo();

    return () => {
      setPlayerInfo(null);
    };
  }, [pid]);

  return {
    setPid,
    playerInfo,
    loading,
  };
};
