import { getFullHelmets, getUserNamesByIds } from 'helper/helper';
import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';
import { TradeWithHelmets } from 'types/trades';

type State = {
  trades: TradeWithHelmets[];
  hasNextPage: boolean;
};

const LIMIT = 2;

export const usePlayerTradesHistory = () => {
  const [trades, setTrades] = useState<State | null>(null);
  const [loading, setLoading] = useState(false);
  const { handleNextPage, handlePrevPage, page, setPage } = useServerSidePagination();

  useEffect(() => {
    const fetchTrades = async () => {
      try {
        setLoading(true);
        const res = await get<{ trades: TradeWithHelmets[]; hasNextPage: boolean }>(`/engine/players/tradeHistory?page=${page}&limit=${LIMIT}`);
        const teamIds = res.trades.flatMap((tr) => tr.trade.flatMap((s) => s.utid))
        const userIds = res.trades.flatMap((tr) => tr.trade.flatMap((s) => s.userId));
        const helmetsData = await getFullHelmets(teamIds);
        const userNames = await getUserNamesByIds(userIds);

        const dataWithHelmets = res.trades.map((tr) => ({
          ...tr,
          trade: tr.trade.map((side) => {
            const helmet = helmetsData.find((h) => h.engId === side.utid) || {};
            const userName = userNames[side.userId];

            return {
              ...side,
              ...helmet,
              userName
            };
          }) as TradeWithHelmets['trade'],
        }));

        setTrades({ trades: dataWithHelmets, hasNextPage: res.hasNextPage });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchTrades();
  }, [page]);

  return {
    trades,
    loading,
    handleNextPage,
    handlePrevPage,
    page,
    setPage,
  };
};
