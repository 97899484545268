import { Player } from 'components/TradesAndFreeAgents/CreateTradeProposal/modals/Player';
import { FullTrade as TradeType } from 'types/trades';
import './TradeProposal.scss';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ManageTradeProposalModal } from './modals/ManageTradeProposalModal';
import { useState } from 'react';

type Props = {
  trade: TradeType;
  userId: number;
};

export const Trade: React.FC<Props> = ({ trade, userId }) => {
  const accountUserId = useSelector((state: RootState) => state.app.resData?.id);
  const isOwn = accountUserId === userId;
  const label = trade.trade[1].userId === userId ? 'SENT' : 'RECEIVED';
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="profile-trade-proposal">
        <BlueButton disabled={!isOwn} text={label} onClick={() => setIsModalOpen(true)} />
        {[...trade.trade]
          .sort((a, b) => Number(a.userId === userId) - Number(b.userId === userId))
          .map((t, i) => (
            <div className="profile-trade-proposal__side-container" key={i}>
              <span className="profile-trade-proposal__side-container__side">{t.userId === userId ? 'SEND' : 'GET'}</span>
              <div className="profile-trade-proposal__side-container__players">
                {t.players.map((p) => (
                  <Player player={p} />
                ))}
              </div>
            </div>
          ))}
      </div>

      <ManageTradeProposalModal open={isModalOpen} onClose={() => setIsModalOpen(false)} trade={trade} sender={trade.trade[1].userId === userId} />
    </>
  );
};
