/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Stickers from "../../../assets/images/homeScreen/gmDynastyLogo.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LeagueHeaderDetail from "./LeagueHeaderDetail";
import { useEffect, useMemo, useState } from "react";
import { API_URL } from "../../../config";
import {
  UserRecordAPI,
  getTeamLeaguesHistory,
} from "../../../redux/actions/GameEngineAction";
import moment from "moment";
import { getFullGameType } from "helper/helper";
import { useFeeRates } from "helper/useFeeRates";

type Franchise = {
  id?: number;
  utid?: number;
  engId?: number;
};

type History = {
  timestamp: Date;
  action: string;
  desc: string;
}[];

export type League = {
  id?: number | string;
  gid: number;
  title: string;
  name: string;
  teams?: number[];
  type: "H" | "S" | "E";
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  prizePool: number;
  payoutPct: number;
  started: Date | string;
  gameTime: Date | string;
  visibility: "public" | "private";
  numberOfTeams?: number;
  status: "pending" | "running" | "completed" | "waitingForPlayers" | "waitingForStart" | "inProgress" | "complete" | "" | "pregame" | "inProgress" | "complete" | string;
  eliminationId?: number;
  joinCode?: string;
  history?: History;
  trophyId?: number;
  groupId?: number;
  seasonId?: string;
  totalTeams?: number;
  isCrowns?: boolean;
};

type Props = {
  franchise?: Franchise;
  league?: Partial<League>;
  arrow?: "up" | "down";
};

const LeagueHeader: React.FC<Props> = ({
  franchise: franchiseIds,
  league,
  arrow,
}) => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();
  const [franchise, setFranchise] = useState<any>(null);
  const { WALLET_TRANSACTION_FEE } = useFeeRates();

  const userTeamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const record = useSelector(
    (state: RootState) => state?.gameEngine?.UserRecord
  );

  const teamData = useSelector(
    (state: RootState) => state.gameEngine.teamLeaguesHistory
  );

  const arrowDirection = useMemo(() => {
    if (teamData) {
      const ratingHistory = teamData?.ratingHistory || [];
      const len = ratingHistory.length;
      for (let i = len - 1; i > 0; i--) {
        if (ratingHistory[i]?.rating > ratingHistory[i - 1]?.rating) {
          return "up";
        }
        if (ratingHistory[i] < ratingHistory[i - 1]) {
          return "down";
        }
      }
    }
  }, [teamData]);

  useEffect(() => {
    if (franchiseIds?.id) {
      setFranchise(
        userTeamList?.find((info: any) => info.id == franchiseIds?.id)
      );
    } else if (franchiseIds?.utid) {
      setFranchise(
        userTeamList?.find((info: any) => info.engId == franchiseIds?.utid)
      );
    } else if (franchiseIds?.engId) {
      setFranchise(
        userTeamList?.find((info: any) => info.engId == franchiseIds?.engId)
      );
    } else if (franchiseId) {
      setFranchise(userTeamList?.find((info: any) => info.id == franchiseId));
    }
  }, [franchiseId, franchiseIds, userTeamList]);

  useEffect(() => {
    if (franchise) {
      dispatch(getTeamLeaguesHistory(franchise?.engId));
      dispatch(UserRecordAPI(franchise?.engId));
    }
  }, [franchise]);

  const leagueType = useMemo(() => {
    if (league?.type) {
      return getFullGameType(league?.type);
    }

    return "N/A";
  }, [league]);

  const franchiseImage = useMemo(
    () =>
      franchise?.img
        ? `${API_URL}/images/franchise/userFranchise/${franchise?.img}`
        : Stickers,
    [franchise]
  );

  const data = useMemo(() => {
    if (!league) return null;

    return {
      ...league,
      profileimage: franchiseImage,
      leagueName: league?.title || leagueType || "SEASON NAME",
      leagueId: league?.gid || league?.id || "N/A",
      franchiseName: franchise?.teamName || franchise?.name || "N/A",
      ratingRangeMin: league?.ratingRangeMin || "N/A",
      ratingRangeMax: league?.ratingRangeMax || "N/A",
      ratingRange:
        league?.ratingRangeMin && league?.ratingRangeMax
          ? `${league?.ratingRangeMin} TO ${league?.ratingRangeMax}`
          : "N/A",
      type: leagueType || "N/A",
      typeLetter: league?.type,
      fee: league && league.fee ? `$ ${league.fee}` : "N/A",
      started: league?.started
        ? moment(league?.started).format("MM/DD/YYYY hh:mm")
        : "N/A",
      gameTime: league?.gameTime
        ? moment(league?.gameTime).format("MM/DD/YYYY hh:mm")
        : "N/A",
      record: record
        ? `${record?.won || 0}-${record?.lost || 0}-${record?.tied || 0}`
        : "0-0-0",
      teamWinningPercentage:
        franchise?.winRate ?? teamData?.winRate ?? record?.pct ?? "N/A",
      teamFanBase: "0",
      teamRating: franchise?.ovr ?? record?.ovr ?? "N/A",
    };
  }, [franchise, teamData, league, record, WALLET_TRANSACTION_FEE]);

  return <LeagueHeaderDetail data={data} arrow={arrowDirection} />;
};

export default LeagueHeader;
