type Props = {
  title?: string;
  data: {
    message: string;
    value: number | string | JSX.Element;
  }[];
};

export const Balances: React.FC<Props> = ({ title, data }) => (
  <div className="wallet-balances-info">
    {title && (
      <h5 className="mb-4">
        <strong>{title}</strong>
      </h5>
    )}
    {data.map((item, index) => {
      const value = typeof item.value === 'number' ? item.value.toFixed(2) : item.value;
      return (
        <div className="wallet-balances-info__balance" key={index}>
          <div className="wallet-balances-title">{item.message}</div>
          <div className="wallet-balances-balance">{value}</div>
        </div>
      );
    })}
  </div>
);
