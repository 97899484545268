import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamRoster } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useRoster = (engId?: number) => {
  const dispatch = useDispatch();
  const teamRosterData = useSelector((state: RootState) => state.gameEngine.teamRoster);

  const loadData = useCallback(() => {
    if (!engId) return;
    dispatch(getTeamRoster(engId));
  }, [engId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return { teamRosterData, loadData };
};
