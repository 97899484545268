import { Row, Col } from 'react-bootstrap';
import React from 'react';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import history from 'routes/history';
import trophyImage from '../../../assets/images/trophy/trophy_example.png';

interface PurchaseTrophyProps {
  image?: string;
  title?: string;
  description?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const defaultProps: PurchaseTrophyProps = {
  image: trophyImage,
  title: 'CREATE TROPHY',
  description: 'REWARD TOURNAMENT WINNERS WITH TROPHIES AND EARN ADDITIONAL PRESTIGE POINTS.',
  buttonText: 'CREATE',
  buttonOnClick: () => history.push('/purchase-trophy'),
};

export const PurchaseTrophy: React.FC<PurchaseTrophyProps> = ({
  image = defaultProps.image,
  title = defaultProps.title,
  description = defaultProps.description,
  buttonText = defaultProps.buttonText,
  buttonOnClick = defaultProps.buttonOnClick,
}) => (
  <div className="leagueListMain franchiselist mb-5">
    <Row>
      <Col sm={12} xl={4} lg={4}>
        <div className="leagueDetailLeft">
          <div className="leagueImage">
            <div className="imgWithRatingContainer">
              <img src={image} alt="leagueImage" className="imgFluid" style={{ cursor: 'pointer' }} />
            </div>
          </div>
        </div>
      </Col>
      <Col sm={12} xl={8} lg={8}>
        <Row>
          <Col xl={12} lg={12}>
            <div className="leaguedetail_head">
              <h5>{title}</h5>
            </div>
          </Col>
          <Col sm={12} xl={8} lg={8}>
            <div className="leagueDetailRight blue font14" style={{ textAlign: 'left' }}>
              {description}
            </div>
          </Col>
          <Col xl={4} lg={4} className="create-team-button-container">
            <BlackButton text={buttonText} onClick={buttonOnClick} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
