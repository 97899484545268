import { useRef, useImperativeHandle, forwardRef } from 'react';
import { Stack } from 'react-bootstrap';
import './PlayerCreation.scss';
import { disabledOptions } from './constants';
import { RosterPlayer, UserTeamsList } from 'types';
import playerComponents from './svgs';
import { Card, Form, Input, Switch } from 'antd';
import html2canvas from 'html2canvas';
import { useDispatch } from 'react-redux';
import { setFrontImage } from 'redux/actions/AppAction';

type Props = {
  team: UserTeamsList[0] | null;
  roster: RosterPlayer | null;
}

type PlayerCreationHandle = {
  submit: () => void;
};

const inputStyle = {
  width: 50,
};

const initialColors = {
  mainColor: '#99243e',
  secondaryColor: '#ffffff',
  ballColor: '#ad4643',
  skinColor: '#f6c9b8',
  bootsColor: '#434343',
  splashColor: '#99243e',
  splash: true,
  backgroundColor: '#ffffff',
} as const;

export const PlayerCreation = forwardRef<PlayerCreationHandle, Props>(({ roster }, ref) => {
  const dispatch = useDispatch();
  const refFrontImage = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm<typeof initialColors>();
  Form.useWatch([], form);

  // const [texture, setTexture] = useState<
  //   TrophyAssetsObject["texture"][0] | null
  // >(null);

  // const trophyAssets = useTrophyAssets();

  const disabledValues = disabledOptions[roster?.pos as keyof typeof playerComponents || 'QB'] || [];
  const position = roster?.pos as keyof typeof playerComponents || 'QB';
  const Component = playerComponents[position];

  const captureImage = async () => {
    if (refFrontImage.current) {
      try {
        const canvas = await html2canvas(refFrontImage.current);
        canvas.toBlob((blob) => {
          if (blob) {
            dispatch(setFrontImage(blob));
          }
        }, 'image/png');

      } catch (error) {
        console.error("Error capturing image:", error);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      captureImage();
    },
  }));

  if (!Component) {
    console.error(`Component for position "${position}" is not defined.`);
    return null;
  }

  return (
    <Stack gap={4} direction="horizontal">
      <Card style={{
        minWidth: '300px',
        maxWidth: '500px',
        borderRadius: "20px",
        width: "100%",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
      }} ref={refFrontImage}>
        <Component
          backgroundImage={undefined}
          backgroundColor={form.getFieldValue('backgroundColor')}
          mainColor={form.getFieldValue('mainColor')}
          ballColor={form.getFieldValue('ballColor')}
          skinColor={form.getFieldValue('skinColor')}
          secondaryColor={form.getFieldValue('secondaryColor')}
          bootsColor={form.getFieldValue('bootsColor')}
          splashColor={form.getFieldValue('splash') ? form.getFieldValue('splashColor') : 'rgba(0, 0, 0, 0)'}
          splash={form.getFieldValue('splash')}
          logoImage={undefined}
          ref={null}
        />
      </Card>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialColors}
        size="middle"
        style={{
          width: '100%',
        }}
      >
        <Form.Item name="mainColor" label="1. Main Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('mainColor')}
          />
        </Form.Item>
        <Form.Item name="secondaryColor" label="2. Secondary Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('secondaryColor')}
          />
        </Form.Item>
        <Form.Item name="ballColor" label="3. Ball Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('ballColor')}
          />
        </Form.Item>
        <Form.Item name="skinColor" label="4. Skin Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('skinColor')}
          />
        </Form.Item>
        <Form.Item name="bootsColor" label="5. Boots Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('bootsColor')}
          />
        </Form.Item>
        <Form.Item label="6. Splash Color">
          <Stack direction="horizontal" gap={2}>
            <Form.Item name="splashColor" noStyle>
              <Input
                style={inputStyle}
                type="color"
                disabled={disabledValues.includes('splashColor')}
              />
            </Form.Item>
            <Form.Item name="splash" valuePropName="checked" noStyle>
              <Switch />
            </Form.Item>
          </Stack>
        </Form.Item>
        <Form.Item name="backgroundColor" label="8. Background Color">
          <Input
            style={inputStyle}
            type="color"
            disabled={disabledValues.includes('backgroundColor')}
          />
        </Form.Item>
      </Form>
    </Stack>
  );
});
