import { RosterPlayerWithAction } from '../../RosterTabMain';

export const getAdditionalTextByAction = (action?: RosterPlayerWithAction['action']) => {
  switch (action) {
    case 'release':
      return ' Releasing player will remove player from your roster. You will not be able to reverse the decision if you proceed.';

    case 'trade':
      return ' Putting player on trade block will make player available for trade. Anytime another user makes an offer, you will be notified.';

    default:
      return '';
  }
};
