import { Modal } from 'antd';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { FreeAgents } from 'components/TradesAndFreeAgents/types';
import './TradeProposalModal.scss';
import { useCreateTrade } from '../hooks/useCreateTrade';
import { TradeProposalModalContent } from './TradeProposalModalContent';
import { useTradeActions } from 'components/Profile/components/TradeProposals/hooks/useTradeActions';

type Props = {
  open: boolean;
  onClose: () => void;
  playersToReceive: FreeAgents['freeAgents'];
  playersToSend: FreeAgents['freeAgents'];
  tradeId?: string; // For counter trade
};

export const TradeProposalModal: React.FC<Props> = ({ onClose, open, playersToReceive, playersToSend, tradeId }) => {
  const { handleCreateTrade, isLoading } = useCreateTrade();
  const { loading, request } = useTradeActions(tradeId);

  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      width={600}
      footer={
        <div className="modal-footer-buttons">
          <PinkButton
            text="SUBMIT TRADE"
            disabled={isLoading || loading}
            onClick={() => {
              if (tradeId) {
                request('counter', [playersToReceive.map((p) => p.pid), playersToSend.map((p) => p.pid)]);
                return;
              }

              handleCreateTrade([playersToReceive.map((player) => player.pid), playersToSend.map((player) => player.pid)]);
            }}
          />
          <BlackButton text="CANCEL TRADE" onClick={onClose} />
        </div>
      }
    >
      <h6 className="trade-proposal-modal-title">{tradeId ? "COUNTER ": ""}TRADE PROPOSAL</h6>
      <TradeProposalModalContent playersToReceive={playersToReceive} playersToSend={playersToSend} />
    </Modal>
  );
};
