import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import './CreateTradeProposal.scss';
import Title from 'components/Common/Title/Title';
import { OnTradeBlock } from '../types';
import { Row, Col } from 'react-bootstrap';
import { getPositionFullName } from 'helper/helper';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { usePositionalTraits } from '../hooks/usePositionalTraits';
import { formatKeyWithSpaces } from 'components/PlayerDetail/utils/formatKey';
import { PlayerSelector } from '../PlayerSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useTeamPlayersWithSomeStats } from '../hooks/useTeamPlayersWithSomeStats';
import { useEffect, useState } from 'react';
import { getTradeAwayPlayers } from '../constants';
import { TradeProposalModal } from './modals/TradeProposalModal';

type Props = {
  player: OnTradeBlock['playersOnTrade'][number];
};

const traitKeyMap = {
  heightText: 'HEIGHT',
  weight: 'WEIGHT',
  spd: 'SPD',
  stre: 'STR',
  hgt: 'AGL',
  endu: 'EXP',
};

export const CreateTradeProposal: React.FC<Props> = ({ player }) => {
  const { traits } = usePositionalTraits(player.pid);
  const utid: number | undefined = useSelector((state: RootState) => state.team.leagueEnterFormData?.franchise?.engId);
  const players = useTeamPlayersWithSomeStats(utid);

  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);

  const columns = getTradeAwayPlayers(selectedPlayers, setSelectedPlayers);

  useEffect(() => {
    setSelectedPlayers([]);
  }, [utid]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <Card>
        <CardBody>
          <div className="trade-proposal">
            <h6 className="mb-4">CREATE TRADE PROPOSAL</h6>
            <Title text="TRADE FOR" />
            <Row className="trade-for-player">
              <Col xl={2} xs={12}>
                <img src={require(`../../../assets/images/player/${player.pos}.png`)} alt="profile" width="100%" />
              </Col>
              <Col xl={3} xs={3}>
                <div className="player-basic-info">
                  <span>
                    {player.firstName} {player.lastName}
                  </span>
                  <span>{player.bornLoc}</span>
                  <span>{getPositionFullName(player.pos)}</span>
                </div>
              </Col>
              <Col xl={6} xs={8}>
                <div className="player-basic-info__right">
                  <Row>
                    {Object.entries(traitKeyMap).map(([key, name]) => (
                      <Col xs={2} className="player-basic-info__right__trait">
                        <span>{name}</span>
                        <span>{player[key as keyof typeof player]}</span>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    {Object.keys(traits || {})
                      .filter((t) => t !== 'pid')
                      .map((t) => (
                        <Col xs={2} className="player-basic-info__right__trait" key={t}>
                          <span>{formatKeyWithSpaces(t, '_')}</span>
                          <span>{Math.round(traits?.[t as keyof typeof traits] || 0)}</span>
                        </Col>
                      ))}
                  </Row>
                </div>
              </Col>
              <Col xl={1} xs={1} className="ovr-container">
                <div>{player.ovr}</div>
              </Col>
            </Row>

            <Title text="TRADE AWAY" />
            <div className="players-to-trade-container">
              <PlayerSelector pagination={false} players={players} columns={columns} />
            </div>
            <div className="propose-button-container">
              <BlueButton text="PROPOSE TRADE" onClick={() => setIsSubmitting(true)} disabled={!selectedPlayers.length}/>
            </div>
          </div>
        </CardBody>
      </Card>

      <TradeProposalModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
        playersToReceive={[player]}
        playersToSend={selectedPlayers.map((pid) => players.find((p) => p.pid === pid)!)}
      />
    </>
  );
};
