import { Col, Row } from 'react-bootstrap';
import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PayPalButton } from 'components/Common/PayPalButton/PayPalButton';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Balances } from 'components/Common/Balances/Balances';
import { useFeeRates } from 'helper/useFeeRates';
import { RootState } from 'redux/store';
import history from 'routes/history';
import { cleanSavePaymentData } from 'redux/actions/AppAction';
import { updateData } from 'redux/actions/AuthAction';
import './AcquireCrowns.scss';
import { MVP_CONVERSION_RATE } from 'config';
import image from '../../../../assets/images/leaguePayment/MVPC.png';
import { MVPPrice } from 'components/Common/MVPPrice/MVPPrice';

export const AcquireCrowns: React.FC = () => {
  const { PAYPAL_FEE_AMOUNT, PAYPAL_FEE_PERCENT } = useFeeRates();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const savePaymentError = useSelector((state: RootState) => state.app.savePaymentError);
  const savePaymentDataSuccess = useSelector((state: RootState) => state.app.savePaymentDataSuccess);
  const userData = useSelector((state: RootState) => state.app.resData);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const redirectBack = queryParams.get('redirectBack');
  const handleRedirectBack = useCallback(() => {
    if (redirectBack) {
      history.push(`/${redirectBack}`);
    }
  }, [redirectBack]);

  useEffect(() => {
    if (savePaymentError) {
      notification.error({
        description: savePaymentError,
        message: 'PAYMENT VERIFICATION FAILED',
        placement: 'bottomRight',
      });
    }
    if (savePaymentDataSuccess) {
      notification.success({
        description: 'Payment successfull',
        message: 'OK',
        placement: 'bottomRight',
      });
      dispatch(cleanSavePaymentData());
      dispatch(updateData());
      handleRedirectBack();
    }
  }, [savePaymentError, savePaymentDataSuccess, dispatch, userData, handleRedirectBack]);

  useEffect(() => {
    dispatch(cleanSavePaymentData());
  }, [dispatch]);

  if (PAYPAL_FEE_AMOUNT === undefined || PAYPAL_FEE_PERCENT === undefined) {
    return null;
  }

  const fee = (amount * PAYPAL_FEE_PERCENT) / 100 + PAYPAL_FEE_AMOUNT;

  return (
    <div>
      <Row>
        {[2, 5, 10, 15, 25, 50].map((price) => (
          <Col className="mvp-crowns-pricecard" onClick={() => setAmount(price)} key={price} xl={4}>
            <Row>
              <Col xs={4}>
                <span className="mvp-crowns-pricecard__crowns">{price * MVP_CONVERSION_RATE}</span>
              </Col>
              <Col xs={4}>
                <img src={image} alt={'MVP Crowns'} />
              </Col>
              <Col xs={4}>
                <span className="mvp-crowns-pricecard__usd">{price} $</span>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      {PAYPAL_FEE_AMOUNT !== undefined && PAYPAL_FEE_PERCENT !== undefined && (
        <Balances
          data={[
            { message: 'DEPOSIT AMOUNT', value: <MVPPrice amt={amount} /> },
            { message: 'SECURITY & TRANSFER FEE', value: '$ ' + fee.toFixed(2) },
            { message: 'TOTAL', value: '$ ' + (amount + fee).toFixed(2) },
          ]}
        />
      )}
      {amount > 0 && (
        <div className="paymentbuttons">
          <PayPalButton
            product={{
              description: 'ADD MVP CROWNS',
              price: amount,
              orderType: 'MVPC_BALANCE',
            }}
          />
        </div>
      )}
    </div>
  );
};
