/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import Input from '../../Common/Form/Input/Input';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Divider, Table, notification } from 'antd';
import './WalletConnectedStyle.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearTransactionStatus, createOrder, updateData } from '../../../redux/actions/AuthAction';
import { getCreateFrenchiseDetail, getPartnerCode, userTeamsList } from '../../../redux/actions/TeamAction';
import { RootState } from '../../../redux/store';
import { getFee, getPriceWithFee } from '../../../helper/helper';
import { acquireFranchise, cleanAcquireFranchise } from '../../../redux/actions/AppAction';
import history from '../../../routes/history';
import { AddFundsModal } from 'components/Common/AddFundsModal/AddFundsModal';
import { useFeeRates } from 'helper/useFeeRates';
import { PreviewData } from '../Common/Preview';
import { Balances } from 'components/Common/Balances/Balances';
import { purchaseAmountTable } from './constants';
import { TrophyBeforePurchase } from 'components/PurchaseTrophy/types';
import { getTrophyPrice } from 'components/PurchaseTrophy/helper';
import { MVPPrice } from 'components/Common/MVPPrice/MVPPrice';

const Price = ({ price, isCrownsShown, isUsdShown }: { price: number | string; isCrownsShown: boolean; isUsdShown: boolean }) => (
  <>
    {isUsdShown ? `$ ${price}` : <></>}
    {isCrownsShown && isUsdShown ? ' | ' : <></>}
    {isCrownsShown ? <MVPPrice amt={+price} /> : <></>}
  </>
);

export interface Props {
  walletconnected?: any;
  payment?: any;
  walletconnectoption?: any;
  data?: any;
}

export type OrderTypes = 'leaguePayment' | 'purchaseFranchise' | 'purchaseTrophy' | 'mintPlayerPayment' | '';

const WalletConnected: React.FC<Props> = ({ payment, data }) => {
  const dispatch = useDispatch();

  const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const location = useLocation();
  const resData = useSelector((state: RootState) => state.app.resData);
  const acquireFranchiseError = useSelector((state: RootState) => state.app.acquireFranchiseError);
  const generalError = useSelector((state: RootState) => state.app.error);
  const acquireFranchiseSuccess = useSelector((state: RootState) => state.app.acquireFranchiseSuccess);
  const cityInfoData = useSelector((state: RootState) => state.team.cityInfo);
  const partnerCodeInfo = useSelector((state: RootState) => state.team.partnerCode);
  const transactionsStatus = useSelector((state: RootState) => state.app.transactionsStatus);

  const [code, setCode] = useState('');
  const [addFundsOpen, setAddFundsOpen] = useState(false);
  const [errorPromo, setErrorPromo] = useState('');
  const [discountPrice, setDiscountPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [codeDisbaled, setCodeDisble] = useState(false);
  const [cityInfo, setCityInfo] = useState<any>();
  const [orderType, setOrderType] = useState<OrderTypes>('');
  const [isPromoBalanceUsed, setIsPromoBalanceUsed] = useState(false);

  const [loading, setLoading] = useState(false);

  const trophyData: TrophyBeforePurchase | null = location.pathname === '/purchase-trophy' ? data : null;

  useEffect(() => {
    if (!isPromoBalanceUsed) {
      removePromocode();
    }
  }, [isPromoBalanceUsed]);

  useEffect(() => {
    switch (location.pathname) {
      case '/leaguepayment':
        setOrderType('leaguePayment');
        let tempData = {
          id: 1,
          name: 'League Payment',
          price: data?.leagueprice,
          type: 'League Payment',
        };
        setCityInfo(tempData);
        break;
      case '/purchasefranchise':
        setOrderType('purchaseFranchise');
        setCityInfo(cityInfoData);
        break;

      case '/purchase-trophy':
        setOrderType('purchaseTrophy');
        if (trophyData) {
          setCityInfo({
            id: 1,
            name: 'Trophy Payment',
            price: getTrophyPrice(trophyData.prestigePoints, trophyData.mint),
            type: 'Trophy Payment',
          });
        } else {
          setCityInfo(null);
        }
        break;

      default:
        setOrderType('');
        setCityInfo(cityInfoData);
        break;
    }
  }, [location.pathname, cityInfoData, data?.leagueprice, trophyData, data?.prestigePoints, data?.mint]);

  const shouldCrownsBeShown = !!data?.isCrowns || orderType !== 'leaguePayment';
  const shouldUsdBeShown = !data?.isCrowns || orderType !== 'leaguePayment';

  const CurrentPrice = useMemo(
    () =>
      ({ price }: { price: number | string }) =>
        <Price price={price} isCrownsShown={shouldCrownsBeShown} isUsdShown={shouldUsdBeShown} />,
    [shouldCrownsBeShown, shouldUsdBeShown],
  );

  useEffect(() => {
    if (cityInfo?.price !== undefined && fee !== undefined && fee !== null) {
      const cityUsdPrice = cityInfo?.price;
      setTotalPrice(cityUsdPrice - discountPrice + fee);
    }
  }, [discountPrice, cityInfo?.price, code, fee]);

  const tableData = useMemo(() => {
    const data = [
      {
        purchase: cityInfo?.type == 'RANDOM CITY' ? 'RANDOM CITY' : cityInfo?.name,
        amount: cityInfo?.price ? (
          <span>
            <CurrentPrice price={cityInfo.price} />
          </span>
        ) : (
          'FREE'
        ),
      },
      {
        purchase: <span className="pink">DISCOUNT</span>,
        amount: (
          <span className="pink">
            <CurrentPrice price={discountPrice.toFixed(2)} />
          </span>
        ),
      },
      {
        purchase: 'TOTAL',
        amount: (
          <span>
            <CurrentPrice price={Number(totalPrice).toFixed(2)} />
          </span>
        ),
      },
    ];

    if (data) {
      data.splice(2, 0, {
        purchase: 'FEE',
        amount: (
          <span>
            <CurrentPrice price={fee.toFixed(2)} />
          </span>
        ),
      });
    }

    if (trophyData !== null) {
      data.splice(1, 0, {
        purchase: `PRESTIGE POINTS ($0.5 * ${trophyData.prestigePoints})`,
        amount: `$ ${(trophyData.prestigePoints * 0.5).toFixed(2)}`,
      });
      if (trophyData.mint) {
        data.splice(2, 0, {
          purchase: 'MINT ($3.00)',
          amount: `$ 3.00`,
        });
      }
    }

    data.forEach((item, index) => ({ ...item, key: (index + 1).toString() }));

    return data;
  }, [cityInfo, discountPrice, fee, totalPrice, location.pathname]);

  useEffect(() => {
    const franchiseId = acquireFranchiseSuccess?.franchiseId;
    if (franchiseId) {
      dispatch(cleanAcquireFranchise());
      dispatch(updateData());
      dispatch(userTeamsList());
      dispatch(getCreateFrenchiseDetail(franchiseId));
      history.push(`/ownfranchise?toReveal=${franchiseId}`);
    }
    if (acquireFranchiseError) {
      const message = acquireFranchiseError?.response?.data?.error || 'Error while acquiring franchise';
      notification.error({
        message: 'Franchise Error',
        description: message,
        placement: 'bottomRight',
        duration: 15,
      });
      dispatch(cleanAcquireFranchise());
    }
    if (generalError) {
      notification.error({
        message: 'Error',
        description: generalError,
        placement: 'bottomRight',
      });
    }
  }, [acquireFranchiseSuccess, acquireFranchiseError, generalError]);

  useEffect(() => {
    dispatch(cleanAcquireFranchise());
    dispatch(clearTransactionStatus());
  }, []);

  const applyCode = () => {
    //
    if (code == '') {
      setErrorPromo('Promo Code Required');
      setDiscountPrice(0);
      setCodeDisble(false);
    } else {
      dispatch(getPartnerCode(code));
    }
  };
  const removePromocode = () => {
    setCode('');
    dispatch(getPartnerCode('NO'));
  };
  useEffect(() => {
    setErrorPromo('');
    setDiscountPrice(0);
  }, [code]);

  useEffect(() => {
    let cityUsdPrice: any;
    if (cityInfo?.price && WALLET_TRANSACTION_FEE !== undefined) {
      const priceWithoutPromoCredits = Number(cityInfo.price);
      setFee(getFee(Number(priceWithoutPromoCredits), WALLET_TRANSACTION_FEE, 0));
      cityUsdPrice = getPriceWithFee(priceWithoutPromoCredits, WALLET_TRANSACTION_FEE, 0).toFixed(2);
      setTotalPrice(cityUsdPrice);
    }
    const rate = partnerCodeInfo?.[0]?.rate;
    const status = partnerCodeInfo?.[0]?.status;
    let discount = 0;
    setDiscountPrice(0);
    if (status == 1) {
      setErrorPromo('Partner Code Already Used');
      setDiscountPrice(0);
      setCodeDisble(false);
    }
    if (status == 0 && code != '') {
      discount = rate;

      if (cityUsdPrice && WALLET_TRANSACTION_FEE !== undefined && +cityUsdPrice > +discount) {
        setDiscountPrice(discount);
        const totalPriceWithDiscount = cityInfo.price - discount;
        const newFee = getFee(totalPriceWithDiscount, WALLET_TRANSACTION_FEE, 0);
        setFee(newFee);
        setTotalPrice(totalPriceWithDiscount + newFee);
        setCodeDisble(true);
      } else if (cityUsdPrice == discount) {
        setDiscountPrice(discount);
        setTotalPrice(cityUsdPrice - discount);
        setCodeDisble(true);
        let check1: any | null = document.querySelector('#cb1');
        let check2: any | null = document.querySelector('#cb2');
        check1.checked = false;
        check2.checked = false;
      } else if (cityUsdPrice) {
        setDiscountPrice(+cityUsdPrice);
        setTotalPrice(0);
        setCodeDisble(true);
        if (discount < 0) {
          let check1: any | null = document.querySelector('#cb1');
          let check2: any | null = document.querySelector('#cb2');
          check1.checked = false;
          check2.checked = false;
        }
      }
    }
    if (partnerCodeInfo.length == 0) {
      setDiscountPrice(0);
      setCodeDisble(false);
    }
    if (partnerCodeInfo.length == 0 && code !== '') {
      setErrorPromo('Invalid Partner Code');
    }
  }, [partnerCodeInfo, cityInfo, WALLET_TRANSACTION_FEE]);

  // wallet payment
  const payWithWallet = async (isCrowns: boolean = false) => {
    const amount = Number(totalPrice).toFixed(2);
    const status = 'COMPLETED';
    const oderId = '';
    const transactionId = '';
    const emailAddress = '';
    const ether = '';
    const partnerCode = '';
    const paymentType = 'WALLET';
    const metamaskAccount = '';
    const city = cityInfo?.id;
    const orderTypeWallet = orderType + '_wallet';
    const franchiseData: (PreviewData & { teamName: string }) | null = cityInfo.franchiseData;

    if (orderType === 'purchaseFranchise') {
      const userId = resData?.id;
      const userName = resData?.userName;

      if (!userId || !userName || !franchiseData) {
        return;
      }
      dispatch(
        acquireFranchise({
          city,
          userId,
          userName,
          partnerCode: code,
          isCrowns,
          franchiseData,
        }),
      );
    } else if (orderType === 'leaguePayment') {
      dispatch(
        createOrder({
          amount,
          fee: fee.toFixed(2),
          status,
          oderId,
          transactionId,
          emailAddress,
          ether,
          partnerCode,
          paymentType,
          metamaskAccount,
          city,
          orderType: orderTypeWallet,
          gid: data.gid,
          type: data.leagueType,
          isCrowns,
          utid: data.engId,
        }, setLoading),
      );
    } else if (orderType === 'purchaseTrophy' && trophyData) {
      dispatch(
        createOrder({
          amount,
          fee: fee.toFixed(2),
          status,
          oderId,
          transactionId,
          emailAddress,
          ether,
          partnerCode,
          paymentType,
          metamaskAccount,
          city,
          orderType: orderTypeWallet,
          trophy: trophyData,
          isCrowns,
        }, setLoading),
      );
    } else if (orderType === 'mintPlayerPayment') {
      console.log('MINT PLAYER PAYMENT', data);
    }
  };

  useEffect(() => {
    if (code === '') {
      setDiscountPrice(0);
    }
  }, [code]);

  const handlePayment = async (isCrowns: boolean = false) => {
    if (+(resData?.walletBalance || 0) < totalPrice && !isCrowns) {
      setAddFundsOpen(true);
      return;
    }

    if (+(resData?.promoBalance || 0) < totalPrice && isCrowns) {
      notification.error({
        message: 'Insufficient Crowns',
        description: 'You do not have enough MVP Crowns to make this purchase',
        placement: 'bottomRight',
      });
      return;
    }

    await payWithWallet(isCrowns);
  };

  return (
    <>
      <Balances
        title="WALLET BALANCE:"
        data={[
          { message: 'WITHDRAWABLE', value: `$ ${resData?.walletBalance}` },
          { message: 'MVP Crowns', value: <MVPPrice amt={resData?.promoBalance} /> },
        ]}
      />
      {payment && cityInfo && (
        <>
          {orderType === 'leaguePayment' && (
            <div className="franchisecity">
              <h5>{cityInfo?.name}</h5>
            </div>
          )}
          <Divider className="pricelinehori" />
          <Table columns={purchaseAmountTable} pagination={false} dataSource={tableData} size="small" className="mt-3 paymentprice" />
          <div className="paymentoption mt-3">
            <h5>PROCEED PAYMENT</h5>
            <Row>
              {shouldUsdBeShown && (
                <Col xs={shouldCrownsBeShown && shouldUsdBeShown ? 6 : 12}>
                  <li className="col-12">
                    <input type="radio" name="test" id="cb3" disabled={loading} onClick={() => handlePayment(false)} />
                    <label htmlFor="cb3">
                      <div className="gmdtoken">
                        <p>$ {Number(totalPrice || 0).toFixed(2)}</p>
                      </div>
                    </label>
                  </li>
                </Col>
              )}
              {shouldCrownsBeShown && (
                <Col xs={shouldCrownsBeShown && shouldUsdBeShown ? 6 : 12}>
                  <li className="col-12">
                    <input type="radio" name="test" id="crowns" disabled={loading} onClick={() => handlePayment(true)} />
                    <label htmlFor="crowns">
                      <div className="gmdtoken">
                        <p>
                          <MVPPrice amt={totalPrice} />
                        </p>
                      </div>
                    </label>
                  </li>
                </Col>
              )}
              <Col>
                {+cityInfo.price > 0 && (
                  <div className="form-check">
                    <input
                      id="promo-credit-checkbox"
                      className="form-check-input"
                      type="checkbox"
                      onClick={() => setIsPromoBalanceUsed((currentValue) => !currentValue)}
                      checked={isPromoBalanceUsed}
                    />
                    <label className="form-check-label" htmlFor="promo-credit-checkbox">
                      APPLY PROMOTION
                    </label>
                  </div>
                )}
              </Col>
            </Row>
            {isPromoBalanceUsed && orderType !== 'leaguePayment' && discountPrice === 0 && +cityInfo.price > 0 && totalPrice > 0 && (
              <>
                <h5>PARTNER CODE</h5>
                <Input type="text" value={code} onChange={setCode} placeholder="ENTER PARTNER CODE" disabled={codeDisbaled} />
                <div className="mt-3 proceed mb-3">
                  <button
                    className="blackButton"
                    disabled={code != '' ? false : true}
                    onClick={applyCode}
                    style={{
                      backgroundColor: code != '' ? '#384c6c' : '',
                    }}
                  >
                    APPLY CODE
                  </button>
                </div>
              </>
            )}
            {orderType !== 'leaguePayment' && errorPromo && <p className="error">{errorPromo}</p>}
          </div>
        </>
      )}
      {transactionsStatus?.status !== 'success' && <p>{transactionsStatus?.message}</p>}
      <AddFundsModal
        open={addFundsOpen}
        onClose={() => setAddFundsOpen(false)}
        depositMessage="In order to purchase a franchise, you must add money to your GMDynasty Wallet"
      />
    </>
  );
};

export default WalletConnected;
