import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { Profile } from 'types';
import { Friends, Groups, Teams } from './components';
import './profile-content.scss';
import { UserTrophies } from './components/common/Trophies/UserTrophies';
import { TradeProposals } from './components/TradeProposals/TradeProposals';

type Props = {
  profile: Profile;
  isOwn: boolean;
};

export const ProfileContent: React.FC<Props> = ({ profile, isOwn }) => (
  <Card>
    <CardBody>
      <Teams isOwn={isOwn} profile={profile} />
      <UserTrophies isOwn={isOwn} profile={profile} />
      <Friends isOwn={isOwn} />
      <Groups isOwn={isOwn} profileId={profile.id} />
      <TradeProposals isOwn={isOwn} profileId={profile.id} />
    </CardBody>
  </Card>
);
