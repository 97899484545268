import { useLoading } from 'components/FranchiseOverview/hooks/use-loading';
import { usePlayersToBuy } from './hooks/usePlayersToBuy';
import { getFreeAgentsColumns, getOnTradeBlockColumns } from './constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useState } from 'react';
import { FreeAgents, OnTradeBlock } from './types';
import { AcquireFreeAgentModal } from './modals/AcquireFreeAgentModal';
import { handleAcquireFreeAgent } from './helper';
import { PlayerSelector } from './PlayerSelector';

type Props = {
  mode: 'freeAgents' | 'onTradeBlock';
  setPlayerToTrade?: (player: OnTradeBlock['playersOnTrade'][number]) => void;
};

export const PlayersToBuy: React.FC<Props> = ({ mode, setPlayerToTrade }) => {
  const { players, setPage, setPos, limit, setLimit, pos } = usePlayersToBuy(mode);
  const loading = useLoading();

  const franchise = useSelector((state: RootState) => state.team.leagueEnterFormData?.franchise);
  const id: number | undefined = franchise?.id;
  const utid: number | undefined = franchise?.engId;

  const [selectedFreeAgent, setSelectedFreeAgent] = useState<FreeAgents['freeAgents'][number] | null>(null);

  const freeAgentsColumns = getFreeAgentsColumns(setSelectedFreeAgent);
  const onTradeBlockColumns = getOnTradeBlockColumns(setPlayerToTrade);

  const acquireFreeAgent = () => {
    if (!selectedFreeAgent || !utid || !id || mode !== 'freeAgents') return;

    handleAcquireFreeAgent(utid, selectedFreeAgent.pid, id);
  };

  return (
    <>
      <PlayerSelector
        columns={mode === 'freeAgents' ? freeAgentsColumns : onTradeBlockColumns}
        pos={pos}
        setPos={setPos}
        players={!!players && 'freeAgents' in players ? players.freeAgents : players?.playersOnTrade}
        pagination={{
          pageSize: limit,
          total: players?.count || 0,
          disabled: loading,
          onChange: setPage,
          pageSizeOptions: [5, 10, 20, 50],
          onShowSizeChange: (c, s) => {
            setPage(c);
            setLimit(s);
          },
        }}
      />

      {selectedFreeAgent && mode === 'freeAgents' && (
        <AcquireFreeAgentModal open={!!selectedFreeAgent} onClose={() => setSelectedFreeAgent(null)} agent={selectedFreeAgent} acquireFreeAgent={acquireFreeAgent} />
      )}
    </>
  );
};
