import { Container } from 'react-bootstrap';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { LobbyContent } from './LobbyContent';
import { ProfileHeader } from 'components/Common/Header/ProfileHeader';
import { Tab, Nav } from 'react-bootstrap';
import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { useState } from 'react';
import { LeaderboardContent } from 'components/Leaderboard/LeaderboardContent';
import { TradesAndFreeAgents } from 'components/TradesAndFreeAgents/TradesAndFreeAgents';

enum TabEnum {
  LOBBY = 'LOBBY',
  LEADERBOARD = 'LEADERBOARD',
  'TRADES & FA' = 'TRADES & FA',
}

export function LobbyMain() {
  const user = useSelector((state: RootState) => state.app.resData);
  const [tab, setTab] = useState(TabEnum.LOBBY);

  if (!user) return null;

  return (
    <Container style={{ paddingTop: '1.5rem', paddingBottom: '4rem' }} className="lobby">
      <ProfileHeader userId={user?.id} />
      <div className="standingTabbarMain">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={TabEnum.LOBBY}
          activeKey={tab}
          onSelect={(key) => setTab((key as TabEnum) || TabEnum.LOBBY)}
          mountOnEnter
          unmountOnExit
        >
          <div className="standingTabbar">
            <Card>
              <CardBody>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.LOBBY}>LOBBY</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.LEADERBOARD}>LEADERBOARD</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum['TRADES & FA']}>TRADES & FA</Nav.Link>
                  </Nav.Item>
                </Nav>
              </CardBody>
            </Card>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey={TabEnum.LOBBY}>
              <LobbyContent />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.LEADERBOARD}>
              <LeaderboardContent />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum['TRADES & FA']}>
              <TradesAndFreeAgents />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
  );
}
