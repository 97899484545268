import { Row } from 'react-bootstrap';
import { Pagination } from '../common';
import { useUserTradeProposals } from './hooks/useUserTradeProposals';
import { Trade } from './Trade';
import React from 'react';

type Props = {
  profileId: number;
  isOwn: boolean;
};

export const TradeProposals: React.FC<Props> = ({ profileId, isOwn }) => {
  const { handleNextPage, handlePrevPage, page, tradeProposals } = useUserTradeProposals(profileId);

  return (
    <div className="groups section">
      <div className="section__header">
        <h5>TRADE PROPOSALS</h5>
        <div className="right">
          <Pagination page={page} hasNextPage={!!tradeProposals?.hasNextPage} hasPreviousPage={page > 1} setNextPage={handleNextPage} setPreviousPage={handlePrevPage} />
        </div>
      </div>
      <div className="section__body">
        {!!tradeProposals?.data?.length ? (
          tradeProposals?.data.map((item, i) => (
            <React.Fragment key={item._id}>
              <Row>
                <Trade trade={item} userId={profileId} />
              </Row>

              {i !== tradeProposals.data.length - 1 && <hr />}
            </React.Fragment>
          ))
        ) : (
          <h5>No trade proposals</h5>
        )}
      </div>
    </div>
  );
};
