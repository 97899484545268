import Error from '../../../../../assets/images/common/error.svg';
import { Modal, Spin } from 'antd';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { RosterPlayerWithAction } from '../RosterTabMain';
import WhiteButton from 'components/Common/Button/WhiteButton/WhiteButton';
import { getAdditionalTextByAction } from './helper/additionalText';

type Props = {
  onOk: (p: RosterPlayerWithAction) => Promise<void>;
  onCancel: () => void;
  playerWithAction: RosterPlayerWithAction | null;
  disabled?: boolean;
};

export const RosterTradeModal: React.FC<Props> = ({ onCancel, onOk, playerWithAction, disabled }) => (
  <Modal
    centered
    className="rostererror"
    onCancel={onCancel}
    onOk={() => onOk(playerWithAction as RosterPlayerWithAction)}
    open={!!playerWithAction}
    footer={
      <div className="modal-footer-buttons">
        <WhiteButton onClick={onCancel} text="CANCEL" disabled={disabled} />
        <BlueButton onClick={() => onOk(playerWithAction as RosterPlayerWithAction)} text={disabled ? <Spin /> : 'OK'} disabled={disabled} />
      </div>
    }
    width={450}
  >
    <div className="startdraftmain">
      <img src={Error} alt="erroricon" />
      <p>
        Are you sure you want to {playerWithAction?.action} {playerWithAction?.firstName} {playerWithAction?.lastName}, who plays as a {playerWithAction?.pos} with a
        rating of {playerWithAction?.rating}?{getAdditionalTextByAction(playerWithAction?.action)}
      </p>
    </div>
  </Modal>
);
