import { useEffect, useState } from "react";
import { getPath } from "./helper";
import "./RenderTrophy.scss";

type Props = {
  trophyId?: number;
  path?: string;
  mode?: "full" | "cup";
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const RenderTrophy: React.FC<Props> = ({ trophyId, path, mode, style = {}, onClick }) => {
  const [imagePath, setImagePath] = useState<string | null>(null);

  const id = mode === "full" ? "rendered-trophy" : "";

  useEffect(() => {
    if (trophyId && mode) {
      getPath(trophyId, mode).then((path) => {
        if (path) {
          setImagePath(path);
        }
      });
    } else if (path) {
      setImagePath(path);
    }
  }, [trophyId, mode, path]);

  if (!imagePath) {
    return null;
  }

  return (
    <div className="trophy_container" style={onClick ? { cursor: "pointer" } : {}}>
      <img id={id} src={imagePath} alt="trophy" style={style} onClick={onClick} />
    </div>
  );
};
