import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { Pagination } from 'components/Profile/components/common/pagination';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useGroupMembers } from '../Common/hooks';
import { GroupMember } from './GroupMember';

export const GroupMembers: React.FC = () => {
  const { group, paginationOptions, approvedMembersPaginated, requestedMembers, approvedMembers, userInGroupData } = useGroupMembers();
  const history = useHistory();

  const showRequests = !group?.autoApprove && ['admin', 'owner'].includes(String(userInGroupData?.role.name)) && !!requestedMembers?.length;

  const handleRedirectToRequests = () => {
    history.push(`/group-requests/${group?.id}`);
  };

  return (
    <div className="section">
      <div className="section__header">
        <h5>GROUP MEMBERS</h5>
        <div className="right">
          {showRequests && <PinkButton onClick={handleRedirectToRequests} text={`REQUESTS (${requestedMembers?.length || 0})`} />}
          <Pagination {...paginationOptions} />
        </div>
      </div>
      <div className="section__body">
        <Row>
          {approvedMembers && approvedMembers.length ? (
            approvedMembersPaginated.map((member) => (
              <Col key={member.user.id} xl={2} lg={2} md={4} sm={6} xs={12}>
                <GroupMember key={member.user.id} data={member.user} />
              </Col>
            ))
          ) : (
            <h5>No members</h5>
          )}
        </Row>
      </div>
    </div>
  );
};
