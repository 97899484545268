import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Title from 'components/Common/Title/Title';
import { PlayersToBuy } from './PlayersToBuy';
import { OnTradeBlock } from './types';
import { useState } from 'react';
import { CreateTradeProposal } from './CreateTradeProposal/CreateTradeProposal';
import { TradesHistory } from './TradesHistory/TradesHistory';

export const TradesAndFreeAgents: React.FC = () => {
  const [selectedPlayerToTrade, setSelectedPlayerToTrade] = useState<OnTradeBlock['playersOnTrade'][number] | null>(null);

  if (selectedPlayerToTrade) {
    return <CreateTradeProposal player={selectedPlayerToTrade}/>;
  }

  return (
    <Card>
      <CardBody>
        <Title text="PLAYER TRADE BLOCK" className="mb-4" />
        <PlayersToBuy mode={'onTradeBlock'} setPlayerToTrade={setSelectedPlayerToTrade} />
        <Title text="FREE AGENTS" />
        <PlayersToBuy mode={'freeAgents'} />
        <Title text="TRADE HISTORY" />
        <TradesHistory />
      </CardBody>
    </Card>
  );
};
