import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useTeamNFTS } from '../../MarketplaceScreen/hooks/useTeamNFTS';
import { API_URL } from 'config';
import './MarketplaceTeams.scss';
import { PaginationPages } from 'components/HistoricalResults/PaginationPages';
import history from 'routes/history';
import { Image, Input, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';

interface MarketplaceTeamsProps { }

export const MarketplaceTeams: React.FC<MarketplaceTeamsProps> = () => {
  const { handleNextPage, handlePrevPage, page, setPage } = useServerSidePagination();
  const [search, setSearch] = useState('');
  const { loading, nfts } = useTeamNFTS(page, 20, search);

  return (
    <div className="marketplace-teams">
      <Input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        addonBefore={<SearchOutlined />}
        placeholder="Search by name"
        type="search"
        className="marketplace-teams-search"
      />
      <div className="marketplace-teams-list">
        {nfts.map((nft) => (
          <div key={nft.id} className="marketplace-teams-list__team" onClick={() => history.push(`/marketplace/teams/${nft.id}`)}>
            <Image
              preview={false}
              width={200}
              height={250}
              src={`${API_URL}/images/franchise/userFranchise/${nft.img}`}
              alt={nft.teamName}
              className="marketplace-teams-list__team__image"
            />
            <Typography.Title level={4} className="mt-2">{nft.teamName}</Typography.Title>
          </div>
        ))}
      </div>
      <div className="marketplace-teams-pagination">
        <PaginationPages
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          page={page}
          nextDisabled={loading || nfts.length === 0}
          onPageClick={(e, shift = 0) => setPage(+(e.target as HTMLElement).innerText + shift || 1)}
        />
      </div>
    </div>
  );
};
