import './BlackButtonStyle.scss';

export interface Props {
  text?: any;
  onClick?: any;
  disabled?: boolean;
}

const BlackButton: React.FC<Props> = ({ text, onClick, disabled }) => (
  <button className="blackButton" onClick={onClick} disabled={disabled}>
    {text}
  </button>
);

export default BlackButton;
