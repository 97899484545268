import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import { notification } from "antd";
import { PayPalButtonProps } from "components/Common/PayPalButton/PayPalButton";
import { useDispatch, useSelector } from "react-redux";
import { savePaymentData } from "redux/actions/AppAction";
import { createOrder } from "redux/actions/AuthAction";
import { RootState } from "redux/store";

export const usePayPalHandlers = (product: PayPalButtonProps["product"]) => {
  const dispatch = useDispatch();
  const resData = useSelector((state: RootState) => state.app.resData);

  const handleApprove = async (
    data: OnApproveData,
    actions: OnApproveActions
  ) => {
    const order = await actions.order?.capture();
    const purchaseData = order && order.purchase_units &&order.purchase_units.at(-1);
    const emailAddress =
      purchaseData && purchaseData.payee && purchaseData.payee.email_address;
    const transactionId =
      purchaseData &&
      purchaseData.payments &&
      purchaseData.payments.captures &&
      purchaseData.payments.captures[0] &&
      (purchaseData.payments.captures[0].id as string | undefined);

    if (order && resData && purchaseData && emailAddress && transactionId && order.status) {
      if (["BALANCE", "MVPC_BALANCE"].includes(product.orderType)) {
        if (purchaseData?.amount?.value ) {
          dispatch(
            savePaymentData({
              userId: resData.id,
              amount: purchaseData.amount.value,
              fee: product.fee?.toFixed(2) || "",
              emailAddress,
              oderId: data.orderID,
              paymentType: product.orderType === "BALANCE" ? "PAYPAL" : "PAYPAL_crowns",
              status: order.status,
              transactionId,
              orderType: product.orderType,
            })
          );
        }
      } else {
        const partnerCode = product.partnerCode || "";
        const metamaskAccount = resData?.metaMaskID || null;
        if (purchaseData?.amount?.value) {
          dispatch(
            createOrder({
              amount: purchaseData.amount.value,
              fee: product.fee?.toFixed(2) || "",
              status: order.status || "UNKNOWN",
              oderId: data.orderID,
              transactionId,
              emailAddress,
              ether: "",
              partnerCode,
              paymentType: "PAYPAL",
              metamaskAccount,
              city: product.city || null,
              orderType: product.orderType,
            })
          );
        }
      }
    }
  };

  const handleError = () => {
    notification.error({
      message: "Error",
      description: "An error occurred during the payment process",
      placement: "bottomRight",
    });
  };

  const handleCancel = () => {
    notification.info({
      message: "Error",
      description: "The user has cancelled the payment",
      placement: "bottomRight",
    });
  };

  return { handleApprove, handleCancel, handleError };
};
