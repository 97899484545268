export const INIT_APP = "INIT_APP";

export const SET_DATA_REQUEST = "SET_DATA_REQUEST";
export const SET_DATA = "SET_DATA";

export const SAVE_PAYMENT_DATA_REQUEST = "SAVE_PAYMENT_DATA_REQUEST";
export const SAVE_PAYMENT_DATA_SUCCESS = "SAVE_PAYMENT_DATA_SUCCESS";
export const SAVE_PAYMENT_DATA_FAIL = "SAVE_PAYMENT_DATA_FAIL";

export const CREATE_PAYOUT_REQUEST = "CREATE_PAYOUT_REQUEST";
export const CREATE_PAYOUT_SUCCESS = "CREATE_PAYOUT_SUCCESS";
export const CREATE_PAYOUT_FAIL = "CREATE_PAYOUT_FAIL";

export const ACQUIRE_FRANCHISE_REQUEST = "ACQUIRE_FRANCHISE_REQUEST";
export const ACQUIRE_FRANCHISE_SUCCESS = "ACQUIRE_FRANCHISE_SUCCESS";
export const ACQUIRE_FRANCHISE_FAIL = "ACQUIRE_FRANCHISE_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const EMAIN_OR_EMAIL_AVAILABILITY_REQUEST = "EMAIN_OR_EMAIL_AVAILABILITY_REQUEST";
export const EMAIN_OR_EMAIL_AVAILABILITY_SUCCESS = "EMAIN_OR_EMAIL_AVAILABILITY_SUCCESS";
export const EMAIN_OR_EMAIL_AVAILABILITY_FAIL = "EMAIN_OR_EMAIL_AVAILABILITY_FAIL";

export const RESENDVARITYEMAIL_REQUEST = "RESENDVARITYEMAIL_REQUEST";
export const RESENDVARITYEMAIL_SUCCESS = "RESENDVARITYEMAIL_SUCCESS";
export const RESENDVARITYEMAIL_FAIL = "RESENDVARITYEMAIL_FAIL";

export const FORGOTPASSWORDEMAIL_REQUEST = "FORGOTPASSWORDEMAIL_REQUEST";
export const FORGOTPASSWORDEMAIL_SUCCESS = "FORGOTPASSWORDEMAIL_SUCCESS";
export const FORGOTPASSWORDEMAIL_FAIL = "FORGOTPASSWORDEMAIL_FAIL";

export const RESERTPASSWORD_REQUEST = "RESERTPASSWORD_REQUEST";
export const RESERTPASSWORD_SUCCESS = "RESERTPASSWORD_SUCCESS";
export const RESERTPASSWORD_FAIL = "RESERTPASSWORD_FAIL";

export const LOGOUT = "LOGOUT";

export const METAMASKLOGIN_REQUEST = "METAMASKLOGIN_REQUEST";
export const METAMASKLOGIN_SUCCESS = "METAMASKLOGIN_SUCCESS";
export const METAMASKLOGIN_FAIL = "METAMASKLOGIN_FAIL";

export const CONNECTMETAMASK_REQUEST = "CONNECTMETAMASK_REQUEST";
export const CONNECTMETAMASK_SUCCESS = "CONNECTMETAMASK_SUCCESS";
export const CONNECTMETAMASK_FAIL = "CONNECTMETAMASK_FAIL";

export const CREATEPAYPALODER_REQUEST = "CREATEPAYPALODER_REQUEST";
export const CREATEPAYPALODER_SUCCESS = "CREATEPAYPALODER_SUCCESS";
export const CREATEPAYPALODER_FAIL = "CREATEPAYPALODER_FAIL";

export const CLEAR_TRANSACTION_STATUS = "CLEAR_TRANSACTION_STATUS";

export const USER_MAIL_REQUEST = "USER_MAIL_REQUEST";
export const USER_MAIL_SUCCESS = "USER_MAIL_SUCCESS";
export const USER_MAIL_FAIL = "USER_MAIL_FAIL";
export const CLEAN_INVITE_EMAIL = "CLEAN_INVITE_EMAIL";

export const SENDCONTACTUSEMAIL_REQUEST = "SENDCONTACTUSEMAIL_REQUEST";
export const SENDCONTACTUSEMAIL_SUCCESS = "SENDCONTACTUSEMAIL_SUCCESS";
export const SENDCONTACTUSEMAIL_FAIL = "SENDCONTACTUSEMAIL_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const GET_USER_TRANSACTIONS_REQUEST = "GET_USER_TRANSACTIONS_REQUEST";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAIL = "GET_USER_TRANSACTIONS_FAIL";

export const CLEAN_FORGOT_ERROR = "CLEAN_FORGOT_ERROR";
export const CLEAN_SUCCESS = "CLEAN_SUCCESS";

export const GET_FEE_RATES_REQUEST = "GET_FEE_RATES_REQUEST";
export const GET_FEE_RATES_SUCCESS = "GET_FEE_RATES_SUCCESS";
export const GET_FEE_RATES_FAIL = "GET_FEE_RATES_FAIL";

export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_PROMO_BALANCE = "UPDATE_PROMO_BALANCE";

export const CHECK_IS_PROMO_USED_REQUEST = "CHECK_IS_PROMO_USED";
export const CHECK_IS_PROMO_USED_SUCCESS = "CHECK_IS_PROMO_USED_SUCCESS";
export const CHECK_IS_PROMO_USED_FAIL = "CHECK_IS_PROMO_USED_FAIL";

export const GET_MIN_WITHDRAWAL_AMOUNT_REQUEST =
  "GET_MIN_WITHDRAWAL_AMOUNT_REQUEST";
export const GET_MIN_WITHDRAWAL_AMOUNT_SUCCESS =
  "GET_MIN_WITHDRAWAL_AMOUNT_SUCCESS";
export const GET_MIN_WITHDRAWAL_AMOUNT_FAIL = "GET_MIN_WITHDRAWAL_AMOUNT_FAIL";

export const GET_INVITE_PROMO_AMOUNTS_REQUEST =
  "GET_INVITE_PROMO_AMOUNTS_REQUEST";
export const GET_INVITE_PROMO_AMOUNTS_SUCCESS =
  "GET_INVITE_PROMO_AMOUNTS_SUCCESS";
export const GET_INVITE_PROMO_AMOUNTS_FAIL = "GET_INVITE_PROMO_AMOUNTS_FAIL";
export const SET_INVITE_CREDITS = "SET_INVITE_CREDITS";

export const UPDATE_USER_TUTORIAL_DISABLED_REQUEST =
  "UPDATE_USER_TUTORIAL_DISABLED_REQUEST";
export const UPDATE_USER_TUTORIAL_DISABLED_SUCCESS =
  "UPDATE_USER_TUTORIAL_DISABLED_SUCCESS";
export const UPDATE_USER_TUTORIAL_DISABLED_FAIL = "UPDATE_USER_TUTORIAL_DISABLED_FAIL";

export const GET_HELMETS_REQUEST = "GET_HELMETS_REQUEST";
export const GET_HELMETS_SUCCESS = "GET_HELMETS_SUCCESS";
export const GET_HELMETS_FAIL = "GET_HELMETS_FAIL";

export const SET_FRONT_IMAGE = "SET_FRONT_IMAGE";
export const SET_BACK_IMAGE = "SET_BACK_IMAGE";
