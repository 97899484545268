import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FreeAgents } from '../types';
import { addLoader, removeLoader } from 'redux/actions/GameEngineAction';
import { get } from 'redux/services/Api';

export const useTeamPlayersWithSomeStats = (utid?: number) => {
  const dispatch = useDispatch();
  const [players, setPlayers] = useState<FreeAgents['freeAgents']>([]);

  useEffect(() => {
    if (!utid) return;

    (async () => {
      try {
        setPlayers([]);
        dispatch(addLoader());

        const response = await get<FreeAgents['freeAgents']>(`/engine/team/${utid}/playersWithSomeStats`);
        if (response?.length) {
          setPlayers(response);
        }
      } finally {
        dispatch(removeLoader());
      }
    })();
  }, [utid]);

  return players;
};
