import { Modal } from "antd";
import { InviteFriendsModalContent } from "components/InviteFriends/InviteFriendsModalContent";

export function InviteFriendPopup({
  isModalOpen,
  handleCloseModal,
  gameId,
}: {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  receiverCredits?: number;
  senderCredits?: number;
  gameId: number;
}) {

  return (
    <Modal
      open={isModalOpen}
      className="verification PreviewModal"
      footer={[]}
      onCancel={handleCloseModal}
    >
      <InviteFriendsModalContent
        title="Share your game with friends!"
        gameId={gameId}
      />
    </Modal>
  );
}