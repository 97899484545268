import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addLoader, removeLoader } from 'redux/actions/GameEngineAction';
import { get } from 'redux/services/Api';

import { useServerSidePagination } from 'helper/useServerSidePagination';

import { FreeAgents, OnTradeBlock } from '../types';

export const usePlayersToBuy = (mode: 'freeAgents' | 'onTradeBlock') => {
  const { page, setPage } = useServerSidePagination();
  const dispatch = useDispatch();
  const [players, setPlayers] = useState<FreeAgents | OnTradeBlock | null>(null);
  const [pos, setPos] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(5);

  const getPlayers = async () => {
    try {
      dispatch(addLoader());
      const res = await get<(FreeAgents | OnTradeBlock) | null>(`/engine/players/${mode}?page=${page}&limit=${limit}${pos ? `&pos=${pos}` : ''}`);
      setPlayers(res);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(removeLoader());
    }
  };

  const handlePosChange = (value: string | null) => {
    setPos(value);
    setPage(1);
  };


  useEffect(() => {
    getPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, pos]);

  return {
    page,
    setPage,
    setPos: handlePosChange,
    players,
    limit,
    setLimit,
    pos,
  };
};
