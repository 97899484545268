import { PaginationPages } from 'components/HistoricalResults/PaginationPages';
import { usePlayerTradesHistory } from '../hooks/usePlayerTradesHistory';
import './TradesHistory.scss';
import history from 'routes/history';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import { SwapOutlined } from '@ant-design/icons';

export const TradesHistory: React.FC = () => {
  const { handleNextPage, handlePrevPage, page, trades, loading, setPage } = usePlayerTradesHistory();

  return (
    <>
      <div className="trade-history">
        {trades?.trades?.map((tr) => (
          <div key={tr._id} className="trade-history__trade">
            {tr.trade.map((side, i) => (
              <>
                <div key={i} className={`trade-history__trade__side${i === 1 ? ' right' : ''}`}>
                  <span className="link" onClick={() => history.push(`/profile/${side.userId}`)}>
                    {side.userName}
                  </span>
                  <span>{side?.teamName || 'UNKNOWN TEAM'}</span>
                  <RenderHelmet helmet={side.helmet} logo={side.logo} engId={side.utid} size={120} mirror={!i} />
                  <div className="trade-history__trade__side__players">
                    {side.players.map((player) => (
                      <div key={player.pid} className="trade-history__trade__side__players__player" onClick={() => history.push(`/playerdetail/${player.pid}?tab=profile`)}>
                        <img src={require(`../../../assets/images/player/${player.pos}.png`)} alt={`${player.firstName} ${player.lastName}`} />
                        <span>
                          {player.firstName} {player.lastName}
                        </span>
                        <span>{player.pos}</span>
                        <span>{player.ovr}</span>
                      </div>
                    ))}
                  </div>
                </div>
                {i === 0 && <SwapOutlined style={{ lineHeight: 64 }} />}
              </>
            ))}
          </div>
        ))}
      </div>
      <PaginationPages
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        page={page}
        nextDisabled={!trades?.hasNextPage || loading}
        onPageClick={(e: React.MouseEvent<HTMLElement, MouseEvent>, shift = 0) => {
          setPage(+(e.target as HTMLElement).innerText + shift || 1);
        }}
        loading={loading}
      />
    </>
  );
};
