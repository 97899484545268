import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLoader, removeLoader } from 'redux/actions/GameEngineAction';
import { get } from 'redux/services/Api';
import { ShortTrade } from 'types/trades';

export const useTrade = (tradeId: string) => {
  const dispatch = useDispatch();
  const [trade, setTrade] = useState<ShortTrade | null>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoader());
        const response = await get<ShortTrade>(`/engine/trades/${tradeId}`);
        setTrade(response);
      } catch (e) {
        notification.error({ message: 'Error loading trade' });
      } finally {
        dispatch(removeLoader());
      }
    })();
  }, [tradeId]);

  return trade;
};
