import { Table } from 'antd';
import { PositionSelector } from './PositionSelector';
import { Row, Col } from 'react-bootstrap';
import { TeamSelector } from 'components/Group/GroupLeagues/TeamSelector/TeamSelector';
import { FreeAgents } from './types';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';

type Props = {
  columns: ColumnsType<any>;
  players?: FreeAgents['freeAgents'];
  pagination?: false | TablePaginationConfig;
  pos?: string | null;
  setPos?: (value: string | null) => void;
};

export const PlayerSelector: React.FC<Props> = ({ players, columns, pagination = false, pos, setPos }) => (
  <div className="standingmain">
    <Row>
      <Col xl={3}>{pos !== undefined && setPos && <PositionSelector pos={pos} setPos={setPos} />}</Col>
      <Col xl={6} />
      <Col xl={3}>
        <TeamSelector />
      </Col>
    </Row>
    <Table size="small" columns={columns} dataSource={players} pagination={pagination} rowKey={(i) => i.pid}/>
  </div>
);
