import { WALLET_ADDR } from "config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createOrder } from "redux/actions/AuthAction";
import { useMetamask } from "use-metamask";
import Web3 from "web3";
import $ from "jquery";

export const useMetamaskPayment = (price: number) => {
  const dispatch = useDispatch();
  const [metamaskLoader, setMetamaskLoader] = useState(false);
  const { metaState, connect } = useMetamask();

  const handelMetamaskConnect = async () => {
    try {
      if (connect !== undefined && Web3 !== undefined) {
        await connect(Web3);
      }
    } catch (error: any) {
      console.log(error);
      if (error == "Error: Metamask is not available.") {
        console.log("Install MetaMask: https://metamask.io/download.html");
      }
    }
  };

  const proceedPayment = async () => {
    const results = await $.getJSON(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,ETH"
    );
    const etherumPrice = (price / results.USD).toFixed(8);
    const etherumPriceWei = metaState.web3.utils.toWei(etherumPrice, "ether");
    setMetamaskLoader(true);
    metaState.web3.eth.sendTransaction(
      {
        to: WALLET_ADDR,
        from: metaState.account[0],
        value: etherumPriceWei,
      },
      function (err: any, transactionHash: any) {
        waitForReceipt(
          transactionHash,
          function (response: any) {},
          etherumPrice
        );
      }
    );
  };

  const waitForReceipt = (hash: string, cb: any, ethereumPrice: string) => {
    metaState.web3.eth.getTransactionReceipt(
      hash,
      function (err: any, receipt: any) {
        if (err) {
          setMetamaskLoader(false);
        }
        if (receipt === null) {
          setMetamaskLoader(true);
        }
        if (receipt !== null) {
          if (receipt?.status) {
            const amount = String(price);
            const status = "COMPLETED";
            const oderId = receipt.blockNumber;
            const transactionId = receipt.transactionHash;
            const emailAddress = "";
            const ether = ethereumPrice;
            const partnerCode = "";
            const paymentType = "METAMASK";
            const metamaskAccount = metaState.account[0];
            const city = "";
            const orderType = "BALANCE";
            dispatch(
              createOrder({
                amount,
                fee: "",
                status,
                oderId,
                transactionId,
                emailAddress,
                ether,
                partnerCode,
                paymentType,
                metamaskAccount,
                city,
                orderType,
              })
            );
          }
          if (cb) {
          }
        } else {
          window.setTimeout(function () {
            waitForReceipt(hash, cb, ethereumPrice);
          }, 1000);
        }
      }
    );
  };

  return { metamaskLoader, proceedPayment, handelMetamaskConnect };
};
