import { Profile } from "types";
import { useTrophiesWithPagination } from "helper/useTrophiesWIthPagination";
import "./Trophies.scss";
import { useHandleScreenSize } from "helper/useHandleScreenSize";
import { useServerSidePagination } from "helper/useServerSidePagination";
import { Trophies } from "./Trophies";

type Props = { profile: Profile, isOwn: boolean };

export const getPageSize = (pageWidth: number) => {
  if (pageWidth < 560) {
    return 1;
  } else if (pageWidth < 790) {
    return 2;
  } else if (pageWidth < 1000) {
    return 3;
  } else if (pageWidth < 1280) {
    return 4;
  } else {
    return 6;
  }
};

export const UserTrophies: React.FC<Props> = ({ profile, isOwn }) => {
  const { pageWidth } = useHandleScreenSize();
  const {
    page,
    handleNextPage,
    handlePrevPage,
  } = useServerSidePagination();

  const trophies = useTrophiesWithPagination(
    profile.id,
    page,
    getPageSize(pageWidth)
  );

  if (!trophies) return null;

  return (
     <Trophies
        data={trophies}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        page={page}
        lockerRoom
        isOwn={isOwn}
     />
  );
};
