import { useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getPlayerStats } from 'redux/actions/GameEngineAction';
import { CareerTab } from 'components/PlayerDetail/tabs/CareerTab';
import Logo from '../../../../../assets/images/common/gm_vertical_logo.png';
import { Stack } from 'react-bootstrap';
import { RosterPlayer, UserTeamsList } from 'types';
import { PlayerProfileMain } from '../../PlayerProfile/PlayerProfileMain';
import { Card, Typography } from 'antd';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import { setBackImage } from 'redux/actions/AppAction';


type Props = {
  roster: RosterPlayer | null;
  team: UserTeamsList[0] | null;
  rosterDescription: string;
  setRosterDescription: (value: string) => void;
};

const Biography: React.FC<Props> = ({
  roster,
  team,
  rosterDescription,
  setRosterDescription
}) => {
  const dispatch = useDispatch();

  const hiddenContainerRef = useRef<HTMLDivElement>(null);
  const playerData = useSelector((state: RootState) => state.gameEngine.PlayerStats);

  const captureImage = async () => {
    if (hiddenContainerRef.current && team) {
      try {
        const canvas = await html2canvas(hiddenContainerRef.current);
        canvas.toBlob((blob) => {
          if (blob) {
            dispatch(setBackImage(blob));
          }
        }, 'image/png');
      } catch (error) {
        console.error("Error capturing back image:", error);
      }
    }
  };

  useEffect(() => {
    captureImage();
  }, [team, roster, rosterDescription, hiddenContainerRef?.current, setRosterDescription]);

  useEffect(() => {
    if (roster?.pid) {
      dispatch(getPlayerStats(roster.pid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roster?.pid]);

  return (
    <Stack className="p-3" >
      <PlayerProfileMain
        roster={roster}
        rosterDescription={rosterDescription}
        setRosterDescription={setRosterDescription}
      />
      <Card
        style={{
          borderRadius: "20px",
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
        }}
        ref={hiddenContainerRef}
      >
        <Stack direction="horizontal" gap={2} className=" justify-content-between align-items-center">
          <img src={Logo} alt="logo" width={85} height={70} />
          <Typography.Title level={2} style={{ color: '#384c6c' }}>{team?.teamName}</Typography.Title>
          {team ? <RenderHelmet {...team} size={100} /> : null}
        </Stack>
        <Typography.Text>{rosterDescription}</Typography.Text>
        <CareerTab
          careerStats={playerData?.careerStats || {}}
          recentGames={playerData?.recentGames ?? []}
          awards={playerData?.award ?? []}
          statisticalAchievements={playerData?.playerStats?.statisticalAchievements || {}}
          className="text-center"
        />
      </Card>
    </Stack>
  );
};

export default Biography;
