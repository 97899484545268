import { alignAllColumnsToCenter } from '../../DepthChartTab/helper';
import { wrapAllAntdTable } from '../../DepthChartTab/ValueWrapper';

export const activeRosterColumns = wrapAllAntdTable(
  alignAllColumnsToCenter([
    {
      title: 'NAME',
      dataIndex: 'activeRoster',
      fixed: 'left',
    },
    {
      title: 'NO',
      dataIndex: 'no',
      sorter: (a: any, b: any) => +a.no - +b.no,
      render: (text: any) => Number(text).toFixed(0),
    },
    {
      title: 'POS',
      dataIndex: 'pos',
      sorter: (a: any, b: any) => a.pos.localeCompare(b.pos),
    },
    {
      title: 'HT',
      dataIndex: 'height',
      sorter: (a: any, b: any) => a.height.localeCompare(b.height),
    },
    {
      title: 'WT',
      dataIndex: 'weight',
      sorter: (a: any, b: any) => +a.weight - +b.weight,
      render: (text: any) => Math.floor(text),
    },
    {
      title: 'GAMES',
      dataIndex: 'games',
      sorter: (a: any, b: any) => +a.games - +b.games,
      render: (text: any) => Number(text).toFixed(0),
    },
    {
      title: 'RATING HIGH',
      dataIndex: 'ratinghigh',
      sorter: (a: any, b: any) => +a.ratinghigh - +b.ratinghigh,
      render: (text: any) => Math.floor(text),
    },
    {
      title: 'RATING',
      dataIndex: 'rating',
      sorter: (a: any, b: any) => a.rating.props.rating - b.rating.props.rating,
      render: (text: any) => Math.floor(text),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      sorter: (a: any, b: any) => a.status - b.status,
    },
    {
      title: '',
      dataIndex: 'buttons',
    },
  ]),
  true,
);
