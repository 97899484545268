import { notification } from 'antd';
import { post } from 'redux/services/Api';
import history from 'routes/history';

export const handleAcquireFreeAgent = async (utid: number, pid: number, id: number) => {
  try {
    const res = await post<{ success: boolean; message: string }>(`/engine/players/${pid}/${utid}/acquireFreeAgent`);
    if (!res.success) {
      throw new Error(res.message);
    }
    notification.success({ message: 'Player acquired successfully' });
    history.push(`franchiseoverview/${id}?tab=roster`);
  } catch (e: any) {
    notification.error({ message: e?.message || 'Failed to acquire free agent' });
  }
};
