import { Col, Row } from 'react-bootstrap';
import '../TabContentStyle.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { cleanCreatePayout, createPayout } from '../../../../redux/actions/AppAction';
import BlueButton from '../../../Common/Button/BlueButton/BlueButton';
import { updateData } from '../../../../redux/actions/AuthAction';
import { useMinWithdrawalAmount } from 'helper/useMinWithdrawalAmount';
import { MVPPrice } from 'components/Common/MVPPrice/MVPPrice';

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

const WithdrawFunds = () => {
  const dispatch = useDispatch();
  const resData = useSelector((state: RootState) => state.app.resData);
  const [amount, setAmount] = useState<number>();
  const [email, setEmail] = useState<string>('');
  const [inputAmountError, setInputAmountError] = useState<boolean>(false);
  const [inputEmailError, setInputEmailError] = useState<boolean>(false);
  const createPayoutError = useSelector((state: RootState) => state.app.createPayoutError);
  const createPayoutSuccess = useSelector((state: RootState) => state.app.createPayoutSuccess);
  const minWithdrawalAmount = useMinWithdrawalAmount();

  const handleCreatePayout = () => {
    if (minWithdrawalAmount === undefined || minWithdrawalAmount === null) {
      return;
    }
    if (!amount || amount < 0 || (minWithdrawalAmount && amount < minWithdrawalAmount)) {
      setInputAmountError(true);
      return;
    } else {
      setInputAmountError(false);
    }
    if (!email || !validateEmail(email)) {
      setInputEmailError(true);
      return;
    } else {
      setInputEmailError(false);
    }
    if (!resData?.id) {
      return;
    }
    dispatch(
      createPayout({
        amount: amount,
        recipientEmail: email,
        userId: resData?.id,
      }),
    );
  };

  useEffect(() => {
    if (createPayoutError) {
      alert(createPayoutError);
    }
    if (createPayoutSuccess) {
      alert('Payout created successfully, your funds will be on your account shortly, otherwise it will be returned back to platform balance');
      dispatch(updateData());
      dispatch(cleanCreatePayout());
    }
  }, [createPayoutError, createPayoutSuccess]);

  useEffect(() => {
    dispatch(cleanCreatePayout());
  }, []);

  const handleAmountChange = (input: any) => {
    if (!input || input < 0) {
      setInputAmountError(true);
    } else {
      setInputAmountError(false);
    }
    setAmount(input);
  };

  const handleEmailChange = (input: any) => {
    if (!input || !validateEmail(input)) {
      setInputEmailError(true);
    } else {
      setInputEmailError(false);
    }
    setEmail(input);
  };

  return (
    <div className="notificationmain">
      <h5 className="settinghead">Settings</h5>
      <Row>
        <Col xl={5}>
          <div className="settingwalletbalance">
            <h5>GM DYNASTY WALLET BALANCE:</h5>
            <p>Withdrawable $ {resData?.walletBalance || 0}</p>
            <p>
              MVP Crowns : <MVPPrice amt={resData?.promoBalance} />{' '}
            </p>
          </div>
          <br />
          <h6 className="withdrawalamount">WITHDRAWAL AMOUNT</h6>
          <Input
            className="withdrawalinput"
            placeholder="50"
            value={amount}
            onChange={(e) => handleAmountChange(Number(e.target.value))}
            type="number"
            prefix="$"
            status={inputAmountError ? 'error' : ''}
          />
          {minWithdrawalAmount && <p>Minimal withdrawal amount is $ {minWithdrawalAmount}</p>}
          <h6 className="withdrawalamount">PAYPAL EMAIL</h6>
          <Input
            className="withdrawalinput"
            placeholder="email@email.com"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            type="email"
            status={inputEmailError ? 'error' : ''}
          />
          <div className="paymentbuttons">
            <BlueButton text="WITHDRAW" onClick={() => handleCreatePayout()} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WithdrawFunds;
