import { ColumnsType } from 'antd/lib/table';
import { FreeAgents, OnTradeBlock } from '../types';
import CircleInfoIcon from 'components/Common/Icon/CircleIcon/CircleInfoIcon';
import history from 'routes/history';
import CircleDownloadIcon from 'components/Common/Icon/CircleIcon/CircleDownloadIcon';
import CircleSwapIcon from 'components/Common/Icon/CircleIcon/CircleSwapIcon';
import { Link } from 'react-router-dom';
import { notification } from 'antd';

const columns: ColumnsType<FreeAgents['freeAgents'][0]> = [
  {
    title: '',
    render: (r: FreeAgents['freeAgents'][0]) => (
      <Link to={`/playerdetail/${r.pid}`} className="a_color">
        {r.firstName && r.lastName ? `${r.firstName} ${r.lastName}` : '---'}
      </Link>
    ),
  },
  {
    title: 'HT',
    dataIndex: 'heightText',
  },
  {
    title: 'WT',
    dataIndex: 'weight',
  },
  {
    title: 'SPD',
    dataIndex: 'spd',
  },
  {
    title: 'STR',
    dataIndex: 'stre',
  },
  {
    title: 'AGL',
    dataIndex: 'hgt',
  },
  {
    title: 'EXP',
    dataIndex: 'endu',
  },
  {
    title: 'OVR',
    dataIndex: 'ovr',
  },
  {
    title: 'POS',
    dataIndex: 'pos',
  },
];

export const getFreeAgentsColumns = (
  setAgent: React.Dispatch<React.SetStateAction<FreeAgents['freeAgents'][number] | null>>,
): ColumnsType<FreeAgents['freeAgents'][number]> => [
  ...columns,
  {
    title: '',
    render: (r: FreeAgents['freeAgents'][0]) => (
      <div className="RosterBtn">
        <CircleInfoIcon onClick={() => history.push(`/playerdetail/${r.pid}?tab=profile`)} />
        <CircleDownloadIcon onClick={() => setAgent(r)} />
      </div>
    ),
  },
];

export const getOnTradeBlockColumns = (setPlayerToTrade?: (player: OnTradeBlock['playersOnTrade'][number]) => void): ColumnsType<OnTradeBlock['playersOnTrade'][0]> => [
  ...columns,
  {
    title: '',
    render: (r: FreeAgents['freeAgents'][0]) => (
      <div className="RosterBtn">
        <CircleInfoIcon onClick={() => history.push(`/playerdetail/${r.pid}?tab=profile`)} />
        <CircleSwapIcon onClick={() => setPlayerToTrade && setPlayerToTrade(r)} />
      </div>
    ),
  },
];

export const getTradeAwayPlayers = (players: number[], setPlayers: React.Dispatch<React.SetStateAction<number[]>>): ColumnsType<OnTradeBlock['playersOnTrade'][0]> => [
  ...columns,
  {
    title: '',
    render: (r: FreeAgents['freeAgents'][0]) => (
      <div className="RosterBtn">
        <CircleInfoIcon onClick={() => history.push(`/playerdetail/${r.pid}?tab=profile`)} />
        <CircleSwapIcon
          selected={players.includes(r.pid)}
          onClick={() => {
            setPlayers((p) => {
              if (p.includes(r.pid)) {
                return p.filter((pid) => pid !== r.pid);
              }

              if (p.length >= 3) {
                notification.error({ message: 'You can only select up to 3 players' });
                return [...p];
              }

              return p.concat(r.pid);
            });
          }}
        />
      </div>
    ),
  },
];
