import { notification } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { post } from 'redux/services/Api';
import { RootState } from 'redux/store';
import history from 'routes/history';
import { TradeToCreate } from 'types/trades';

export const useCreateTrade = () => {
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateTrade = async (trade: TradeToCreate) => {
    try {
      setIsLoading(true);
      const res = await post<{ success: boolean; message: string }>('/engine/players/createTrade', trade);
      if (res.success) {
        notification.success({ message: 'Trade has been created' });
        history.push(`/profile/${userId}`);
      } else {
        throw new Error(res.message);
      }
    } catch (e: any) {
      notification.error({ message: e?.message || 'Failed to create trade' });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreateTrade,
    isLoading,
  };
};
