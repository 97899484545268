import CircleIcon from './CircleIcon';
import CircleDownloadSvg from '../../../../assets/images/common/download.svg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
}

const CircleDownloadIcon: React.FC<Props> = (props) => <CircleIcon {...props} icon={CircleDownloadSvg} className="circlePink notRotated" />;

export default CircleDownloadIcon;
