import { Row, Col } from "react-bootstrap";
import { Table, Modal } from "antd";
import CircleCutIcon from "components/Common/Icon/CircleIcon/CircleCutIcon";
import CircleChevronIcon from "components/Common/Icon/CircleIcon/CircleChevronIcon";
import { Link } from "react-router-dom";
import { useState } from "react";
import { frontOfficeColumns } from "./constants";

type Props = {
  dataList: any;
  memberType: "HEAD COACH" | "ASSISTANT COACH" | "GENERAL MANAGER" | "ASSISTANT GM" | "AVAILABLE FOR HIRE";
};

export const FrontOfficeMember: React.FC<Props> = ({ dataList, memberType }) => {
  const frontOfficeMemberData = dataList?.map((data: any, index: number) => ({
    key: `${index}`,
    franchiseStaff: (
      <Link to={`/playerdetail/${data.pid}`} className="a_color">
        {`${data?.firstName} ${data?.lastName}`}
      </Link>
    ),
    pos: data?.ratings?.[0]?.pos ? data?.ratings?.[0]?.pos : "--",
    exp: data?.ratings?.[0]?.exp ? data?.ratings?.[0]?.exp : "--",
    def: data?.ratings?.[0]?.dst ? data?.ratings?.[0]?.dst : "--",
    off: data?.ratings?.[0]?.ost ? data?.ratings?.[0]?.ost : "--",
    spc: data?.ratings?.[0]?.sst ? data?.ratings?.[0]?.sst : "--",
    rating: data?.ratings?.[0]?.ovr ? data?.ratings?.[0]?.ovr : "--",
    status: "OPEN",
    buttons: (
      <div className="overviewBtn frontOfficeBtn">
        <CircleCutIcon onClick={() => setVisible6(true)} />
        <CircleChevronIcon onClick={() => setVisible6(true)} />
      </div>
    ),
  }));

  // PROMOTE MODAL
  const [visible6, setVisible6] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const showModal6 = () => {
    setIsModalVisible6(true);
  };
  const handleOk6 = () => {
    setIsModalVisible6(false);
  };
  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };
  return (
    <>
      <div className="standingmain mt-4">
        <Row className="mb-2">
          <Col md={6} sm={6} className="tableTitleRowStart">
            <h6>{memberType}</h6>
          </Col>
          {memberType !== "AVAILABLE FOR HIRE" && (
            <Col md={6} sm={6} className="tableTitleRowEnd frontofficesub">
              <h6>
                {dataList?.length} OF {dataList?.length} {memberType} FILLED
              </h6>
            </Col>
          )}
        </Row>
        <Table
          tableLayout="fixed"
          columns={frontOfficeColumns}
          pagination={false}
          dataSource={frontOfficeMemberData}
          size="small"
        />
      </div>

    </>
  );
};
