import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../TabContentStyle.scss';
import metamaskconnect from '../../../../assets/images/wallet/metaMaskConnected.png';
import BlueButton from '../../../Common/Button/BlueButton/BlueButton';
import { Modal } from 'antd';
import ConnectNewWalletModal from './ConnectNewWalletModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { metaMaskWalletId } from '../../../../helper/helper';
import { useMetamask } from 'use-metamask';
import Web3 from 'web3';
import { connectMetaMaskRequest } from '../../../../redux/actions/AuthAction';
import { MVPPrice } from 'components/Common/MVPPrice/MVPPrice';

interface Props {
  openAddNewFundsTab: () => void;
  openWithdrawFundsTab: () => void;
}

const SettingWallets = ({ openAddNewFundsTab, openWithdrawFundsTab }: Props) => {
  const { connect, metaState } = useMetamask();
  const dispatch = useDispatch();

  const metaMaskError = useSelector((state: RootState) => state.app.metaMaskError);
  const resData = useSelector((state: RootState) => state.app.resData);

  const [userBalanceETH, setUserBalanceETH] = useState('0.00');
  const [callMetamask, setCallMetamask] = useState<boolean>(false);
  const [shortMetaMaskId, setShortMetaMaskId] = useState<string>('Not connected');
  const [msg, setMsg] = useState<string>('');

  useEffect(() => {
    if (metaMaskError) {
      setMsg(metaMaskError);
    }
  }, [metaMaskError]);

  useEffect(() => {
    if (resData?.metaMaskID) {
      setShortMetaMaskId(metaMaskWalletId(resData?.metaMaskID));
    }
  }, [resData?.metaMaskID]);

  // CONNECT NEW WALLET
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (callMetamask && metaState.account?.length) {
      dispatch(connectMetaMaskRequest(metaState.account[0]));
      setCallMetamask(false);
      handleCancel();
    }
  }, [metaState.account, callMetamask, resData?.metaMaskID, dispatch]);

  useEffect(() => {
    (async () => {
      if (metaState.isAvailable && !metaState.isConnected) {
        try {
          if (connect !== undefined && Web3 !== undefined) {
            await connect(Web3);
          }
          return handleCancel();
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [metaState.isAvailable, connect, metaState.isConnected]);

  const connectMetamask = async () => {
    setCallMetamask(true);
  };

  useEffect(() => {
    const { web3 } = metaState;
    (async () => {
      if (resData?.metaMaskID) {
        let _balance: any;
        if (web3?.eth) {
          _balance = await metaState.web3?.eth?.getBalance(resData?.metaMaskID);
        } else {
          _balance = await metaState.web3?.getBalance(resData?.metaMaskID);
        }
        const bal: any = (_balance / 10 ** 18).toFixed(3);
        setUserBalanceETH(bal || '0.000');
      } else {
        setUserBalanceETH('0.000');
      }
    })();
  }, [resData, metaState]);

  const MMButtonText = resData?.metaMaskID ? 'CHANGE WALLET' : 'CONNECT WALLET';

  const isSame = resData?.metaMaskID === metaState.account?.[0];

  return (
    <>
      <div className="notificationmain">
        <Row>
          <Col xl={5}>
            <div className="walletconnectedmain settingwalletconnect">
              <h5>CONNECTED WALLET</h5>
              <img src={metamaskconnect} alt="metamask" className="mb-2" />
              <ul>
                <li className="wallethead">WALLET ID :</li>
                <li>{shortMetaMaskId}</li>
              </ul>
              <ul>
                <li className="wallethead">ETH BALANCE :</li>
                <li>{userBalanceETH || 0}</li>
              </ul>

              {msg && <p className="error mb-3">{msg}</p>}
              {!metaState?.isConnected && <p className="error mb-3">UNABLE TO CONNECT TO METAMASK</p>}
              {isSame && <p className="error mb-3 mt-3">THIS WALLET IS ALREADY CONNECTED</p>}

              <BlueButton onClick={showModal} text={MMButtonText} disabled={!metaState?.isConnected || isSame} />
            </div>
            <div className="settingwalletbalance">
              <h5>GM DYNASTY WALLET BALANCE:</h5>
              <p>Withdrawable: $ {resData?.walletBalance || 0}</p>
              <p>
                MVP Crowns:{" "}
                <MVPPrice amt={resData?.promoBalance || 0} />
              </p>
            </div>
            <div className="withdrawwallet">
              <BlueButton text="WITHDRAW FUNDS" onClick={openWithdrawFundsTab} />
              <BlueButton text="ADD FUNDS" onClick={openAddNewFundsTab} />
            </div>
          </Col>
        </Row>
      </div>

      <Modal title="" className="connectnewwallet" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered>
        <ConnectNewWalletModal onClick={connectMetamask} mode={resData?.metaMaskID ? 'change' : 'connect'} />
      </Modal>
    </>
  );
};

export default SettingWallets;
