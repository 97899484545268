import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { FacebookShare } from "./FacebookShare";
import { post } from "redux/services/Api";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getGameSeasonScoresByGid } from "redux/actions/GameEngineAction";
import { SeasonScoresWithHelmets } from "screens/Season/Tabs/Scores/hooks/useScoresForWeek";
import finalScorePostImage from "../../assets/images/finalScore/final-score-post.jpeg";
import { Stack } from "react-bootstrap";
import { Spin } from "antd";
import { User } from "types/user";

type Props = {
  title?: string;
  emails?: string[];
  handleEmailsChange?: (data: string[]) => void;
  message?: string;
  handleMessageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  errors?: string[];
  isLoading?: boolean;
  gameId?: number;
};

export const InviteFriendsModalContent: React.FC<Props> = ({
  title = "Get your Friends in the game",
  gameId,
}) => {
  const dispatch = useDispatch();

  const gameSeasonScoresByGid = useSelector(
    (state: RootState) => state.gameEngine.gameSeasonScoresByGid
  );
  const user = useSelector((state: RootState) => state.app.resData);

  const [image, setImage] = useState<string | null>(null);
  const [finalScorePost, setFinalScorePost] = useState<string>("");

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  const extractTeamInfo = (data: any): { name: string, pts: number, helmet: string, logo: string }[] => {
    const teams: any[] = [];

    data.helmets.forEach((helmet: any) => {
      const isWinner = helmet.engId === data.won.utid;
      const teamInfo = {
        name: helmet.teamName,
        pts: isWinner ? data.won.pts : data.lost.pts,
        helmet: helmet.helmet,
        logo: helmet.logo
      };

      teams.push(teamInfo);
    });

    return teams;
  };

  const generateImageFinalScorePost = async (data: any) => {
    const canvas = document.createElement('canvas');
    canvas.width = 960;
    canvas.height = 1700;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    if (!data.helmets[0] || !data.helmets[1]) {
      return;
    }
    const finalScorePostData = extractTeamInfo(data);
    try {
      const logo1Path = require(`../../assets${finalScorePostData[0].logo}`);
      const logo2Path = require(`../../assets${finalScorePostData[1].logo}`);
      const [backgroundImage, logo1, logo2] = await Promise.all([
        loadImage(finalScorePostImage),
        loadImage(logo1Path),
        loadImage(logo2Path)
      ]);
      // background
      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

      // logos
      ctx.drawImage(logo1, 110, 970, 250, 250);
      ctx.drawImage(logo2, 620, 970, 250, 250);

      // text
      ctx.font = '30px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.fillText(finalScorePostData[0].name, 270, 1320);
      ctx.fillText(finalScorePostData[1].name, 750, 1320);

      // score
      ctx.font = '60px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.fillText(finalScorePostData[0].pts.toString(), 230, 1400);
      ctx.fillText(finalScorePostData[1].pts.toString(), 700, 1400);

      setImage(canvas.toDataURL());
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  const handleGenerateFinalScorePost = async (data: SeasonScoresWithHelmets, userData: User) => {
    const extendData = {
      ...data,
      user_discord_id: userData?.discord ? `https://discord.com/users/${userData?.discord}` : null,
      user_twitter_id: userData?.twitter ? `https://twitter.com/${userData?.twitter}` : null,
      user_instagram_id: userData?.instagram ? `https://instagram.com/${userData?.instagram}` : null,
      user_facebook_id: userData?.facebook ? `https://facebook.com/${userData?.facebook}` : null,
    }
    try {
      const res = await post<{ message: string }>('/admin/final-score-game-announcement', extendData);
      if (res.message) {
        setFinalScorePost(res.message || "");
      }
    } catch (error) {
      console.log('Error in handleGeneratePost', error);
      return null;
    }
  };

  const handleCopyTextToClipboard = async () => {
    if (finalScorePost) {
      navigator.clipboard.writeText(finalScorePost);
    }
  };

  const handleCopyImageToClipboard = async () => {
    if (image) {
      try {
        const response = await fetch(image);
        const blob = await response.blob();
        const clipboardItem = new ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([clipboardItem]);
      } catch (error) {
        console.error('Failed to copy image to clipboard:', error);
      }
    }
  };

  const handleFinalScorePostChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFinalScorePost(e.target.value);
  };

  useEffect(() => {
    if (gameId) {
      dispatch(getGameSeasonScoresByGid(gameId));
    }
  }, [gameId]);

  useEffect(() => {
    if (gameSeasonScoresByGid?.length && user) {
      generateImageFinalScorePost(gameSeasonScoresByGid[0]);
      handleGenerateFinalScorePost(gameSeasonScoresByGid[0], user);
    }
  }, [gameSeasonScoresByGid, user]);

  const isLoading = !finalScorePost || !image;

  return (
    <div className="modal_content_container">
      <h1 className=" fs-5">{title}</h1>
      {finalScorePost && image &&
        <Stack direction="horizontal" gap={3} className="my-3">
          <img src={image || ""} alt="Final Score Post" width={250} height={400} />
          <TextArea
            value={finalScorePost}
            onChange={handleFinalScorePostChange}
            style={{
              height: '400px',
              resize: 'none',
              backgroundColor: '#f4f7f7',
              border: 'none',
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 500,
              color: '#333',
              padding: 16,
            }}
          />
        </Stack>
      }
      {isLoading &&
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      }
      <div className="btn_container gap-2 mt-3">
        <BlueButton
          type="button"
          onClick={handleCopyTextToClipboard}
          text="Copy Text"
          disabled={isLoading}
        />
        <BlueButton
          type="button"
          onClick={handleCopyImageToClipboard}
          text="Copy Image"
          disabled={isLoading}
        />
        <FacebookShare
          url={`${window?.location?.host || 'https://gmdynasty.com'}/replay/${gameId}`}
          hashtag="#GMDynasty"
          disabled={isLoading}
        >
          <BlueButton
            type="button"
            className="bg-danger w-100 h-100"
            text="Share on Facebook"
            disabled={isLoading}
          />
        </FacebookShare>
      </div>
    </div>
  );
};
