import { useMemo, useRef, useState } from 'react';
import { Container, Stack, } from 'react-bootstrap';
import { InfoCircleFilled } from '@ant-design/icons';
import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import OwnFranchiseHeader from 'components/OwnFranchise/OwnFranchiseHeader';
import { MintRosterInfo } from './modals/MintRosterInfo/MintRosterInfo';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import { RosterPlayer, UserTeamsList } from 'types';
import { Button, notification, Select, Tabs, Typography } from 'antd';
import { getUserTeams } from 'redux/actions/TeamAction';
import { addLoader, getTeamRoster, removeLoader } from 'redux/actions/GameEngineAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PlayerCreation } from './components/Tabs/PlayerCreation/PlayerCreation';
import Biography from './components/Tabs/Biography';
import MintPlayerConnected from './components/Tabs/MintPlayerConnected';
import { postPlayerImages, postPlayerNftJson } from 'helper/helper';
import { setBackImage, setFrontImage } from 'redux/actions/AppAction';

type PositionType = 'left' | 'right';

export const MintRoster: React.FC = () => {
  const dispatch = useDispatch();
  const playerCreationRef = useRef<any>(null);
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const teamsData = useSelector((state: RootState) => state.team.requestedUserTeams);
  const rosterData = useSelector((state: RootState) => state.gameEngine.teamRoster);
  const frontImage = useSelector((state: RootState) => state.app.frontImage);
  const backImage = useSelector((state: RootState) => state.app.backImage);
  console.log("backImage", backImage);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [team, setTeam] = useState<UserTeamsList[0] | null>(null);
  const [roster, setRoster] = useState<RosterPlayer | null>(null);
  const [rosterDescription, setRosterDescription] = useState<string>('');
  const [activeTab, setActiveTab] = useState("0");
  const [pathToJson, setPathToJson] = useState<string | undefined>();
  const [pathToGif, setPathToGif] = useState<string | undefined>();


  const teams = useMemo(
    () =>
      teamsData?.map((team: UserTeamsList[0]) => ({
        label: `${team.teamName} (ID:${team.engId})`,
        value: team.engId,
      })),
    [teamsData],
  );

  const rosters = useMemo(
    () =>
      rosterData?.roster?.map((roster: any) => ({
        label: `${roster.pos} ${roster.firstName} ${roster.lastName} (ID:${roster.pid})`,
        value: roster.pid,
      })),
    [rosterData],
  );

  const handleTeamFocus = () => {
    if (userId) {
      dispatch(getUserTeams(userId));
    }
  };

  const handleRosterFocus = () => {
    if (team?.engId) {
      dispatch(getTeamRoster(team.engId));
    }
  };

  const handleTeamChange = (value: number) => {
    const selectedTeam = teamsData?.find((t) => t.engId === value);
    if (selectedTeam) {
      setTeam(selectedTeam);
      setRoster(null);
    }
  };

  const handleRosterChange = (value: number) => {
    setRoster(rosterData?.roster?.find((r) => r.pid === value) || null);
    setRosterDescription('');
    setActiveTab("0");
    dispatch(setFrontImage(undefined));
    dispatch(setBackImage(undefined));
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleGenerateImage = async (backImageItem?: Blob, frontImageItem?: Blob) => {
    if (!backImageItem || !frontImageItem) {
      throw new Error("Please generate both images");
    }
    const frontImageBase64 = await blobToBase64(frontImageItem);
    const backImageBase64 = await blobToBase64(backImageItem);

    const { gifPath } = await postPlayerImages({
      playerId: roster?.pid,
      img1: frontImageBase64,
      img2: backImageBase64,
    });
    setPathToGif(gifPath);
    const { jsonFile } = await postPlayerNftJson({
      playerId: roster?.pid,
      teamId: team?.engId,
      description: rosterDescription,
      ownerId: userId,
    });
    setPathToJson(jsonFile);
    notification.success({
      message: "Image and JSON file generated successfully"
    });
  };

  const tabs = [
    {
      label: '1. Create Roster Image',
      key: "0",
      disabled: !team || !roster,
      children: <PlayerCreation
        team={team}
        roster={roster}
        ref={playerCreationRef}
      />,
    },
    {
      label: '2. Create Biography',
      key: "1",
      disabled: !team || !roster,
      children: <Biography
        roster={roster}
        team={team}
        rosterDescription={rosterDescription}
        setRosterDescription={setRosterDescription}
      />,
    },
    {
      label: '3. Mint',
      key: "2",
      disabled: !team || !roster || !rosterDescription,
      children: <MintPlayerConnected
        team={team}
        roster={roster}
        pathToGif={pathToGif}
        pathToJson={pathToJson}
        setActiveTab={setActiveTab}
      />,
    },
  ];

  const handlePrevTab = () => {
    setActiveTab(prev => {
      const prevTab = Number(prev) - 1;
      return prevTab >= 0 ? prevTab.toString() : prev;
    });
  };

  const handleNextTab = async () => {
    const nextTab = Number(activeTab) + 1;

    switch (nextTab) {
      case 1:
        dispatch(addLoader());
        await playerCreationRef.current?.submit();
        dispatch(removeLoader());
        setActiveTab("1");
        break;
      case 2:
        try {
          dispatch(addLoader());
          await handleGenerateImage(backImage, frontImage);
          setActiveTab("2");
        } catch (error) {
          notification.error({
            message: "Error generating image and JSON file. Please try again later."
          });
          setActiveTab("1");
        } finally {
          dispatch(removeLoader());
        }
        break;
      default:
        if (nextTab <= tabs.length - 1) {
          setActiveTab(nextTab.toString());
        }
        break;
    }
  };

  const OperationsSlot: Record<PositionType, React.ReactNode> = {
    left: <Button onClick={handlePrevTab} type="primary" disabled={!roster || activeTab === "0"}>Previous</Button>,
    right: <Button onClick={handleNextTab} type="primary" disabled={!roster || (activeTab === "1" && !rosterDescription) || activeTab === "2"}>Next</Button>,
  };

  return (
    <>
      <Container>
        <OwnFranchiseHeader />
        <Card>
          <CardBody>
            <Stack gap={2}>
              <Stack direction="horizontal" gap={1} className="justify-content-center align-items-center">
                {team &&
                  <div style={{ width: '80px', height: '100px', overflow: 'hidden' }}>
                    <div className="a_color franchise helmet-nft-card"> <RenderHelmet {...team} size={100} /></div>
                  </div>}
                <Typography.Title level={4}>
                  CUSTOMIZE & CREATE YOUR PLAYER <InfoCircleFilled onClick={() => setIsInfoModalVisible(true)} />
                </Typography.Title>
              </Stack>
              <Stack gap={2}>
                <Select
                  placeholder="Select Team"
                  options={teams}
                  value={team?.engId}
                  onChange={handleTeamChange}
                  onFocus={handleTeamFocus}
                />
                <Select
                  placeholder="Select Roster"
                  options={rosters}
                  value={roster?.pid}
                  onChange={handleRosterChange}
                  disabled={!team}
                  onFocus={handleRosterFocus}
                />
              </Stack>
              <Tabs
                tabBarExtraContent={OperationsSlot}
                type="card"
                centered
                size="large"
                activeKey={activeTab}
                items={tabs}
              />
            </Stack>
          </CardBody>
        </Card>
      </Container>
      <MintRosterInfo open={isInfoModalVisible} onClose={() => setIsInfoModalVisible(false)} />
    </>
  );
};
