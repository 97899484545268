import { notification } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { patch } from 'redux/services/Api';
import { RootState } from 'redux/store';
import history from 'routes/history';

export const useTradeActions = (tradeId?: string, utid?: number) => {
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const teamId = userTeams?.find((t) => t.engId === utid)?.id;

  const request = async (
    action: 'accept' | 'reject' | 'cancel' | 'counter',
    trade?: [number[], number[]], // For counter trade
  ) => {
    if (!tradeId) {
      notification.error({ message: 'Trade ID is missing' });
      return;
    }
    setLoading(true);
    try {
      const response = await patch<{ success: boolean; message: string }>(`/user/trade-action/${tradeId}`, { action, trade });
      if (response.success) {
        notification.success({ message: response.message });
        if (action === 'counter') {
          userId && history.push(`/profile/${userId}`);
        } else {
          teamId && history.push(`/franchiseoverview/${teamId}?tab=roster`);
        }
      } else {
        throw new Error(response.message);
      }
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e?.response?.data?.error || e?.response?.data || 'Something went wrong' });
    } finally {
      setLoading(false);
    }
  };

  return { loading, request };
};
