import { useSelector } from 'react-redux';
import './SelectedFormations.scss';
import Title from 'components/Common/Title/Title';
import { Col, Row } from 'react-bootstrap';
import { RootState } from 'redux/store';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';

export const SelectedFormations: React.FC = () => {
  const gameOverview = useSelector((state: any) => state.gameEngine.PregameOverview);
  const avatarsList = useSelector((state: RootState) => state?.team?.fetchedAllAvatar);

  const firstHelmetData = avatarsList?.find((data: any) => +data?.engId === gameOverview?.teams?.[0]?.utid);
  const secondHelmetData = avatarsList?.find((data: any) => +data?.engId === gameOverview?.teams?.[1]?.utid);

  const helmets = [firstHelmetData, secondHelmetData].filter(Boolean);

  if (!gameOverview || !gameOverview?.teams || !avatarsList || helmets?.length !== 2) {
    return <></>;
  }

  return (
    <div className="selected-formations-container mt-4">
      <Title text="BASE FORMATIONS SELECTED" />
      {Object.values(gameOverview.teams).map((team: any, i: number) => (
        <Row className="selected-formations-row" key={i}>
          <Col xs="3">
            <RenderHelmet {...helmets[i]} size={115} />
          </Col>
          <Col>
            <span className="selected-formations-data">
              <strong>OFFENSE:</strong> {team.offensiveGamePlan}
            </span>
          </Col>
          <Col>
            <span className="selected-formations-data">
              <strong>DEFENSE:</strong> {team.defensiveGamePlan}
            </span>
          </Col>
        </Row>
      ))}
    </div>
  );
};
