import { Row, Col } from 'react-bootstrap';
import image from '../../assets/images/homeScreen/franchiseBuild.gif';
import React from 'react';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import history from 'routes/history';

type Props = {
  noHeader?: boolean;
  shorterText?: boolean;
};

export const PurchaseTeam: React.FC<Props> = ({ noHeader = false, shorterText = false } = { noHeader: false, shorterText: false }) => (
  <div className="leagueListMain franchiselist mb-5">
    <Row>
      <Col sm={12} xl={4} lg={4}>
        <div className="leagueDetailLeft">
          <div className="leagueImage">
            <div className="imgWithRatingContainer">
              <img src={image} alt="leagueImage" className="imgFluid" style={{ cursor: 'pointer' }} />
            </div>
          </div>
          {!noHeader && (
            <div className="leagueDetail">
              <h5>ADD A TEAM!</h5>
            </div>
          )}
        </div>
      </Col>
      <Col sm={12} xl={8} lg={8}>
        <Row>
          <Col xl={12} lg={12}>
            <div className="leaguedetail_head">
              <h5>{shorterText ? 'CREATE ADDITIONAL TEAMS' : 'PARTICIPATE IN MORE LEAGUES!'}</h5>
            </div>
          </Col>
          <Col sm={12} xl={8} lg={8}>
            <div className="leagueDetailRight blue font14" style={{ textAlign: 'left' }}>
              {shorterText
                ? "ADD TO YOUR OWNER'S PORTFOLIO. TEST NEW STRATEGIES, ENGAGE IN MORE CONTESTS"
                : "CREATE ADDITIONAL TEAMS TO ADD TO YOUR OWNER'S PORTFOLIO. TEST NEW STRATEGIES, ENGAGE IN MORE CONTESTS OR SIMPLY EXPLORE YOUR CREATIVE LIMITS OF DESIGNING A NEW TEAM. ALL TEAMS ARE MINTED NFTS THAT ARE OWNED BY YOU."}
            </div>
          </Col>
          <Col xl={4} lg={4} className="create-team-button-container">
            <BlackButton text="CREATE" onClick={() => history.push('/purchasefranchise')} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
