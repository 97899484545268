import Error from '../../../../../assets/images/common/error.svg';
import { Modal } from 'antd';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';

type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

export const DemoteModal: React.FC<Props> = (props) => (
  <Modal centered className="rostererror" {...props} footer={<BlueButton onClick={props.onCancel} text="OK" className="mx-auto" />} width={450}>
    <div className="startdraftmain">
      <img src={Error} alt="erroricon" />
      <p>Player has been demoted to the taxi squad.</p>
    </div>
  </Modal>
);
