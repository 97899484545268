import Title from 'components/Common/Title/Title';
import { Row } from 'react-bootstrap';
import { Player } from './Player';
import { FreeAgents } from 'components/TradesAndFreeAgents/types';
import React from 'react';

type P = Pick<FreeAgents['freeAgents'][number], 'bornLoc' | 'firstName' | 'lastName' | 'pos' | 'pid' | 'ovr'>;

type Props = {
  playersToReceive: P[];
  playersToSend: P[];
};

export const TradeProposalModalContent: React.FC<Props> = ({ playersToReceive, playersToSend }) => (
  <>
    <Title text="RECEIVE" />
    <Row className="mb-4">
      {playersToReceive.map((player) => (
        <Player player={player} key={player.pid} />
      ))}
    </Row>
    <Title text="SEND" />
    <Row>
      {playersToSend.map((player) => (
        <Player player={player} key={player.pid} />
      ))}
    </Row>
  </>
);
