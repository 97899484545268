import { Modal } from 'antd';
import { CreateTradeProposal } from 'components/TradesAndFreeAgents/CreateTradeProposal/CreateTradeProposal';
import { FreeAgents } from 'components/TradesAndFreeAgents/types';

type Props = {
  player?: FreeAgents['freeAgents'][number] | null;
  onClose: () => void;
};

export const CreateTradeProposalModal: React.FC<Props> = ({ player, onClose }) => {
  if (!player) return null;

  return (
    <Modal open={!!player} onCancel={onClose} centered footer={null} width={"100%"}>
      <CreateTradeProposal player={player} />
    </Modal>
  );
};
