import './RosterTabStyle.scss';
import Card from '../../../Common/Card/Card';
import CardBody from '../../../Common/Card/CardBody';
import { Row, Col } from 'react-bootstrap';
import { Table, notification } from 'antd';
import Title from '../../../Common/Title/Title';
import CircleCutIcon from 'components/Common/Icon/CircleIcon/CircleCutIcon';
import CircleChevronIcon from 'components/Common/Icon/CircleIcon/CircleChevronIcon';
import { useState } from 'react';
import { DemoteModal } from './Modals/DemoteModal';
import { PromoteModal } from './Modals/PromoteModal';
import { Link } from 'react-router-dom';
import { RatingWithChange } from './RatingWithChange';
import { useScrollTop } from 'helper/useScrollTop';
import CircleSwapIcon from 'components/Common/Icon/CircleIcon/CircleSwapIcon';
import { useRoster } from './hooks/useRoster';
import { RosterPlayer } from 'types';
import { RosterTradeModal } from './Modals/RosterActionModal';
import { activeRosterColumns } from './constants';
import { post } from 'redux/services/Api';
import { useDispatch } from 'react-redux';
import { setTeamLeaguesHistoryOvr } from 'redux/actions/GameEngineAction';

type Response = {
  success: boolean;
  message: string;
  ovr?: number;
  utid?: number;
};

type Props = {
  engId?: number;
};

export type RosterPlayerWithAction = RosterPlayer & { action: 'demote' | 'promote' | 'release' | 'trade' /* | 'mint' */ };

const RosterTabMain: React.FC<Props> = ({ engId }) => {
  const dispatch = useDispatch();
  const { teamRosterData, loadData } = useRoster(engId);
  const [selectedPlayerAndAction, setSelectedPlayerAndAction] = useState<RosterPlayerWithAction | null>(null);

  useScrollTop();

  const [promoteModalOpen, setPromoteModalOpen] = useState(false);
  const [demoteModalOpen, setDemoteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePlayerAction = async (p: RosterPlayerWithAction) => {
    switch (p.action) {
      case 'trade':
        try {
          setLoading(true);
          await post(`/engine/players/${p.pid}/toggleTradeBlockAvailability`, { isOnTrade: true });
          notification.success({ message: 'Player is now available for trade' });
          loadData();
          
        } catch (e) {
          notification.error({ message: 'Failed to toggle trade block availability' });
        } finally {
          setSelectedPlayerAndAction(null);
          setLoading(false);
        }
        break;

      case 'demote':
        try {
          setLoading(true);
          const res = await post<Response>(`/engine/players/${p.pid}/demote`);
          if (res.success) {
            notification.success({ message: `Player ${p.firstName} ${p.lastName} has been demoted to the taxi squad` });
            setDemoteModalOpen(true);
            loadData();
            if (res?.ovr && res?.utid) {
              dispatch(setTeamLeaguesHistoryOvr({ ovr: res.ovr, utid: res.utid }));
            }
            setTimeout(() => setDemoteModalOpen(false), 5000);
          } else {
            notification.error({ message: res.message });
          }
        } catch (e) {
          notification.error({ message: 'Failed to demote player' });
        } finally {
          setSelectedPlayerAndAction(null);
          setLoading(false);
        }
        break;

      case 'release':
        try {
          setLoading(true);
          const res = await post<Response>(`/engine/players/${p.pid}/release`);
          if (res.success) {
            notification.success({ message: `Player ${p.firstName} ${p.lastName} has been realsed` });
            if (res?.ovr && res?.utid) {
              dispatch(setTeamLeaguesHistoryOvr({ ovr: res.ovr, utid: res.utid }));
            }
            loadData();
          } else {
            notification.error({ message: res.message });
          }
        } catch (e) {
          notification.error({ message: 'Failed to release player' });
        } finally {
          setSelectedPlayerAndAction(null);
          setLoading(false);
        }
        break;

      case 'promote':
        try {
          setLoading(true);
          const res = await post<Response>(`/engine/players/${p.pid}/promote`);
          if (res.success) {
            notification.success({ message: `Player ${p.firstName} ${p.lastName} has been promoted` });
            loadData();
            if (res?.ovr && res?.utid) {
              dispatch(setTeamLeaguesHistoryOvr({ ovr: res.ovr, utid: res.utid }));
            }
            setPromoteModalOpen(true);
            setTimeout(() => setPromoteModalOpen(false), 5000);
          } else {
            notification.error({ message: res.message });
          }
        } catch (e) {
          notification.error({ message: 'Failed to promote player' });
        } finally {
          setSelectedPlayerAndAction(null);
          setLoading(false);
        }
        break;

      default:
        notification.error({ message: 'Attempt to perform unknown action' });
        break;
    }
  };

  const rosterDataList =
    teamRosterData?.roster?.map((e, key) => ({
      key: key + 1,
      activeRoster: (
        <Link to={`/playerdetail/${e?.pid}`} className="a_color">
          {e.firstName || e.lastName ? `${e.firstName} ${e.lastName}` : '---'}
        </Link>
      ),
      no: e?.number ? format(e.number) : '---',
      pos: e?.pos ? e.pos : '---',
      height: e?.heightText ? e.heightText : '---',
      weight: e?.weight ? format(e.weight) : '---',
      games: e?.games ? format(e.games) : '---',
      ratinghigh: e?.highRating ? format(e.highRating) : '---',
      rating: e?.rating && (e?.ratingChange !== undefined || e?.ratingChange !== null) ? <RatingWithChange rating={e.rating} ratingChange={e.ratingChange} /> : '---',
      status: e?.status ? e.status : '---',
      buttons: (
        <div className="RosterBtn">
          <CircleCutIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'release' })} />
          <CircleChevronIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'demote' })} />
          <CircleSwapIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'trade' })} disabled={!!e?.isOnTrade} />
        </div>
      ),
    })) || [];

  const taxiSquadNewData =
    teamRosterData?.taxiSquad?.map((e, key) => ({
      key: key + 1,
      activeRoster: (
        <Link to={`/playerdetail/${e?.pid}`} className="a_color">
          {e.firstName || e.lastName ? `${e.firstName} ${e.lastName}` : '---'}
        </Link>
      ),
      no: e?.number ? format(e.number) : '---',
      pos: e?.pos ? e.pos : '---',
      height: e?.heightText ? e.heightText : '---',
      weight: e?.weight ? format(e.weight) : '---',
      games: e?.games ? format(e.games) : '---',
      ratinghigh: e?.highRating ? format(e.highRating) : '---',
      rating: e?.rating && (e?.ratingChange !== undefined || e?.ratingChange !== null) ? <RatingWithChange rating={e.rating} ratingChange={e.ratingChange} /> : '---',
      status: e?.status ? e.status : '---',
      buttons: (
        <div className="RosterBtn">
          <CircleCutIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'release' })} />
          <CircleChevronIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'promote' })} upsideDown />
          <CircleSwapIcon onClick={() => setSelectedPlayerAndAction({ ...e, action: 'trade' })} disabled={!!e?.isOnTrade} />
        </div>
      ),
    })) || [];

  return (
    <>
      <Row>
        <Col xl={12} className="mb-5">
          <Card>
            <CardBody>
              <div className="standingmain">
                <div className="table_head">
                  <Title text="ACTIVE ROSTER" />
                  <h6>{rosterDataList?.length || 0} OF 53 ROSTER SPOTS FILLED</h6>
                </div>
                <Table columns={activeRosterColumns} pagination={false} dataSource={rosterDataList} size="small" />
              </div>
              <div className="standingmain">
                <div className="table_head">
                  <Title text="TAXI SQUAD" />
                  <h6>{taxiSquadNewData?.length || 0} OF 10 TAXI SPOTS FILLED</h6>
                </div>
                <Table columns={activeRosterColumns} pagination={false} dataSource={taxiSquadNewData} size="small" />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <DemoteModal open={demoteModalOpen} onCancel={() => setDemoteModalOpen(false)} onOk={() => setDemoteModalOpen(false)} />

      <PromoteModal open={promoteModalOpen} onCancel={() => setPromoteModalOpen(false)} onOk={() => setPromoteModalOpen(false)} />

      <RosterTradeModal playerWithAction={selectedPlayerAndAction} onCancel={() => setSelectedPlayerAndAction(null)} onOk={handlePlayerAction} disabled={loading} />
    </>
  );
};

export default RosterTabMain;

function format(unknown: number | string) {
  if (typeof unknown === 'number') {
    return Math.floor(unknown);
  }
  if (typeof unknown === 'string') {
    return parseInt(unknown);
  }
}
