import { useEffect, useState } from "react";
import { Input, Select, Button, Spin, Form, FormInstance } from "antd";
import { useProcessPromt } from "./useProcessPromt";
import { PlayerProfileOptions } from "./PlayerProfileConstants";
import { Stack } from "react-bootstrap";
import { RosterPlayer } from "types";

export type ProfileRequestProps = {
  firstName: string;
  lastName: string;
  race: string;
  background: string;
  location: string;
  familySocialStatus: string;
  student: string;
  trouble: string;
  story: string;
  position: string;
  family: string;
  footballBackground: string;
  education: string;
  accomplishments: string;
  inspiration: string;
  relationshipStatus: string;
  backgroundMood: string;
};

type Props = {
  roster: RosterPlayer | null;
  rosterDescription: string;
  setRosterDescription: (value: string) => void;
}

interface SubmitButtonProps {
  form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  );
};

export const PlayerProfileMain: React.FC<Props> = ({ roster, rosterDescription, setRosterDescription }) => {
  const [form] = Form.useForm();

  const { processPromt, loader: submitLoader } = useProcessPromt();

  const handleSubmit = async () => {
    setRosterDescription('');
    const response = await processPromt(form.getFieldsValue());
    if (response) {
      setRosterDescription(response);
    }
  };

  const getRandomOption = (constantName: keyof typeof PlayerProfileOptions.PLAYER_PROFILE_CONSTANTS) => {
    const option = PlayerProfileOptions.getRandomOptionByConstantName(constantName);
    return typeof option === 'string' ? option : option.value;
  };

  const handleRandomize = () => {
    const updatedProfile: ProfileRequestProps = {
      firstName: roster?.firstName || '',
      lastName: roster?.lastName || '',
      position: roster?.pos || '',
      race: getRandomOption('RACE'),
      background: getRandomOption('BACKGROUND'),
      location: getRandomOption('LOCATION'),
      familySocialStatus: getRandomOption('FAMILY_SOCIAL_STATUS'),
      student: getRandomOption('STUDENT'),
      trouble: getRandomOption('TROUBLE'),
      story: getRandomOption('STORY'),
      family: getRandomOption('FAMILY'),
      footballBackground: getRandomOption('FOOTBALL_BACKGROUND'),
      education: getRandomOption('EDUCATION'),
      accomplishments: getRandomOption('ACCOMPLISHMENTS'),
      inspiration: getRandomOption('INSPIRATION'),
      relationshipStatus: getRandomOption('RELATIONSHIP_STATUS'),
      backgroundMood: getRandomOption('BACKGROUND_MOOD'),
    };
    form.setFieldsValue(updatedProfile);
  };

  useEffect(() => {
    if (roster) {
      form.resetFields();
    }
  }, [roster, form]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          firstName: roster?.firstName || undefined,
          lastName: roster?.lastName || undefined,
          position: roster?.pos || undefined,
          race: undefined,
          background: undefined,
          location: undefined,
          familySocialStatus: undefined,
          student: undefined,
          trouble: undefined,
          story: undefined,
          family: undefined,
          footballBackground: undefined,
          education: undefined,
          accomplishments: undefined,
          inspiration: undefined,
          relationshipStatus: undefined,
          backgroundMood: undefined,
        }}
      >
        <Form.Item name="firstName" label="First Name:">
          <Input
            disabled={true}
            style={{ height: '30px' }}
          />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name:">
          <Input
            disabled={true}
            style={{ height: '30px' }}
          />
        </Form.Item>
        <Form.Item name="position" label="Position:">
          <Select disabled={true}>
          </Select>
        </Form.Item>
        <Form.Item name="race" label="Race:" rules={[{ required: true }]}>
          <Select placeholder="Select Race">
            {PlayerProfileOptions.getOptionsByConstantName('RACE')}
          </Select>
        </Form.Item>
        <Form.Item name="background" label="Background:" rules={[{ required: true }]}>
          <Select placeholder="Select Background">
            {PlayerProfileOptions.getOptionsByConstantName('BACKGROUND')}
          </Select>
        </Form.Item>
        <Form.Item name="location" label="Location:" rules={[{ required: true }]}>
          <Select placeholder="Select Location">
            {PlayerProfileOptions.getOptionsByConstantName('LOCATION')}
          </Select>
        </Form.Item>
        <Form.Item name="familySocialStatus" label="Family Social Status:" rules={[{ required: true }]}>
          <Select
            placeholder="Select Family Social Status"
          >
            {PlayerProfileOptions.getOptionsByConstantName('FAMILY_SOCIAL_STATUS')}
          </Select>
        </Form.Item>
        <Form.Item name="student" label="Student:" rules={[{ required: true }]}>
          <Select placeholder="Select Student">
            {PlayerProfileOptions.getOptionsByConstantName('STUDENT')}
          </Select>
        </Form.Item>
        <Form.Item name="trouble" label="Trouble:" rules={[{ required: true }]}>
          <Select placeholder="Select Trouble">
            {PlayerProfileOptions.getOptionsByConstantName('TROUBLE')}
          </Select>
        </Form.Item>

        <Form.Item name="story" label="Story:" rules={[{ required: true }]}>
          <Select placeholder="Select story">
            {PlayerProfileOptions.getOptionsByConstantName('STORY')}
          </Select>
        </Form.Item>

        <Form.Item name="family" label="Family:" rules={[{ required: true }]}>
          <Select placeholder="Select Family">
            {PlayerProfileOptions.getOptionsByConstantName('FAMILY')}
          </Select>
        </Form.Item>

        <Form.Item name="footballBackground" label="Football Background:" rules={[{ required: true }]}>
          <Select placeholder="Select Football Background">
            {PlayerProfileOptions.getOptionsByConstantName('FOOTBALL_BACKGROUND')}
          </Select>
        </Form.Item>

        <Form.Item name="education" label="Education:" rules={[{ required: true }]}>
          <Select placeholder="Select Education">
            {PlayerProfileOptions.getOptionsByConstantName('EDUCATION')}
          </Select>
        </Form.Item>

        <Form.Item name="accomplishments" label="Accomplishments:" rules={[{ required: true }]}>
          <Select placeholder="Select Accomplishments">
            {PlayerProfileOptions.getOptionsByConstantName('ACCOMPLISHMENTS')}
          </Select>
        </Form.Item>

        <Form.Item name="inspiration" label="Inspiration:" rules={[{ required: true }]}>
          <Select placeholder="Select Inspiration">
            {PlayerProfileOptions.getOptionsByConstantName('INSPIRATION')}
          </Select>
        </Form.Item>

        <Form.Item name="relationshipStatus" label="Relationship Status:" rules={[{ required: true }]}>
          <Select placeholder="Select Relationship Status">
            {PlayerProfileOptions.getOptionsByConstantName('RELATIONSHIP_STATUS')}
          </Select>
        </Form.Item>

        <Form.Item name="backgroundMood" label="Background Mood:" rules={[{ required: true }]}>
          <Select placeholder="Select Background Mood">
            {PlayerProfileOptions.getOptionsByConstantName('BACKGROUND_MOOD')}
          </Select>
        </Form.Item>

        <Form.Item>
          <Stack direction="horizontal" gap={2} className="mt-4 justify-content-end align-items-center">
            <Button
              type="primary"
              onClick={handleRandomize}
            >
              RANDOMIZE
            </Button>
            <SubmitButton form={form}>
              {submitLoader
                ? <Spin />
                : rosterDescription.length > 0
                  ? 'REGENERATE'
                  : 'GENERATE'}
            </SubmitButton>
          </Stack>
        </Form.Item>
      </Form>
    </>
  );
};
