import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLoader, removeLoader } from 'redux/actions/GameEngineAction';
import { get } from 'redux/services/Api';

type Res = { pid: number } & Record<string, number>;

export const usePositionalTraits = (pid: number) => {
  const dispatch = useDispatch();
  const [traits, setTraits] = useState<Res | null>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoader());
        const response = await get<Res>(`/engine/players/${pid}/positionalSkills`);
        if (response) {
          setTraits(response);
        }
      } finally {
        dispatch(removeLoader());
      }
    })();
  }, [pid]);

  return { traits };
};
