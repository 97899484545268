import { ProfileHeaderWithFriendButton } from 'components/Common/Header/ProfileHeader';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CounterTrade } from './CounterTrade';
import { useParams } from 'react-router-dom';
import { useTrade } from './hooks/useTrade';
import { useEffect } from 'react';
import history from 'routes/history';
import { notification } from 'antd';

export const CounterTradeMain: React.FC = () => {
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const { id } = useParams<{ id: string }>();
  const trade = useTrade(id);
  
  useEffect(() => {
    if (!userId || !trade) return;

    if (trade.trade[0].userId !== userId) {
      notification.error({ message: "You can't access this page" });
      history.push(`/profile/${userId}`);
    }
  }, [userId, trade]);

  if (!userId) return null;

  return (
    <Container>
      <ProfileHeaderWithFriendButton userId={userId} />
      <CounterTrade trade={trade} />
    </Container>
  );
};
