import { Col, Row } from 'react-bootstrap';
import { PurchaseTeam } from 'components/NoFranchises/PurchaseTeam';
import { PurchaseTrophy } from 'components/Common/PurchaseTrophy/PurchaseTrophy';
import history from 'routes/history';
import teamImage from '../../../assets/images/homeScreen/franchiseBuild.gif';

export const AcquireGameAssets: React.FC = () => (
  <Row>
    <Col xl={6}>
      <PurchaseTeam noHeader shorterText />
    </Col>
    <Col xl={6}>
      <PurchaseTrophy />
    </Col>
    <Col xl={6}>
      <PurchaseTrophy
        image={teamImage}
        title="CHECK OUT THE COMPETITION"
        description="HERE ARE ALL THE MINTED TEAMS ON GMDYNASTY"
        buttonText="VIEW" buttonOnClick={() => history.push('/marketplace/teams')} />
    </Col>
  </Row>
);
