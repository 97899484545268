export const INIT_APP = "INIT_APP";

export const ADD_LOADER = "ADD_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";

export const GETLEAGUESTANDINGDIVISION_REQUEST =
  "GETLEAGUESTANDINGDIVISION_REQUEST";
export const GETLEAGUESTANDINGDIVISION_SUCCESS =
  "GETLEAGUESTANDINGDIVISION_SUCCESS";
export const GETLEAGUESTANDINGDIVISION_FAIL = "GETLEAGUESTANDINGDIVISION_FAIL";

export const GETLEAGUESTANDINGCONFERENCE_REQUEST =
  "GETLEAGUESTANDINGCONFERENCE_REQUEST";
export const GETLEAGUESTANDINGCONFERENCE_SUCCESS =
  "GETLEAGUESTANDINGCONFERENCE_SUCCESS";
export const GETLEAGUESTANDINGCONFERENCE_FAIL =
  "GETLEAGUESTANDINGCONFERENCE_FAIL";

export const GET_TEAM_TROPHIES_REQUEST =
  "GET_TEAM_TROPHIES_REQUEST";
export const GET_TEAM_TROPHIES_SUCCESS =
  "GET_TEAM_TROPHIES_SUCCESS";
export const GET_TEAM_TROPHIES_FAIL =
  "GET_TEAM_TROPHIES_FAIL";

export const GETPLAYERSTATS_REQUEST =
  "GETPLAYERSTATS_REQUEST";
export const GETPLAYERSTATS_SUCCESS =
  "GETPLAYERSTATS_SUCCESS";
export const GETPLAYERSTATS_FAIL =
  "GETPLAYERSTATS_FAIL";

export const GETLEAGUEWEEKLYSCORES_REQUEST = "GETLEAGUEWEEKLYSCORES_REQUEST";
export const GETLEAGUEWEEKLYSCORES_SUCCESS = "GETLEAGUEWEEKLYSCORES_SUCCESS";
export const GETLEAGUEWEEKLYSCORES_FAIL = "GETLEAGUEWEEKLYSCORES_FAIL";

export const GETLEAGUELEADERS_REQUEST = "GETLEAGUELEADERS_REQUEST";
export const GETLEAGUELEADERS_SUCCESS = "GETLEAGUELEADERS_SUCCESS";
export const GETLEAGUELEADERS_FAIL = "GETLEAGUELEADERS_FAIL";

export const GETTEAMSTAT_REQUEST = "GETTEAMSTAT_REQUEST";
export const GETTEAMSTAT_SUCCESS = "GETTEAMSTAT_SUCCESS";
export const GETTEAMSTAT_FAIL = "GETTEAMSTAT_FAIL";

export const SELECTLEAGUEAPI_REQUEST = "SELECTLEAGUEAPI_REQUEST";
export const SELECTLEAGUEAPI_SUCCESS = "SELECTLEAGUEAPI_SUCCESS";
export const SELECTLEAGUEAPI_FAIL = "SELECTLEAGUEAPI_FAIL";

export const AllLeagueAPI_REQUEST = "AllLeagueAPI_REQUEST";
export const AllLeagueAPI_SUCCESS = "AllLeagueAPI_SUCCESS";
export const AllLeagueAPI_FAIL = "AllLeagueAPI_FAIL";

export const TeamsActiveGames_REQUEST = "TeamsActiveGames_REQUEST";
export const TeamsActiveGames_SUCCESS = "TeamsActiveGames_SUCCESS";
export const TeamsActiveGames_FAIL = "TeamsActiveGames_FAIL";

export const GameByIdAPI_REQUEST = "GameByIdAPI_REQUEST";
export const GameByIdAPI_SUCCESS = "GameByIdAPI_SUCCESS";
export const GameByIdAPI_FAIL = "GameByIdAPI_FAIL";

export const SelectFranchise_REQUEST = "SelectFranchise_REQUEST";
export const SelectFranchise_SUCCESS = "SelectFranchise_SUCCESS";
export const SelectFranchise_FAIL = "SelectFranchise_FAIL";

export const PregameOverview_REQUEST = "PregameOverview_REQUEST";
export const PregameOverview_SUCCESS = "PregameOverview_SUCCESS";
export const PregameOverview_FAIL = "PregameOverview_FAIL";

export const JOINLEAGUEAPI_REQUEST = "JOINLEAGUEAPI_REQUEST";
export const JOINLEAGUEAPI_SUCCESS = "JOINLEAGUEAPI_SUCCESS";
export const JOINLEAGUEAPI_FAIL = "JOINLEAGUEAPI_FAIL";

export const PregameStatus_REQUEST = "PregameStatus_REQUEST";
export const PregameStatus_SUCCESS = "PregameStatus_SUCCESS";
export const PregameStatus_FAIL = "PregameStatus_FAIL";

export const FULLTEAMSTATS_REQUEST = "FULLTEAMSTATS_REQUEST";
export const FULLTEAMSTATS_SUCCESS = "FULLTEAMSTATS_SUCCESS";
export const FULLTEAMSTATS_FAIL = "FULLTEAMSTATS_FAIL";

export const SecondUserJoinLeagueAPI_REQUEST = "SecondUserJoinLeagueAPI_REQUEST";
export const SecondUserJoinLeagueAPI_SUCCESS = "SecondUserJoinLeagueAPI_SUCCESS";
export const SecondUserJoinLeagueAPI_FAIL = "SecondUserJoinLeagueAPI_FAIL";


export const UserRecordAPI_REQUEST = "UserRecordAPI_REQUEST";
export const UserRecordAPI_SUCCESS = "UserRecordAPI_SUCCESS";
export const UserRecordAPI_FAIL = "UserRecordAPI_FAIL";

export const GETTEAMOVERVIEW_REQUEST = "GETTEAMOVERVIEW_REQUEST";
export const GETTEAMOVERVIEW_SUCCESS = "GETTEAMOVERVIEW_SUCCESS";
export const GETTEAMOVERVIEW_FAIL = "GETTEAMOVERVIEW_FAIL";

export const GETTEAMDEPTHCHART_REQUEST = "GETTEAMDEPTHCHART_REQUEST";
export const GETTEAMDEPTHCHART_SUCCESS = "GETTEAMDEPTHCHART_SUCCESS";
export const GETTEAMDEPTHCHART_FAIL = "GETTEAMDEPTHCHART_FAIL";

export const GAMEENGINETOKEN_REQUEST = "GAMEENGINETOKEN_REQUEST";
export const GAMEENGINETOKEN_SUCCESS = "GAMEENGINETOKEN_SUCCESS";
export const GAMEENGINETOKEN_FAIL = "GAMEENGINETOKEN_FAIL";

export const GETTEAMPLAYERSREVEAL_REQUEST = "GETTEAMPLAYERSREVEAL_REQUEST";
export const GETTEAMPLAYERSREVEAL_SUCCESS = "GETTEAMPLAYERSREVEAL_SUCCESS";
export const GETTEAMPLAYERSREVEAL_FAIL = "GETTEAMPLAYERSREVEAL_FAIL";

export const GETTEAMROSTER_REQUEST = "GETTEAMROSTER_REQUEST";
export const GETTEAMROSTER_SUCCESS = "GETTEAMROSTER_SUCCESS";
export const GETTEAMROSTER_FAIL = "GETTEAMROSTER_FAIL";

export const GETTEAMGAMEPLAN_REQUEST = "GETTEAMGAMEPLAN_REQUEST";
export const GETTEAMGAMEPLAN_SUCCESS = "GETTEAMGAMEPLAN_SUCCESS";
export const GETTEAMGAMEPLAN_FAIL = "GETTEAMGAMEPLAN_FAIL";

export const PUTTEAMGAMESPECIFICPLAN_REQUEST = "PUTTEAMGAMESPECIFICPLAN_REQUEST";
export const PUTTEAMGAMESPECIFICPLAN_SUCCESS = "PUTTEAMGAMESPECIFICPLAN_SUCCESS";
export const PUTTEAMGAMESPECIFICPLAN_FAIL = "PUTTEAMGAMESPECIFICPLAN_FAIL";

export const PUTTEAMGAMEPLAN_REQUEST = "PUTTEAMGAMEPLAN_REQUEST";
export const PUTTEAMGAMEPLAN_SUCCESS = "PUTTEAMGAMEPLAN_SUCCESS";
export const PUTTEAMGAMEPLAN_FAIL = "PUTTEAMGAMEPLAN_FAIL";

export const PUTTEAMDEPTHCHART_REQUEST = "PUTTEAMDEPTHCHART_REQUEST";
export const PUTTEAMDEPTHCHART_SUCCESS = "PUTTEAMDEPTHCHART_SUCCESS";
export const PUTTEAMDEPTHCHART_FAIL = "PUTTEAMDEPTHCHART_FAIL";

export const GETTEAMLEAGUESHISTORY_REQUEST = "GETTEAMLEAGUESHISTORY_REQUEST";
export const GETTEAMLEAGUESHISTORY_SUCCESS = "GETTEAMLEAGUESHISTORY_SUCCESS";
export const GETTEAMLEAGUESHISTORY_FAIL = "GETTEAMLEAGUESHISTORY_FAIL";

export const GETTEAMLEADERS_REQUEST = "GETTEAMLEADERS_REQUEST";
export const GETTEAMLEADERS_SUCCESS = "GETTEAMLEADERS_SUCCESS";
export const GETTEAMLEADERS_FAIL = "GETTEAMLEADERS_FAIL";

export const GETHEADTOHEADOVERVIEW_REQUEST = "GETHEADTOHEADOVERVIEW_REQUEST";
export const GETHEADTOHEADOVERVIEW_SUCCESS = "GETHEADTOHEADOVERVIEW_SUCCESS";
export const GETHEADTOHEADOVERVIEW_FAIL = "GETHEADTOHEADOVERVIEW_FAIL";

export const GETGAMESCORES_REQUEST = "GETGAMESCORES_REQUEST";
export const GETGAMESCORES_SUCCESS = "GETGAMESCORES_SUCCESS";
export const GETGAMESCORES_FAIL = "GETGAMESCORES_FAIL";

export const GETGAMESEASONSCORES_REQUEST = "GETGAMESEASONSCORES_REQUEST";
export const GETGAMESEASONSCORES_SUCCESS = "GETGAMESEASONSCORES_SUCCESS";
export const GETGAMESEASONSCORES_FAIL = "GETGAMESEASONSCORES_FAIL";

export const GETALLLEAGUES_REQUEST = "GETALLLEAGUES_REQUEST";
export const GETALLLEAGUES_SUCCESS = "GETALLLEAGUES_SUCCESS";
export const GETALLLEAGUES_FAIL = "GETALLLEAGUES_FAIL";

export const GETOWNERTEAMS_REQUEST = "GETOWNERTEAMS_REQUEST";
export const GETOWNERTEAMS_SUCCESS = "GETOWNERTEAMS_SUCCESS";
export const GETOWNERTEAMS_FAIL = "GETOWNERTEAMS_FAIL";

export const GETOWNERSTATS_REQUEST = "GETOWNERSTATS_REQUEST";
export const GETOWNERSTATS_SUCCESS = "GETOWNERSTATS_SUCCESS";
export const GETOWNERSTATS_FAIL = "GETOWNERSTATS_FAIL";

export const GETTEAMFRONTOFFICE_REQUEST = "GETTEAMFRONTOFFICE_REQUEST";
export const GETTEAMFRONTOFFICE_SUCCESS = "GETTEAMFRONTOFFICE_SUCCESS";
export const GETTEAMFRONTOFFICE_FAIL = "GETTEAMFRONTOFFICE_FAIL";

export const FETCHALLSELECTEDAVATAR_REQUEST = "FETCHSELECTEDAVATAR_REQUEST";
export const FETCHALLSELECTEDAVATAR_SUCCESS = "FETCHSELECTEDAVATAR_SUCCESS";
export const FETCHALLSELECTEDAVATAR_FAIL = "FETCHSELECTEDAVATAR_FAIL";

export const userReadyState_REQUEST = "userReadyState_REQUEST";
export const userReadyState_SUCCESS = "userReadyState_SUCCESS";
export const userReadyState_FAIL = "userReadyState_FAIL";

export const readyGamePlay_REQUEST = "readyGamePlay_REQUEST";
export const readyGamePlay_SUCCESS = "readyGamePlay_SUCCESS";
export const readyGamePlay_FAIL = "readyGamePlay_FAIL";

export const readyBothGamePlay_SUCCESS = "readyGamePlay_SUCCESS";

export const GET_PER_GAME_LEADERS_REQUEST = "GET_PER_GAME_LEADERS_REQUEST";
export const GET_PER_GAME_LEADERS_SUCCESS = "GET_PER_GAME_LEADERS_SUCCESS";
export const GET_PER_GAME_LEADERS_FAIL = "GET_PER_GAME_LEADERS_FAIL";

export const GET_LAST_GAME_RESULTS_REQUEST = "GET_LAST_GAME_RESULTS_REQUEST";
export const GET_LAST_GAME_RESULTS_SUCCESS = "GET_LAST_GAME_RESULTS_SUCCESS";
export const GET_LAST_GAME_RESULTS_FAIL = "GET_LAST_GAME_RESULTS_FAIL";

export const GET_LAST_ELIMINATION_RESULTS_REQUEST = "GET_LAST_ELIMINATION_RESULTS_REQUEST";
export const GET_LAST_ELIMINATION_RESULTS_SUCCESS = "GET_LAST_ELIMINATION_RESULTS_SUCCESS";
export const GET_LAST_ELIMINATION_RESULTS_FAIL = "GET_LAST_ELIMINATION_RESULTS_FAIL";

export const GET_LAST_SEASON_RESULTS_REQUEST = "GET_LAST_SEASON_RESULTS_REQUEST";
export const GET_LAST_SEASON_RESULTS_SUCCESS = "GET_LAST_SEASON_RESULTS_SUCCESS";
export const GET_LAST_SEASON_RESULTS_FAIL = "GET_LAST_SEASON_RESULTS_FAIL";

export const GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_REQUEST = "GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_REQUEST";
export const GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_SUCCESS = "GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_SUCCESS";
export const GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_FAIL = "GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_FAIL";

export const GET_GAME_TEAMS_REQUEST = "GET_GAME_TEAMS_REQUEST";
export const GET_GAME_TEAMS_SUCCESS = "GET_GAME_TEAMS_SUCCESS";
export const GET_GAME_TEAMS_FAIL = "GET_GAME_TEAMS_TEAM_FAIL";

export const GET_GAME_TEAMS_READY_REQUEST = "GET_GAME_TEAMS_READY_REQUEST";
export const GET_GAME_TEAMS_READY_SUCCESS = "GET_GAME_TEAMS_READY_SUCCESS";
export const GET_GAME_TEAMS_READY_FAIL = "GET_GAME_TEAMS_READY_FAIL";

export const ShortPregameOverview_REQUEST = "ShortPregameOverview_REQUEST";
export const ShortPregameOverview_SUCCESS = "ShortPregameOverview_SUCCESS";
export const ShortPregameOverview_FAIL = "ShortPregameOverview_FAIL";

export const GETGAMESEASONSCORESBYGID_REQUEST = "GETGAMESEASONSCORESBYGID_REQUEST";
export const GETGAMESEASONSCORESBYGID_SUCCESS = "GETGAMESEASONSCORESBYGID_SUCCESS";
export const GETGAMESEASONSCORESBYGID_FAIL = "GETGAMESEASONSCORESBYGID_FAIL";

export const POST_USER_PROBLEM_REQUEST = "POST_USER_PROBLEM_REQUEST"
export const POST_USER_PROBLEM_SUCCESS = "POST_USER_PROBLEM_SUCCESS"
export const POST_USER_PROBLEM_FAIL = "POST_USER_PROBLEM_FAIL"

export const CLEAN_PREGAMEOVERVIEW = "CLEAN_PREGAMEOVERVIEW"

export const TOGGLE_LOADER_ON = "TOGGLE_LOADER_ON";
export const TOGGLE_LOADER_OFF = "TOGGLE_LOADER_OFF";
export const TOGGLE_LOADER_OFF_WITH_ERROR = "TOGGLE_LOADER_OFF_WITH_ERROR";

export const GET_TEAM_OF_WEEK_DATA_REQUEST = "GET_TEAM_OF_WEEK_DATA_REQUEST";
export const GET_TEAM_OF_WEEK_DATA_SUCCESS = "GET_TEAM_OF_WEEK_DATA_SUCCESS";
export const GET_TEAM_OF_WEEK_DATA_FAIL = "GET_TEAM_OF_WEEK_DATA_FAIL";

export const GET_GAME_OF_WEEK_ID_REQUEST = "GET_GAME_OF_WEEK_ID_REQUEST";
export const GET_GAME_OF_WEEK_ID_SUCCESS = "GET_GAME_OF_WEEK_ID_SUCCESS";
export const GET_GAME_OF_WEEK_ID_FAIL = "GET_GAME_OF_WEEK_ID_FAIL";

export const GET_WEEKLY_LEADERS_WINS_REQUEST = "GET_WEEKLY_LEADERS_WINS_REQUEST";
export const GET_WEEKLY_LEADERS_WINS_SUCCESS = "GET_WEEKLY_LEADERS_WINS_SUCCESS";
export const GET_WEEKLY_LEADERS_WINS_FAIL = "GET_WEEKLY_LEADERS_WINS_FAIL";

export const GET_WEEKLY_LEADERS_RATINGS_REQUEST = "GET_WEEKLY_LEADERS_RATINGS_REQUEST";
export const GET_WEEKLY_LEADERS_RATINGS_SUCCESS = "GET_WEEKLY_LEADERS_RATINGS_SUCCESS";
export const GET_WEEKLY_LEADERS_RATINGS_FAIL = "GET_WEEKLY_LEADERS_RATINGS_FAIL";

export const GET_TEAM_LEADERS_WINS_REQUEST = "GET_TEAM_LEADERS_WINS_REQUEST";
export const GET_TEAM_LEADERS_WINS_SUCCESS = "GET_TEAM_LEADERS_WINS_SUCCESS";
export const GET_TEAM_LEADERS_WINS_FAIL = "GET_TEAM_LEADERS_WINS_FAIL";

export const GET_TEAM_LEADERS_PLAYED_REQUEST = "GET_TEAM_LEADERS_PLAYED_REQUEST";
export const GET_TEAM_LEADERS_PLAYED_SUCCESS = "GET_TEAM_LEADERS_PLAYED_SUCCESS";
export const GET_TEAM_LEADERS_PLAYED_FAIL = "GET_TEAM_LEADERS_PLAYED_FAIL";

export const GET_TEAM_LEADERS_SCORED_REQUEST = "GET_TEAM_LEADERS_SCORED_REQUEST";
export const GET_TEAM_LEADERS_SCORED_SUCCESS = "GET_TEAM_LEADERS_SCORED_SUCCESS";
export const GET_TEAM_LEADERS_SCORED_FAIL = "GET_TEAM_LEADERS_SCORED_FAIL";

export const GET_TEAM_LEADERS_CONS_WINS_REQUEST = "GET_TEAM_LEADERS_CONS_WINS_REQUEST";
export const GET_TEAM_LEADERS_CONS_WINS_SUCCESS = "GET_TEAM_LEADERS_CONS_WINS_SUCCESS";
export const GET_TEAM_LEADERS_CONS_WINS_FAIL = "GET_TEAM_LEADERS_CONS_WINS_FAIL";

export const GET_TEAM_LEADERS_WINRATE_REQUEST = "GET_TEAM_LEADERS_WINRATE_REQUEST";
export const GET_TEAM_LEADERS_WINRATE_SUCCESS = "GET_TEAM_LEADERS_WINRATE_SUCCESS";
export const GET_TEAM_LEADERS_WINRATE_FAIL = "GET_TEAM_LEADERS_WINRATE_FAIL";

export const GET_TEAM_LEADERS_PLAYOFFS_REQUEST = "GET_TEAM_LEADERS_PLAYOFFS_REQUEST";
export const GET_TEAM_LEADERS_PLAYOFFS_SUCCESS = "GET_TEAM_LEADERS_PLAYOFFS_SUCCESS";
export const GET_TEAM_LEADERS_PLAYOFFS_FAIL = "GET_TEAM_LEADERS_PLAYOFFS_FAIL";

export const SET_TEAM_LEADERS_VIEW_MORE_TYPE = "SET_TEAM_LEADERS_VIEW_MORE_TYPE";

export const TEAM_LEADERS_VIEW_MORE_REQUEST = "TEAM_LEADERS_VIEW_MORE_REQUEST";
export const SET_TEAM_LEADERS_VIEW_MORE_DATA = "SET_TEAM_LEADERS_VIEW_MORE_DATA";
export const CLEAN_TEAM_LEADERS_VIEW_MORE_DATA = "CLEAN_TEAM_LEADERS_VIEW_MORE_DATA";

export const GET_PLAYERS_LEADERS_REQUEST = "GET_PLAYERS_LEADERS_REQUEST";
export const GET_PLAYERS_LEADERS_SUCCESS = "GET_PLAYERS_LEADERS_SUCCESS";
export const GET_PLAYERS_LEADERS_FAIL = "GET_PLAYERS_LEADERS_FAIL"
export const SET_PLAYERS_LEADERS_TYPE = "SET_PLAYERS_LEADERS_TYPE"
export const SET_PLAYERS_LEADERS_TYPE_DATA = "SET_PLAYERS_LEADERS_TYPE_DATA"

export const LEAVE_GAME_REQUEST = "LEAVE_GAME_REQUEST";
export const LEAVE_GAME_SUCCESS = "LEAVE_GAME_SUCCESS";
export const LEAVE_GAME_FAIL = "LEAVE_GAME_FAIL";
export const LEAVE_GAME_CLEAN = "LEAVE_GAME_CLEAN";

export const CREATE_LEAGUE_REQUEST = "CREATE_LEAGUE_REQUEST";
export const CREATE_LEAGUE_SUCCESS = "CREATE_LEAGUE_SUCCESS";
export const CREATE_LEAGUE_FAIL = "CREATE_LEAGUE_FAIL";
export const CLEAN_CREATE_LEAGUE_DATA = "CLEAN_CREATE_LEAGUE_DATA";

export const GET_FRANCHISE_PERFORMANCE_REQUEST = "GET_FRANCHISE_PERFORMANCE_REQUEST";
export const GET_FRANCHISE_PERFORMANCE_SUCCESS = "GET_FRANCHISE_PERFORMANCE_SUCCESS";
export const GET_FRANCHISE_PERFORMANCE_FAIL = "GET_FRANCHISE_PERFORMANCE_FAIL";

export const GET_SEASON_INFO_REQUEST = "GET_SEASON_INFO_REQUEST";
export const GET_SEASON_INFO_SUCCESS = "GET_SEASON_INFO_SUCCESS";
export const GET_SEASON_INFO_FAIL = "GET_SEASON_INFO_FAIL";

export const GET_TEAM_ACTIVE_GAMES_DETAILS_REQUEST = "GET_TEAM_ACTIVE_GAMES_DETAILS_REQUEST";
export const GET_TEAM_ACTIVE_GAMES_DETAILS_SUCCESS = "GET_TEAM_ACTIVE_GAMES_DETAILS_SUCCESS";
export const GET_TEAM_ACTIVE_GAMES_DETAILS_FAIL = "GET_TEAM_ACTIVE_GAMES_DETAILS_FAIL";

export const GET_PLAYERS_STOCK_REPORT_REQUEST = "GET_PLAYERS_STOCK_REPORT_REQUEST";
export const GET_PLAYERS_STOCK_REPORT_SUCCESS = "GET_PLAYERS_STOCK_REPORT_SUCCESS";
export const GET_PLAYERS_STOCK_REPORT_FAIL = "GET_PLAYERS_STOCK_REPORT_FAIL";

export const SET_TEAM_LEAGUES_HISTORY_OVR = "SET_TEAM_LEAGUES_HISTORY_OVR";
