import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLoader, removeLoader } from 'redux/actions/GameEngineAction';
import { get } from 'redux/services/Api';
import { FullTrade } from 'types/trades';

const LIMIT = 2;

export const useUserTradeProposals = (userId: number) => {
  const dispatch = useDispatch();
  const { handleNextPage, handlePrevPage, page } = useServerSidePagination();
  const [tradeProposals, setTradeProposals] = useState<{
    data: FullTrade[];
    hasNextPage: boolean;
  } | null>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoader());
        const response = await get<{
          data: FullTrade[];
          hasNextPage: boolean;
        }>(`/engine/owner/${userId}/trades?page=${page}&limit=${LIMIT}`);
        setTradeProposals(response);
      } catch (e) {
      } finally {
        dispatch(removeLoader());
      }
    })();
  }, [userId, page]);

  return {
    tradeProposals,
    handleNextPage,
    handlePrevPage,
    page,
  };
};
