import { MVP_CONVERSION_RATE } from 'config';
import './MVPPrice.scss';

type Props = {
  amt?: number;
};

export const MVPPrice = ({ amt = 0 }: Props = { amt: 0 }) => (
  <span className="mvp-price">
    <span className="mvp-price__crown">🜲</span> {amt * MVP_CONVERSION_RATE}
  </span>
);
