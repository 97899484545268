import CircleIcon from './CircleIcon';
import './CircleGrey.scss';
import CircleInfoSvg from '../../../../assets/images/common/info.svg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
}

const CircleInfoIcon: React.FC<Props> = (props) => <CircleIcon {...props} icon={CircleInfoSvg} className="circleGrey" />;

export default CircleInfoIcon;
