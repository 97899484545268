import Dropdown from 'components/Common/Form/Dropdown/Dropdown';

const allPositions = ['QB', 'RB', 'WR', 'TE', 'K', 'P', 'DL', 'LB', 'CB', 'S', 'OL'];

const options = [
  {
    option: 'ALL',
    value: '',
  },
  ...allPositions.map((p) => ({
    option: p,
    value: p,
  })),
];

type PositionSelectorProps = {
  pos: string | null;
  setPos: (value: string) => void;
};

export const PositionSelector: React.FC<PositionSelectorProps> = ({ pos, setPos }) => (
  <Dropdown
    value={pos}
    onChange={(value) => setPos(value.target.value)}
    dropDownOptions={options}
  />
);
