import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { Divider, Table } from "antd";
import { Modal } from "antd";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import metamaskconnect from "../../../../../assets/images/wallet/metaMaskConnected.png";
import { useMetamask } from "use-metamask";
import ConnectNewWalletModal from "components/Setting/TabContent/WalletTab/ConnectNewWalletModal";
import { connectMetaMaskRequest } from "redux/actions/AuthAction";
import Web3 from "web3";
import { metaMaskWalletId, notify, postPlayerToMint } from "helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { RosterPlayer, UserTeamsList } from "types";
import { addLoader, removeLoader } from "redux/actions/GameEngineAction";

const abis = require(`./abis.json`);

// Function to get ETH to USD conversion rate
const getEthToUsdRate = async (): Promise<number> => {
  try {
    const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
    const data = await response.json();
    return data.USD;
  } catch (error) {
    console.error("Error fetching ETH to USD rate:", error);
    return 0;
  }
};

const MintPlayerConnected: React.FC<{
  team: UserTeamsList[0] | null;
  roster: RosterPlayer | null;
  pathToGif?: string;
  pathToJson?: string;
  setActiveTab: (value: string) => void;
}> = ({ team, roster, pathToGif, pathToJson, setActiveTab }) => {
  const { connect, metaState } = useMetamask();
  const dispatch = useDispatch();
  const metaMaskError = useSelector((state: RootState) => state.app.metaMaskError);
  const resData = useSelector((state: RootState) => state.app.resData);

  const [walletData, setWalletData] = useState({
    purchase: "Mint Player",
    amount: "$ 3",
    eth: "0.031",
  });

  const [userBalanceETH, setUserBalanceETH] = useState('0.00');
  const [callMetamask, setCallMetamask] = useState<boolean>(false);
  const [shortMetaMaskId, setShortMetaMaskId] = useState<string>('Not connected');
  const [msg, setMsg] = useState<string>('');
  const [contract, setContract] = useState<any>(null);

  const fetchWalletData = async () => {
    const ethToUsdRate = await getEthToUsdRate();
    const usdToEthAmount = (3 / ethToUsdRate).toFixed(5);

    setWalletData({
      purchase: "Mint Player",
      amount: `$ 3`,
      eth: usdToEthAmount,
    });
  };

  const routeToFirstTab = () => {
    setActiveTab('0');
  };

  useEffect(() => {
    fetchWalletData();
  }, [resData?.metaMaskID]);

  const columns = [
    {
      title: "PURCHASE",
      dataIndex: "purchase",
    },
    {
      title: "AMT",
      dataIndex: "amount",
    },
    {
      title: "ETH",
      dataIndex: "eth",
    },
  ];

  const offensiveLinkData = [
    {
      key: "1",
      purchase: walletData.purchase,
      amount: walletData.amount,
      eth: walletData.eth,
    },
    {
      key: "2",
      purchase: "TOTAL",
      amount: walletData.amount,
      eth: walletData.eth,
    },
  ];

  useEffect(() => {
    if (metaMaskError) {
      setMsg(metaMaskError);
    }
  }, [metaMaskError]);

  useEffect(() => {
    if (resData?.metaMaskID) {
      setShortMetaMaskId(metaMaskWalletId(resData?.metaMaskID));
    }
  }, [resData?.metaMaskID]);

  // CONNECT NEW WALLET
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (callMetamask && metaState.account?.length) {
      dispatch(connectMetaMaskRequest(metaState.account[0]));
      setCallMetamask(false);
      handleCancel();
    }
  }, [metaState.account, callMetamask, resData?.metaMaskID, dispatch]);

  useEffect(() => {
    (async () => {
      if (metaState.isAvailable && !metaState.isConnected) {
        try {
          if (connect !== undefined && Web3 !== undefined) {
            await connect(Web3);
          }
          return handleCancel();
        } catch (error) {
          console.log(error);
        }
      }
    })();

  }, [metaState.isAvailable, connect, metaState.isConnected]);

  const connectMetamask = async () => {
    setCallMetamask(true);
  };

  useEffect(() => {
    if (metaState.web3) {
      const _contract = new metaState.web3.eth.Contract(abis.abi, abis.address);
      setContract(_contract);
    }
  }, [metaState.web3]);

  useEffect(() => {
    const { web3 } = metaState;
    (async () => {
      if (resData?.metaMaskID) {
        let _balance: any;
        if (web3?.eth) {
          _balance = await metaState.web3?.eth?.getBalance(resData?.metaMaskID);
        } else {
          _balance = await metaState.web3?.getBalance(resData?.metaMaskID);
        }
        const bal: any = (_balance / 10 ** 18).toFixed(3);
        setUserBalanceETH(bal || '0.000');
      } else {
        setUserBalanceETH('0.000');
      }
    })();
  }, [resData, metaState]);

  const MMButtonText = resData?.metaMaskID ? 'CHANGE WALLET' : 'CONNECT WALLET';
  const isSame = resData?.metaMaskID === metaState.account?.[0];


  const checkTx = (hash: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      // Set interval to regularly check if we can get a receipt
      let interval = setInterval(async () => {
        await metaState.web3.eth.getTransactionReceipt(hash, (err: any, receipt: any) => {
          // If we've got a receipt, check status and log / change text accordingly
          if (receipt) {
            console.log("Gotten receipt");
            clearInterval(interval); // Clear interval
            if (receipt.status === true) {
              console.log(receipt);
              resolve(receipt); // Resolve the promise with the receipt
            } else if (receipt.status === false) {
              reject(new Error("Transaction failed")); // Reject the promise with an error
            }
          }
        });
      }, 1000);
    });
  };

  const extractTokenIdFromReceipt = (receipt: any): string | null => {
    for (let log of receipt.logs) {
      const tokenId: string = metaState.web3.utils.hexToNumberString(log.topics[3]);
      if (tokenId) {
        console.log("ERC721 token ID: ", tokenId);
        return tokenId;
      }
    }
    return null;
  };

  const handleMint = async () => {
    if (!metaState.isConnected || !metaState.account?.length) {
      console.error("Wallet not connected or account not available");
      return;
    }

    try {
      dispatch(addLoader());
      const account = metaState.account[0];
      const gasPrice = await metaState.web3.eth.getGasPrice();
      const minimumWei = await metaState.web3.utils.toWei("0.000001", "ether");
      const valueInWei = metaState.web3.utils.toWei(walletData.eth, "ether");
      const valueToSend = Math.max(valueInWei, minimumWei);
      const gasEstimate = await contract.methods.safeMint(account, pathToJson).estimateGas({ from: account, value: valueToSend, gasPrice });
      if (pathToJson) {

        const tx = await contract.methods
          .safeMint(account, pathToJson)
          .send({ from: account, value: valueToSend, gas: gasEstimate, gasPrice });

        const receipt = await checkTx(tx.transactionHash);
        const tokenId = extractTokenIdFromReceipt(receipt);
        if (tokenId) {
          await postPlayerToMint({
            teamId: team?.engId,
            playerId: roster?.pid,
            ownerId: resData?.id,
            creatorId: resData?.id,
            nftTokenId: tokenId,
            jsonFile: pathToJson,
          });
          notify("success", "Player minted successfully");
          routeToFirstTab();
        }
      }
    } catch (error: any) {
      console.error("Minting Error:", error);
      notify("error", JSON.stringify(error?.message));
    } finally {
      dispatch(removeLoader());
    }
  };

  return (
    <Stack gap={4} direction="horizontal">
      {pathToGif && <img src={pathToGif} alt="metamask" className="mb-3" width={300} />}

      <Stack gap={2}>
        <div className="walletconnectedmain">

          <Stack direction="vertical" gap={2}>
            <img src={metamaskconnect} alt="metamask" className="mb-3" width={200} />
            <Stack direction="horizontal" gap={2}>
              <Stack direction="vertical" gap={1} className={`${msg || !metaState?.isConnected || isSame ? '' : 'd-none'}`}>
                {msg && <p className="error mb-3">{msg}</p>}
                {!metaState?.isConnected && <p className="error mb-3">UNABLE TO CONNECT TO METAMASK</p>}
                {isSame && <p className="error mb-3 mt-3">WALLET CONNECTED</p>}
              </Stack>
              <BlueButton onClick={showModal} text={MMButtonText} disabled={!metaState?.isConnected || isSame} />
            </Stack>
          </Stack>

          <ul>
            <li className="wallethead">WALLET ID :</li>
            <li>{shortMetaMaskId}</li>
          </ul>
          <ul>
            <li className="wallethead">ETH BALANCE :</li>
            <li>{userBalanceETH}</li>
          </ul>
        </div>
        <Divider className="pricelinehori" />
        <Table
          columns={columns}
          pagination={false}
          dataSource={offensiveLinkData}
          size="small"
          className="mt-3 paymentprice"
        />

        <div className="my-3 proceed">
          <BlueButton text="MINT" onClick={handleMint} />
        </div>

        <Modal title="" className="connectnewwallet" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered>
          <ConnectNewWalletModal onClick={connectMetamask} mode={resData?.metaMaskID ? 'change' : 'connect'} />
        </Modal>
      </Stack>
    </Stack>
  );
};

export default MintPlayerConnected;
