/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from 'react-bootstrap';
import { Row, Col, notification } from 'antd';
import CardBody from '../Common/Card/CardBody';
import Card from '../Common/Card/Card';
import Ground from './Ground';
import './GamePlayStyle.scss';
import QuaterInfo from './QuaterInfo';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL, QUARTER_PAUSE } from '../../config';
import { setTimeout, clearTimeout } from 'worker-timers';
import { useDispatch, useSelector } from 'react-redux';
import { PregameOverview } from '../../redux/actions/GameEngineAction';
import LeagueHeader from '../Common/LeagueHeader/LeagueHeader';
import { useGameAnimationsEvents } from './hooks/useGameAnimations';
import { SelectedFormations } from './SelectedFormations';
import { useInviteFriendPopup } from './hooks/useInviteFriendPopup';
import { redirectOnGameEnd } from './helper/RedirectOnGameEnd';
import { InviteFriendPopup } from './InviteFriendPopup';
import { gmdComputerTeams } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';
import { TrialGamePopup } from './TrialGamePopup';
import { useFreeGame } from 'components/layout/WithLoginNavbar/hooks/useFreeGame';
import { GamePlayQuarterStats } from './GamePlayInGameStats';
import { ShareGamePlay } from './ShareGamePlay';
import { HorizontalAd, SquareAd } from 'components/Ads';
import { isProdEnvironment } from 'helper/isProdEnvironment';
import { getRandomComment, hasChance } from './helper';
import Chat from './Chat/Chat';
import ScoreContainer from './ScoreContainer';
import { ChangeButton } from './ChangeFormation/components/ChangeButton/ChangeButton';
import { ChangeStrategyModal } from './ChangeFormation/modals/ChangeStrategyModal/ChangeStrategyModal';
import { UserTeamsList } from 'types';
import { PbPComment } from './PbPComment';
import ReplayTabMain from 'components/Replay/ReplayTabMain';

export interface GameEvent {
  type: string;
  animationType: string | null;
  down: number;
  quarter: string;
  rtSecs: number;
  rtStamp: string;
  scrimmage: number;
  t: number;
  text: string;
  ticks: number;
  time: string;
  toGo: number;
  comment?: string;
}

type Props = {
  gameId: number;
  replayMode?: boolean;
};

const GamePlayer: React.FC<Props> = ({ gameId, replayMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [quarterMessages, setQuarterMessages] = useState<GameEvent[]>([]);
  const [quarterCount, setQuarterCount] = useState<number>(0);
  const [quarterTime, setQuarterTime] = useState('The game will start shortly...');
  const [quarterTitle, setQuarterTitle] = useState<string | undefined>();
  const [teamNum, setTeamNum] = useState<undefined | number>();
  const [curYdLine, setCurYdLine] = useState(50);
  const [curDown, setCurDown] = useState(1);
  const [ydsToGo, setYdsToGo] = useState(10);
  const [scoreSummary, setScoreSummary] = useState<any>();
  const [playByPlay, setPlayByPlay] = useState<GameEvent[]>([]);
  const [statEvents, setStatEvents] = useState<any>([]);
  const [franchise, setFranchise] = useState<UserTeamsList[0]>();
  const [qtrScoring, setQtrScoring] = useState<number[][]>([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ]);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const timeoutsRef = useRef<any[]>([]);
  const scoreTimeoutRef = useRef<any>(undefined);
  const [isStrategyChangePossible, setIsStrategyChangePossible] = useState(false);
  const [isStrategyOpen, setIsStrategyOpen] = useState(false);
  const { handleOpenInvitePopup, inviteFriendPopUp, handleCancelInvitePopup, inviteCreditsPromo } = useInviteFriendPopup();
  const userTeamList = useSelector((state: any) => state.team.userTeamsList);
  const gameOverview = useSelector((state: any) => state.gameEngine.PregameOverview);
  const { getAnimationFileUrlByGameEvent, fileUrl, handleSetFileUrl } = useGameAnimationsEvents(gameOverview?.animationType);

  const { gid } = useFreeGame();

  const resData = useSelector((state: RootState) => state.app.resData);

  let gamePbpURL = `${API_URL}/engine/games/h2h/${gameId}/playByPlay`;
  if (!!playByPlay.length && !!statEvents.length) {
    gamePbpURL += `?prevPbpLength=${playByPlay.length + statEvents.length}`;
  }

  const myRef: any = useRef();

  const qtrScoreRef: any = useRef();
  const league = useMemo(
    () => ({
      ...gameOverview,
      id: gameOverview?.gid,
      prizePool: gameOverview?.fee + (gameOverview?.fee * gameOverview?.payout) / 100,
      started: gameOverview?.created,
      gameTime: gameOverview?.end,
      payoutPct: gameOverview?.payout,
    }),
    [gameOverview],
  );

  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);

  const handleEndOfGame = () => {
    if (gid) {
      setIsTrialModalOpen(true);
    } else {
      handleOpenInvitePopup();
    }
  };

  useEffect(() => {
    if (gameOverview) {
      if ('trial' in gameOverview && gameOverview?.trial) {
        const franchise = userTeamList?.find((info: any) => info.engId == gameOverview?.teams?.[0]?.utid);
        setFranchise(franchise);
      } else {
        const franchise = userTeamList?.find((info: any) => [gameOverview?.teams?.[0]?.utid, gameOverview?.teams?.[1]?.utid].includes(info.engId));
        if (franchise) {
          setFranchise(franchise);
        }
      }
    }
  }, [gameOverview, userTeamList]);

  useEffect(() => {
    if (gameOverview?.trial && resData === null) {
      dispatch(gmdComputerTeams());
    }
  }, [gameOverview?.trial, resData]);

  useEffect(() => {
    let pbpIndex: number = 0;
    if (playByPlay !== null && playByPlay.length > 0) {
      const currentTime = new Date().getTime();
      const actualPbpEvents = playByPlay?.filter((evnt: any) => new Date(evnt?.rtStamp).getTime() < currentTime).reverse();
      pbpIndex = actualPbpEvents.length;
      const lastEvent = actualPbpEvents[0];
      if (lastEvent && !replayMode) {
        setYdsToGo(lastEvent?.toGo);
        setCurDown(lastEvent?.down);
        setCurYdLine(lastEvent?.scrimmage);
        setTeamNum(lastEvent?.t);
        setQuarterCount(parseInt(lastEvent?.quarter.slice(-1)));
        setQuarterTime(lastEvent?.time);
      }

      setQuarterMessages(replayMode ? [] : actualPbpEvents);

      if (actualPbpEvents.length === playByPlay.length && !replayMode) {
        if (playByPlay?.at(-1)?.text === 'End of game') {
          redirectOnGameEnd({
            gameOverview,
            queryStringGameId: gameId,
            franchise,
            history,
          });
        } else {
          retrievePlayByPlay(gamePbpURL);
          return;
        }
      }

      if (!timeoutsRef.current.length) {
        const timer = setTimeout(() => {
          handlePbpEvent(replayMode ? 0 : pbpIndex++);
        }, 2000);

        return () => clearTimeout(timer);
      }
    }
  }, [playByPlay?.length, gameOverview, franchise]);

  useEffect(() => {
    if (gameId) {
      dispatch(PregameOverview(gameId));
      retrievePlayByPlay(gamePbpURL);
    }
  }, [gameId]);

  function retrievePlayByPlay(gamePbpURL: string) {
    fetch(gamePbpURL).then((result: any) => {
      result.json().then((res: any) => {
        setScoreSummary(() => res?.scoring);
        if (res?.secondsRemaining > 0) {
          setTimeout(() => {
            retrievePlayByPlay(gamePbpURL);
          }, (res?.secondsRemaining + 1) * 1000);
        } else {
          const events = res?.playByPlay?.reduce(
            (prev: any, current: any) => {
              if (current.type !== 'stat' || (current.type == 'stat' && current.s == 'pts')) {
                prev.pbpEvents.push(current);
              } else {
                prev.statEvents.push(current);
              }
              return prev;
            },
            { pbpEvents: [], statEvents: [] },
          );
          if (events?.pbpEvents?.length > 0) {
            const pbpsLength = events.pbpEvents.length;
            let pbpsCopy = [...events.pbpEvents];
            if (!pbpsCopy.some((pbp: any) => 'comment' in pbp)) {
              pbpsCopy = pbpsCopy.map((pbp: any) => {
                if ('animationType' in pbp && pbp.animationType) {
                  const comment = getRandomComment(pbp.animationType);
                  if (comment) {
                    pbp.comment = comment;
                  }
                } else {
                  if (hasChance(pbpsLength / 20)) {
                    const comment = getRandomComment();
                    if (comment) {
                      pbp.comment = comment;
                    }
                  }
                }
                return pbp;
              });
            }
            setStatEvents(() => events.statEvents);
            setPlayByPlay(() => pbpsCopy);
            !replayMode && setQtrScoring(() => res?.currentScores);
          }
        }
      });
    });
  }

  function handlePbpEvent(index: number) {
    const handleNext = (timeout: number = 3000) => {
      const timer = setTimeout(() => {
        handlePbpEvent(++index);
      }, timeout);
      timeoutsRef.current.push(timer);
    };

    qtrScoreRef.current = qtrScoring;
    if (index === playByPlay?.length) {
      if (playByPlay[index]?.text === 'End of game') {
        handleEndOfGame();
      } else {
        timeoutsRef.current = [];
      }
      return;
    }

    if (index < playByPlay!.length) {
      let pbpEvent: any = playByPlay[index];
      const nextPbpEvent: any = playByPlay[index + 1];
      const timeoutToNextPbp: number = replayMode ? 3000 : nextPbpEvent?.rtStamp && pbpEvent?.rtStamp ? new Date(nextPbpEvent.rtStamp).getTime() - Date.now() : 3000;

      if (!pbpEvent) {
        handleNext(timeoutToNextPbp);
        return;
      }

      if (pbpEvent.type == 'clock') {
        if (pbpEvent.quarter != null) setQuarterInfo(pbpEvent.quarter);
        setQuarterTime(pbpEvent.time);
        setTeamNum(pbpEvent.t);
        setCurDown(pbpEvent.down);
        setCurYdLine(() => pbpEvent.scrimmage);
        setYdsToGo(pbpEvent.toGo);
      } else if (pbpEvent.type == 'text') {
        if (pbpEvent.text === 'Start of  overtime') {
          setQtrScoring((prevScoring) => {
            const copy = [...prevScoring];
            copy[0][5] = 0;
            copy[1][5] = 0;

            return copy;
          });
        }
        setQuarterMessages((prevMessages) => {
          const newMsgs = [...prevMessages];
          newMsgs.unshift(pbpEvent);
          return newMsgs;
        });

        setQuarterInfo(pbpEvent.quarter);
        setQuarterTime(pbpEvent.time);
        if (pbpEvent.animationType) {
          handleSetFileUrl(getAnimationFileUrlByGameEvent(pbpEvent.animationType));
          setIsAnimationPlaying(true);
          setTimeout(() => {
            handleSetFileUrl(null);
            setIsAnimationPlaying(false);
          }, 5000);
        }

        if (pbpEvent.text === 'End of game') {
          handleEndOfGame();
        }
      } else if (pbpEvent.type == 'stat' && pbpEvent.s == 'pts') {
        const timeout = setTimeout(() => {
          setQtrScoring((prevScores) => {
            const currentScores: number[][] = JSON.parse(JSON.stringify(prevScores));
            const qtrNum: number = parseInt(pbpEvent.quarter.charAt(pbpEvent.quarter?.slice(0, 2) === 'OT' ? 2 : 1));

            currentScores[pbpEvent.t][qtrNum] = currentScores[pbpEvent.t][qtrNum] + pbpEvent.amt;
            currentScores[pbpEvent.t][0] = currentScores[pbpEvent.t][0] + pbpEvent.amt;
            return currentScores;
          });
        }, 3000);
        scoreTimeoutRef.current = timeout;
      }
      handleNext(timeoutToNextPbp);
      return;
    }

    handleNext();
  }

  function setQuarterInfo(quarter: string) {
    if (quarter.slice(0, 2) === 'OT') {
      const quarterNumText: string | undefined = quarter.at(2);
      if (quarterNumText) {
        setQuarterCount(parseInt(quarterNumText));
        setQuarterTitle('OVERTIME');
      }
    } else if (quarter.at(0) === 'Q') {
      const quarterNumText: string | undefined = quarter.at(1);
      if (quarterNumText) setQuarterCount(parseInt(quarterNumText));
      setQuarterTitle('QUARTER');
    }
  }
  const isProd = isProdEnvironment();

  const setStrategyOpen = () => {
    if (isStrategyChangePossible) {
      setIsStrategyOpen(true);
    } else {
      notification.error({
        message: 'Strategy change is not possible at the moment',
        placement: 'bottomRight',
      });
    }
  };

  const setStrategyClose = () => {
    setIsStrategyOpen(false);
  };

  useEffect(() => {
    if (isStrategyChangePossible) {
      setStrategyOpen();
    } else {
      setStrategyClose();
    }
  }, [isStrategyChangePossible]);

  useEffect(() => {
    if (playByPlay && playByPlay?.[playByPlay.length - 1]?.text !== 'End of game' && !replayMode) {
      const currentTimestamp = new Date().getTime();
      const lastTimestamp = new Date(playByPlay?.[playByPlay.length - 1]?.rtStamp).getTime();
      const possibleTo = lastTimestamp + QUARTER_PAUSE * 1000 * 60;
      if (possibleTo > currentTimestamp) {
        let turnOnTimerId = 0;
        if (!gameOverview?.trial) {
          turnOnTimerId = setTimeout(() => {
            setIsStrategyChangePossible(() => true);
          }, lastTimestamp - currentTimestamp);
        }

        const turnOffTimerId = setTimeout(() => {
          setIsStrategyChangePossible(() => false);
          retrievePlayByPlay(gamePbpURL);
        }, possibleTo - currentTimestamp);

        return () => {
          if (turnOnTimerId) {
            try {
              clearTimeout(turnOnTimerId);
            } catch (e) { }
          }
          try {
            clearTimeout(turnOffTimerId);
          } catch (e) { }
        };
      }
    }
  }, [playByPlay, gamePbpURL, gameOverview]);

  useEffect(() => () => {
    timeoutsRef.current.forEach((timeout) => {
      try {
        clearTimeout(timeout);
      } catch (e) { }
    });
    timeoutsRef.current = [];

    if (scoreTimeoutRef.current) {
      clearTimeout(scoreTimeoutRef.current);
    }
  }, []);

  const comment = useMemo(() => {
    if (quarterMessages?.[0]?.text === 'End of game') {
      return 'End of game';
    }

    if (quarterMessages?.[0]?.text !== 'End of game' && JSON.stringify(quarterMessages[0]) !== JSON.stringify(playByPlay?.at(-1))) {
      if (quarterMessages?.[0]?.comment) {
        return quarterMessages[0].comment;
      }
    } else if (playByPlay?.[playByPlay.length - 1]?.rtStamp && Date.now() >= new Date(playByPlay?.[playByPlay.length - 1]?.rtStamp).getTime()) {
      const timestamp = new Date(new Date(playByPlay?.[playByPlay.length - 1]?.rtStamp).getTime() + QUARTER_PAUSE * 1000 * 60);
      return timestamp;
    }

    return '...';
  }, [quarterMessages, QUARTER_PAUSE, playByPlay]);

  return (
    <>
      <Container>
        <div className="mb-5 pb-2">
          <LeagueHeader franchise={franchise} league={league} />
          {replayMode && <ReplayTabMain gid={String(gameId)} />}
          <Card>
            <CardBody>
              <Row gutter={[8, 8]}>
                <Col xs={{ span: 24, order: 2 }} lg={{ span: 6, order: 1 }}>
                  <QuaterInfo
                    quatreMessage={quarterMessages}
                    quarterCount={quarterCount}
                    quarterTitle={quarterTitle}
                    downNum={curDown}
                    ydsToGo={ydsToGo}
                    ydLine={curYdLine}
                    ref={myRef}
                  />
                </Col>
                <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2, offset: 30 }}>
                  <div className="gameplay__score-helmets-container">
                    <ScoreContainer
                      isAnimationPlaying={isAnimationPlaying}
                      quarterCount={quarterCount || undefined}
                      timer={quarterCount > 0 ? quarterTime : undefined}
                      isBallInLeft={teamNum}
                      down={curDown}
                      toGo={ydsToGo}
                      ballOn={curYdLine > 50 ? 100 - curYdLine : curYdLine}
                      scoreSummary={scoreSummary}
                      liveSummary={qtrScoring}
                    />
                  </div>
                  <div className="gameplay__ground-wrapper">
                    <PbPComment message={comment} />
                    <Ground
                      yardLine={curYdLine}
                      teamNum={teamNum}
                      animationOverlay={fileUrl}
                      closeAnimationViewer={() => {
                        handleSetFileUrl(null);
                        setIsAnimationPlaying(false);
                      }}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24, order: 3 }} lg={{ span: 6, order: 3 }}>
                  {gameId && !replayMode && <Chat gameId={+gameId} />}
                  <ShareGamePlay />
                </Col>
              </Row>

              {!gameOverview?.trial && !replayMode && (
                <Row>
                  <Col xs={24}>
                    <ChangeButton onClick={setStrategyOpen} disabled={!isStrategyChangePossible} />
                  </Col>
                </Row>
              )}
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 24, order: 2 }} lg={{ span: 4, order: 1 }}></Col>
                <Col xs={{ span: 24, order: 1 }} lg={{ span: 16, order: 2 }}>
                  <GamePlayQuarterStats
                    scoreSummary={scoreSummary}
                    eventsStats={statEvents}
                    gameId={String(gameId)}
                    quarterCount={quarterCount}
                    quarterTime={quarterTime?.includes(':') ? quarterTime : null}
                    replayMode={replayMode}
                  />
                </Col>
                <Col xs={{ span: 24, order: 2 }} lg={{ span: 4, order: 3 }}>
                  <div
                    style={{
                      paddingTop: '3rem',
                      height: '100%',
                      paddingBottom: '3rem',
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                    </div>
                  </div>
                </Col>
              </Row>
              {!!playByPlay.length && <SelectedFormations />}
            </CardBody>
          </Card>
        </div>
      </Container>
      {!!(franchise?.engId && gameId) && <ChangeStrategyModal open={isStrategyOpen} onClose={setStrategyClose} gid={+gameId} utid={franchise.engId} />}
      <InviteFriendPopup
        isModalOpen={inviteFriendPopUp}
        gameId={gameId}
        handleCloseModal={() => {
          handleCancelInvitePopup({
            gameOverview,
            queryStringGameId: gameId,
            franchise,
          });
        }}
      />
      <TrialGamePopup open={isTrialModalOpen} />
    </>
  );
};

export default GamePlayer;
