import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamNFTS } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useTeamNFTS = (page: number = 1, limit: number = 20, search: string = '') => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const nfts = useSelector((state: RootState) => state.team.requestedUserTeams);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    dispatch(getTeamNFTS(page, limit, setLoading, debouncedSearch));
  }, [page, limit, debouncedSearch]);

  return { nfts, loading };
};
