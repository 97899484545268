/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "antd";

type RevealCompleteModalProps = {
  open: boolean;
  onCancel: () => void;
  handleRedirectToRoster: () => void;
};

export function RevealCompleteModal({
  open,
  onCancel,
  handleRedirectToRoster,
}: RevealCompleteModalProps) {
  return (
    <Modal
      title=""
      className="playerreveal_complete"
      open={open}
      onCancel={onCancel}
      centered
    >
      <h5>PLAYER REVEAL COMPLETE</h5>
      <p>
        Congratulations, your team has been revealed. You will now be able to
        manage your roster, set depth charts and develop game strategies.
      </p>
      <div className="playerreveal_complete__actions-row">
        <button
          type="button"
          className="blueButton"
          onClick={handleRedirectToRoster}
        >
          VIEW ROSTER
        </button>
      </div>
    </Modal>
  );
}
